// Essential for all components
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Component dependencies
import { MenuItem, Navbar, Nav, NavDropdown } from 'react-bootstrap';

// Redux
import { connect } from 'react-redux';
import { mainMenuMouseOver, mainMenuMouseLeave } from '../../Redux/Action/effectAction';

// Utils
import { filter, get, isFunction, map } from 'lodash-es';

let nextInstanceId = 1;

function stopEvent(evt) {
    if (evt && isFunction(evt.preventDefault)) {
        evt.preventDefault();
    }
}

// function renderResourcesForYouDropdownMenuItems() {
//     TODO: implement this
// }

class MainMenu extends Component {

    constructor(props) {
        super(props);
        this.instanceId = nextInstanceId;
        ++nextInstanceId;

        this.renderDropdownMenuItem = this.renderDropdownMenuItem.bind(this);
    }

    render() {
        const { currentURL } = this.props;
        let result = null;
        if (currentURL) {
            result = this.renderResponsiveMenu();
        } else {
            result = this.renderOldMenu();
        }
        return result;
    }

    renderOldMenu() {
        const {
            t,
            i18n,
            effect,
            mainMenuMouseOverP,
            mainMenuMouseLeaveP,
            currentURL,
            menus
        } = this.props;

        const mainMenu = menus.allMenus[0];

        const i = i18n.language;

        return (
            <div className="wrapper-MainMenu">
                <div className="MainMenu clearfix">
                    {mainMenu &&
                        <ul className="clearfix">
                            <li
                                className={effect.heroMenu.about ? 'menu_opened' : null}
                                onMouseOver={() => mainMenuMouseOverP('about')}
                                onMouseLeave={() => mainMenuMouseLeaveP('about')}
                            // ><Link to={"/"} className="about" onClick={stopEvent}><span>{(i === "zh-HK" ? mainMenu.menus[0].text_zh_cht : mainMenu.menus[0].text_en)}</span></Link>
                            ><Link to={"/"} className="about" onClick={stopEvent}><span>{t("Common:Menu.AboutUs")}</span></Link>
                                <ul className={"leftOne " + (currentURL ? '' : 'atHome')}>
                                    <div className="top"></div>
                                    <div className="middle">
                                        {mainMenu.menus[0].menu_items.map((subMenus) =>
                                            <li key={subMenus.menu_item_id}>
                                                <Link
                                                    to={"/" + i18n.language + "/" + subMenus.dest}
                                                    onClick={(e) => mainMenuMouseLeaveP('about')}
                                                >
                                                    <span>
                                                        {(i === "zh-HK" ? subMenus.text_zh_cht : subMenus.text_en)}
                                                    </span>
                                                </Link>
                                            </li>
                                        )}
                                    </div>
                                    <div className="bottom"></div>
                                </ul>
                            </li>

                            <li
                                className={effect.heroMenu.resource ? 'menu_opened' : null}
                                onMouseOver={() => mainMenuMouseOverP('resource')}
                                onMouseLeave={() => mainMenuMouseLeaveP('resource')}
                            // ><Link to={"/"} className="resource" onClick={stopEvent}><span>{(i === "zh-HK" ? mainMenu.menus[1].text_zh_cht : mainMenu.menus[1].text_en)}</span></Link>
                            ><Link to={"/"} className="resource" onClick={stopEvent}><span>{t("Common:Menu.ResourcesForYou")}</span></Link>
                                <ul className={"leftTwo " + (currentURL ? '' : 'atHome')}>
                                    <div className="top"></div>
                                    <div className="middle clearfix">

                                        <li>
                                            <Link to={"/" + i18n.language + "/primary-school-home"} onClick={(e) => mainMenuMouseLeaveP('resource')} >
                                                <div className="thumb one"></div>
                                                <span>{t("Common:SubMenu-ResourcesForYou.Primary")}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/" + i18n.language + "/secondary-school-home"} onClick={(e) => mainMenuMouseLeaveP('resource')} >
                                                <div className="thumb two"></div>
                                                <span>{t("Common:SubMenu-ResourcesForYou.Secondary")}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/" + i18n.language + "/parents-home"} onClick={(e) => mainMenuMouseLeaveP('resource')} >
                                                <div className="thumb three"></div>
                                                <span>{t("Common:SubMenu-ResourcesForYou.Parents")}</span>
                                            </Link>
                                        </li>
                                    </div>
                                    <div className="bottom"></div>
                                </ul>
                            </li>

                            <li
                                className={effect.heroMenu.featured ? 'menu_opened' : null}
                                onMouseOver={() => mainMenuMouseOverP('featured')}
                                onMouseLeave={() => mainMenuMouseLeaveP('featured')}
                            // ><Link to={"/"} className="featured" onClick={stopEvent}><span>{(i === "zh-HK" ? mainMenu.menus[2].text_zh_cht : mainMenu.menus[2].text_en)}</span></Link>
                            ><Link to={"/"} className="featured" onClick={stopEvent}><span>{t("Common:Menu.FeaturedIssues")}</span></Link>
                                <ul className={(currentURL ? '' : 'atHome')}>
                                    <div className="top"></div>
                                    <div className="middle">
                                        {mainMenu.menus[2].menu_items.map((subMenus) =>
                                            <li key={subMenus.menu_item_id}><Link to={"/" + i18n.language + "/" + subMenus.dest} onClick={(e) => mainMenuMouseLeaveP('featured')}><span>{(i === "zh-HK" ? subMenus.text_zh_cht : subMenus.text_en)}</span></Link></li>
                                        )}
                                        {/* <li><Link to={"/" + i18n.language + "/online-grooming"}><span>Online Grooming</span></Link></li>
                                    <li><Link to={"/" + i18n.language + "/sexting"}><span>Sexting</span></Link></li>
                                    <li><Link to={"/" + i18n.language + "/personal-privacy"}><span>Personal Privacy</span></Link></li>
                                    <li><Link to={"/" + i18n.language + "/legislations"}><span>Legislations</span></Link></li> */}
                                    </div>
                                    <div className="bottom"></div>
                                </ul>
                            </li>

                            <li
                                className={effect.heroMenu.reseaerch ? 'menu_opened' : null}
                                onMouseOver={() => mainMenuMouseOverP('research')}
                                onMouseLeave={() => mainMenuMouseLeaveP('research')}
                            ><Link to={"/" + i18n.language + "/research-findings"} className="research" onClick={(e) => mainMenuMouseLeaveP('research')}><span>{t("Common:Menu.ResearchFindings")}</span></Link>
                            </li>

                            <li
                                className={effect.heroMenu.event ? 'menu_opened' : null}
                                onMouseOver={() => mainMenuMouseOverP('event')}
                                onMouseLeave={() => mainMenuMouseLeaveP('event')}
                            // ><Link to={"/"} className="event" onClick={stopEvent}><span>{(i === "zh-HK" ? mainMenu.menus[4].text_zh_cht : mainMenu.menus[4].text_en)}</span></Link>
                            ><Link to={"/"} className="event" onClick={stopEvent}><span>{t("Common:Menu.OurEvents")}</span></Link>
                                <ul className={(currentURL ? '' : 'atHome')}>
                                    <div className="top"></div>
                                    <div className="middle">
                                        {mainMenu.menus[4].menu_items.map((subMenus) =>
                                            <li key={subMenus.menu_item_id}><Link to={"/" + i18n.language + "/" + subMenus.dest} onClick={(e) => mainMenuMouseLeaveP('event')} > <span>{(i === "zh-HK" ? subMenus.text_zh_cht : subMenus.text_en)}</span></Link></li>
                                        )}
                                    </div>
                                    <div className="bottom"></div>
                                </ul>
                            </li>

                            <li
                                className={effect.heroMenu.external ? 'menu_opened' : null}
                                onMouseOver={() => mainMenuMouseOverP('external')}
                                onMouseLeave={() => mainMenuMouseLeaveP('external')}
                            ><Link to={"/" + i18n.language + "/external-resources"} className="external" onClick={(e) => mainMenuMouseLeaveP('external')} >
                                    <span>{t("Common:Menu.ExternalResources")}</span>
                                </Link>
                            </li>
                        </ul>
                    }
                </div>
            </div>
        );
    }

    renderResponsiveMenu() {
        const { instanceId: id } = this;
        const {
            t,
            i18n,
            menus,
            history
        } = this.props;
        const language = get(i18n, 'language');
        const dropdownLabelAttr = language && language.toLowerCase() === 'en-us' ? 'text_en' : 'text_zh_cht';
        const mainMenu = get(filter(get(menus, 'allMenus'), ['type', 'menu_bar']), [0, 'menus']);
        const aboutUs = get(filter(mainMenu, ['name', 'about_us']), 0);
        const resourcesForYou = get(filter(mainMenu, ['name', 'resources_for_you']), 0);
        const featuredIssues = get(filter(mainMenu, ['name', 'featured_issues']), 0);
        const researchFindings = get(filter(mainMenu, ['name', 'research_findings']), 0);
        const ourEvents = get(filter(mainMenu, ['name', 'our_events']), 0);

        // const externalResources = get(filter(mainMenu, ['name', 'external_resources']), 0);
        const externalResources = {
            menu_id: 'external_resources',
            dest: 'external-resources'
        };
        externalResources[dropdownLabelAttr] = t("Common:Menu.ExternalResources");

        return <Navbar className="stc-menubar">
            <Navbar.Header>
                <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
                {mainMenu && <Nav className="stc-menubar-navbar-nav">
                    {this.renderDropdown(aboutUs, 'about-us-dropdown', 'menubarAboutUsDropdown_' + id)}
                    {this.renderDropdown(resourcesForYou, 'resources-for-you-dropdown', 'menubarResourcesForYouDropdown_' + id)}
                    {this.renderDropdown(featuredIssues, 'featured-issues-dropdown', 'menubarFeaturedIssuesDropdown_' + id)}

                    {/* this.renderDropdown(researchFindings, 'research-finding-dropdown', 'menubarResearchFindingsDropdown_' + id) */}
                    <NavDropdown
                        id={'menubarResearchFindingsDropdown_' + id}
                        title={researchFindings[dropdownLabelAttr]}
                        className="research-finding-dropdown"
                        open={false}
                        onToggle={() => {
                            history.push('/' + language + '/' + researchFindings.dest);
                        }}
                    >
                    </NavDropdown>

                    {this.renderDropdown(ourEvents, 'our-events-dropdown', 'menubarOurEventsDropdown_' + id)}

                    {/* this.renderDropdown(externalResources, 'external-resources-dropdown', 'menubarExternalResourcesDropdown_' + id) */}
                    <NavDropdown
                        id={'menubarExternalResourcesDropdown_' + id}
                        title={externalResources[dropdownLabelAttr]}
                        className="external-resources-dropdown"
                        open={false}
                        onToggle={() => {
                            history.push('/' + language + '/' + externalResources.dest);
                        }}
                    >
                    </NavDropdown>
                </Nav>}
            </Navbar.Collapse>
        </Navbar>;
    }

    renderDropdown(data, className, id) {
        // TODO: implement scss of className
        let result = null;
        if (data) {
            const language = get(this, ['props', 'i18n', 'language']);
            const items = get(data, 'menu_items');
            const nameAttr = language && language.toLowerCase() === 'en-us' ? 'text_en' : 'text_zh_cht';
            const name = data[nameAttr];
            result = <NavDropdown
                id={id}
                key={id || data.menu_id || name || className}
                title={name}
                className={className}
                // active={some(items, 'active')}
            >
                {map(items, this.renderDropdownMenuItem)}
            </NavDropdown>;
        }
        return result;
    }

    renderDropdownMenuItem(item) {
        let result = null;
        if (item) {
            const language = get(this, ['props', 'i18n', 'language']);
            const labelAttr = language && language.toLowerCase() === 'en-us' ? 'text_en' : 'text_zh_cht';
            result = <MenuItem
                key={item.menu_item_id || item.seq || item.dest || item.text_en || item.text_zh_cht}
                href={'/' + language + '/' + item.dest}
            >
                {item[labelAttr]}
            </MenuItem>;
        }
        return result;
    }
}

const mapStateToProps = (state) => ({
    effect: state.effect,
    menus: state.menus
});

const mapDispatchToProps = dispatch => ({
    mainMenuMouseOverP: data => dispatch(mainMenuMouseOver(data)),
    mainMenuMouseLeaveP: data => dispatch(mainMenuMouseLeave(data)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(MainMenu)));

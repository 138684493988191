// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

// Styling
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';

// Api
import { apiAuth } from '../../Api/ApiAuth';
import { apiHistory } from '../../Api/ApiHistory';

// Redux
import { connect } from 'react-redux';
import { login, forgetPasswordClear } from '../../Redux/Action/authAction';

// Utils
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// Children components
import BreadCrumb from '../../components/100Include/BreadCrumb';
import ErrorMessage from '../../components/100Include/ErrorMessage';
import GeneralMessage from '../../components/100Include/GeneralMessage';

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            MessageContent: ''
        }
    }

    componentDidMount() {
        apiHistory.createHistory(window.location.pathname);
    }

    _resetPasswordAsync = (values) => {
        if (typeof (values) !== 'undefined') {

            var promise = Promise.resolve();
            apiAuth.getClientCredentials().then((res) => {
                promise.then(() => {
                    this._resetPassword(values, res.access_token);
                });
            })
        }
    }

    _resetPassword = (values, token) => {
        const cb = (obj) => {
            // console.log("cb : ", obj);

            setTimeout(() => {
                // clear the reset password username in redux
                this.props.forgetPasswordClearP();

                // give some message
                this.setState({
                    MessageContent: 'Reset Password Success, please login again'
                });

                // reset the form
                document.getElementById("resetPasswordForm").reset();
            }, 3000);
        }

        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }

        const body = {
            old: {
                password: values.tempPassword
            },
            new: {
                password: values.password
            }
        };

        // console.log(this.props.auth.forgetPasswordBy);

        apiAuth.resetPassword(this.props.auth.forgetPasswordBy, body, token, cb, eCb);
    }

    formResetPassword = ({ values, errors, touched, handleChange }) => {
        const { t, i18n } = this.props;

        return (
            <Form id="resetPasswordForm" className="form-wrapper">

                <Grid container spacing={16}>
                    <Grid item xs={12} className="grid">
                        <h1>{t('Common:General.ResetPassword')}</h1>
                    </Grid>
                    <Grid item xs={12} className="grid">
                        <Field name="tempPassword" type="password" placeholder={t("LoginRegister:placeholder.tempPassword")} maxLength="100" style={{ 'width': '100%' }} />
                        {errors.tempPassword && touched.tempPassword ? <ErrorMessage message={errors.tempPassword} /> : null}
                    </Grid>
                    <Grid item xs={12} className="grid">
                        <Field name="password" type="password" placeholder={t("LoginRegister:placeholder.newPassword")} maxLength="100" style={{ 'width': '100%' }} />
                        {errors.password && touched.password ? <ErrorMessage message={errors.password} /> : null}
                    </Grid>
                    <Grid item xs={12} className="grid">
                        <Field name="confirmPassword" type="password" placeholder={t("LoginRegister:placeholder.ConfirmPassword")} maxLength="100" style={{ 'width': '100%' }} />
                        {errors.confirmPassword && touched.confirmPassword ? <ErrorMessage message={errors.confirmPassword} /> : null}
                    </Grid>

                    <ul>
                        <li>{t("LoginRegister:register.Condition1")}</li>
                        <li>{t("LoginRegister:register.Condition2")}</li>
                    </ul>

                    <Grid item xs={12} className="grid">
                        <Button type="submit">{t("Common:General.Reset")}</Button>
                    </Grid>
                    {(this.state.MessageContent !== '') &&
                        <Grid item xs={12} className="SuccessMessage">
                            <GeneralMessage
                                message={this.state.MessageContent}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} className="grid">
                    {t("LoginRegister:forgetPassword.backTo")}<Link to={"/" + i18n.language + "/"}>{t("Common:General.Login")}</Link>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        const { t } = this.props;

        // Redirect to home
        if (this.props.auth.auth) {
            return <Redirect push to={"/"} />
        }

        const Schema = Yup.object().shape({
            tempPassword: Yup.string()
                .typeError('Temporary Password must be a valid string')
                .required('Temporary Password is required'),
            password: Yup.string()
                .typeError('New Password must be a valid string')
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, "Does not match Password requirements!")
                .required('New Password is required'),
            confirmPassword: Yup.string()
                .typeError('Confirm New Password must be a valid string')
                .oneOf([Yup.ref('password'), null], "Does not match with Password!")
                .required('Confirm New Password is required'),
        })

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:resetPassword.title")}</title>
                    <meta name="description" content={t("Meta:resetPassword.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content">
                            <div className="forgetPassword">
                                <Formik
                                    initialValues={{
                                        tempPassword: '',
                                        password: '',
                                        confirmPassword: '',
                                    }}
                                    validationSchema={Schema}
                                    onSubmit={this._resetPasswordAsync}
                                    component={this.formResetPassword}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data)),
    forgetPasswordClearP: data => dispatch(forgetPasswordClear(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword)));

// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Styling

// Api
import { apiHistory } from '../../../Api/ApiHistory';
import { apiWebPage } from '../../../Api/ApiWebPage';

// Redux
import { connect } from 'react-redux';
import { login } from '../../../Redux/Action/authAction';

// Utils
import ReactToPrint from 'react-to-print';
import { filter, get } from 'lodash-es';
import CommonUtils, { createMarkup } from '../../../utils/CommonUtils';
import { FacebookShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { WEBSITE_URL } from '../../../config';

// Children components
import BreadCrumb from '../../../components/100Include/BreadCrumb';
// import Buttons from '../../../components/100Include/Buttons';


class ComponentToPrint extends React.Component {
    //     render() {

    //         if (this.props.i18n.language === 'en-US') {
    //             return (
    //                 <div className="bottom">
    //                     <img src={require('../../../images/ResourcesForYou/PrimarySchool/img_resourceforyou-General_Tips.png')} alt="" style={{ maxWidth: 'none' }} />
    //                 </div>
    //             );
    //         } else {
    //             return (
    //                 <div className="bottom">
    //                     <img src={require('../../../images/ResourcesForYou/PrimarySchool/img_resourceforyou-General_Tips_CH.png')} alt="" style={{ maxWidth: 'none' }} />
    //                 </div>
    //             );
    //         }
    //     }
    // }

    constructor(props) {
        super(props)
        this.state = {
        };
    }

    handleContent() {
        if (this.props.i18n.language === 'en-US') {
            return (
                <div dangerouslySetInnerHTML={createMarkup(this.props.content)} />
            )
        } else {
            return (
                <div className="wordDoc generalTips">
                    <div dangerouslySetInnerHTML={createMarkup(this.props.content)} />
                </div>
            )
        }
    }



    render() {
        // console.log('hellohi', this.props)
        return (
            <div className="wrapper-content">
                <div className="App-intro">
                    {/* <div className="content generalTips"> */}
                    <div className="content">
                        {this.handleContent()}
                        {/* 
                            <div>
                                <img src={require('../../../images/ResourcesForYou/GeneralTips/img-general_01.png')} alt="" />
                                <span>gg你擁有權利！</span>
                            </div>
                            <div dangerouslySetInnerHTML={createMarkup("<ul><li>當你堅守個人權益和負責任地上網，你便可從網絡獲得更多益處。</li><li>實際上，每一個人都可以使用網絡，並且可以上載資料。你只是眾多網絡用家之一。</li><li>堅守個人權益十分重要，包括網上和生活的其他方面。</li><li>也要照顧你的朋友。</li></ul> <p>&nbsp;</p>")} />
                            <div>
                                <img src={require('../../../images/ResourcesForYou/GeneralTips/img-general_02_YOUR_VOICE.png')} alt="" />
                                <span>你的聲音很重要：</span>
                            </div>
                            <div dangerouslySetInnerHTML={createMarkup("<p>在線或是離線，你都有權分享個人意見及想法。只要你沒侵犯他人權益，你可以發佈照片、文字內容、音樂等任何東西。</p> <p>&nbsp;</p> <h4>謹記:</h4> <ul><li>在網絡發佈的東西是永久存在的。（想一想你在網上發佈了甚麼？）</li><li>在網絡的行為與現實生活應相符。</li><li>保持良好我的言行舉止</li><li>與家人談談關於你的網絡世界</li></ul> <p>!你可以教導成人如何使用網絡</p><p>當你使用網絡時，行為應與現實生活一致。基本規則是對待別人，如同你期待怎樣受到別人對待。好好記著在網絡發佈的東西是永久存在的，而且有機會被散播開去：已發佈的訊息、圖片或</p> <p>影片不可能再受控制。任何人都可以複製及散佈上述資料，包括有著完全不同的用途。今天，你在網上發佈的任何東西，明天甚或在10年後都可看得見。你想上載怎麼樣的個人圖片?告訴父母你的網上經驗，就如你做其他事情一樣。與父母一同在網絡世界探索。</p><p>&nbsp;</p>")} />
                            <div>
                                <img src={require('../../../images/ResourcesForYou/GeneralTips/img-general_03_BULLYING.png')} alt="" />
                                <span>欺凌非兒戲：</span>
                            </div>
                            <div dangerouslySetInnerHTML={createMarkup("<ul><li>欺凌是錯誤的行為，別容忍</li><li>儲存所有欺凌的訊息</li><li>告訴成人</li></ul><p>你有權選擇做自己喜歡的事情。沒人有權褻瀆你的品格及聲譽。欺凌、詐騙或騷擾必不可容忍。如你受欺凌或是收到奇怪和令人不快的訊息，儲存所有文件、訊息、照片及討論內容，以便為調查提供證據。你不用為他人行為或收到的訊息負責。別回應奇怪的訊息。如對網絡或電話上的對話感到奇怪或不安，相信你的直覺：</p><p>停止對話，立刻離開</p> <p>同時，照顧你的朋友！</p><p>&nbsp;</p>")} />
                            <div>
                                <img src={require('../../../images/ResourcesForYou/GeneralTips/img-general_04_MEDIA.png')} alt="" />
                                <span>了解你的媒體</span>
                            </div>
                            <div dangerouslySetInnerHTML={createMarkup("<ul>你有否覺得自己用太多時間在網上玩樂消遣？<p>&nbsp;</p> <li>不要被欺騙。別輕信他人在網絡告訴你的任何事情。</li><li>查證訊息由誰人發佈及基於何種原因。</li><li>從報紙、電視、書本及長輩中掌握資訊。</li></ul> <p>你會輕信在網絡的所見所聞，以及認識的人嗎？任何人都可以在網絡寫下或製作任何內容。有些時候，很難分辨真偽資訊，或是知悉你與何人聊天。</p> <p>別輕信首次搜尋結果！利用網絡或各類網站來查證誰人或甚麼隱藏在背後，花一點時間都是值得的。查證一下在網上得到的資訊是否事實、別人的意見或是廣告內容。</p> <p>&nbsp;</p>")} />
                            <div>
                                <img src={require('../../../images/ResourcesForYou/GeneralTips/img-general_05_AVOID.png')} alt="" />
                                <span>你有否覺得自己用太多時間在網上玩樂消遣？</span>
                            </div>
                            <div dangerouslySetInnerHTML={createMarkup("<p>上網成癮？</p><p>你可避免網絡或遊戲上癮，只要你：</p> <ul><li>關心友誼</li><li>盡責完成功課</li><li>食得健康及多元</li><li>睡眠充足</li></ul> <p>如果你發覺自己缺乏上述其中一點，你可加以關注這些方面，從而改善有關情況。如果情況讓你感到憂慮時，你應該將問題告知可信任的成年人。</p> <p>你曾經在網上看到一些讓人反感的事情嗎?</p>")} />
                            <div dangerouslySetInnerHTML={createMarkup("<p>有否曾經在網上遇過一些事情，引起你的不安、恐懼和苦惱。別糾結於該些冒犯性或駭人的內容。你應該立刻離開該些冒犯性的網站，如沒其他方法，便關掉電腦。好好保護自己!</p><p>&nbsp;</p> <ul><li>你不應看任何引起不安或恐懼的事情。</li><li>如其他方法都行不通，關掉瀏覽軟件及電子產品。</li><li>與成人談一談你的經歷。</li><li>如有需要便尋求協助及支援。</li></ul> <p>在網絡世界有很多事情或會引起恐懼和懊惱。這絕對是正常的。任何人在任何地方都可在網站撰寫或發佈資訊。網上，存在很多錯誤的價值觀和流言蜚語。有些時候，線上社群討論可能歪曲原意，而且身陷其中會成為你最大的敵人。放心告知可靠的成人。</p> <p>如有任何事情引起憂慮，告訴身邊的成人。</p> <p>&nbsp;</p>")} />
                            <div>
                                <img src={require('../../../images/ResourcesForYou/GeneralTips/img-general_06_PROTECT.png')} alt="" />
                                <span>看看如何：</span>
                            </div>
                            <div dangerouslySetInnerHTML={createMarkup("<h4></h4> <ul><li>拒絕接收一些你不想聯絡的人的訊息。</li><li>改變社群版面的私隱設定。</li><li>刪除你的網上紀錄，讓他人無從知道你曾進入甚麼網站。</li><li>以「書籤」功能記錄網站。</li><li>拒絕一些不想接收的垃圾郵件和廣告訊息，並且了解如何改變過濾設定。</li><li>拒絕和舉報欺凌者和其他滋事份子。</li><li>如遇上困難尋求幫助。</li></ul> <p>亦要關心你的朋友！</p> <p>發佈任何資訊前先三思，被發佈的內容可能永久留在網上。</p> <p>&nbsp;</p>")} />
                            <div>
                                <img src={require('../../../images/ResourcesForYou/GeneralTips/img-general_07_LEARN.png')} alt="" />
                                <span>安全網上交友貼士:</span>
                            </div>
                            <div dangerouslySetInnerHTML={createMarkup("<ul><li>守護你的權益。你不允許被傷害或欺凌，包括在互聯網上。</li><li>別過份坦率，想一想誰會看到你發佈至網上的訊息或圖片。</li><li>如你在現實生活中不認識的網友，別隨便相信他。別單獨跟網友約會。</li><li>如果你懷疑某些事情，相信你的直覺。沒有人可向他人施壓、逼迫或勒索。</li></ul>")} /> */}
                        {/* </div> */}
                    </div>
                </div>
            </div >
        )
    }
}

class PrimaryGeneralTips extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundColor: 'white',
            showPrintButton: true,
            showFacebookShareButton: true,
            showDownloadButton: true,
            showCopyToClipboardButton: true,
            copied: false,
            contentUpper: null,
            contentContent: null,
            language: null,
            loading: false
        }
    }

    componentDidMount() {
        this.updateHtml();
        apiHistory.createHistory(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
            this.updateHtml();
        }
    }

    updateHtml() {
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiWebPage.getPageData('/primary-general-tips', (result) => {
            const language = get(this, 'props.i18n.language');
            const newState = {
                loading: false,
                language
            };
            const body = get(result, 'body');
            if (body && body.length > 0) {
                const attr = language && language.toLowerCase() === 'en-us' ? 'html_en' : 'html_zh_cht';

                const uppers = filter(body, ['section', 'upper']);
                if (uppers && uppers.length > 0) {
                    newState.contentUpper = uppers[0][attr];
                } else {
                    console.log('no upper section');
                }

                const contents = filter(body, ['section', 'content']);
                if (contents && contents.length > 0) {
                    newState.contentContent = contents[0][attr];
                } else {
                    console.log('no content section');
                }
            }
            this.setState(newState);
        });
    }

    _downloadFile = (file_url, file_name) => {
        // Bluebird.delay(0, file_url).then((url) => {
        CommonUtils.forceDownload(file_url, file_name);
        // });
    }

    _handleCopyLink = () => {
        this.setState({
            copied: true
        });
        setTimeout(() => {
            this.setState({
                copied: false
            });
        }, 3000);
    }

    render() {
        const { t, i18n } = this.props;

        const currentURL = this.props.router.location.pathname;
        // const url = window.location.protocol + '//' + window.location.hostname + currentURL;
        const url = WEBSITE_URL + currentURL;

        let file = require('../../../uploads/' + t("Upload:primaryTips2"));

        // console.log('this.state.contentContent', this.state.contentContent)

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:primaryGeneralTips.title")}</title>
                    <meta name="description" content={t("Meta:primaryGeneralTips.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content">
                            <div className="ImageText">
                                <div className="upper">
                                    <div dangerouslySetInnerHTML={createMarkup(this.state.contentUpper)} />
                                    {/* <div dangerouslySetInnerHTML={this.state.contentUpper} /> */}
                                    {/* <img src={require('../../../images/ResourcesForYou/PrimarySchool/EngContent/EngContent1.jpg')} alt="" />
                                    <img src={require('../../../images/ResourcesForYou/PrimarySchool/EngContent/EngContent2.jpg')} alt="" />
                                    <img src={require('../../../images/ResourcesForYou/PrimarySchool/EngContent/EngContent3.jpg')} alt="" />
                                    <img src={require('../../../images/ResourcesForYou/PrimarySchool/EngContent/EngContent4.jpg')} alt="" />
                                    <img src={require('../../../images/ResourcesForYou/PrimarySchool/EngContent/EngContent5.jpg')} alt="" />
                                    <img src={require('../../../images/ResourcesForYou/PrimarySchool/EngContent/EngContent6.jpg')} alt="" />
                                    <img src={require('../../../images/ResourcesForYou/PrimarySchool/EngContent/EngContent7.jpg')} alt="" />
                                    <img src={require('../../../images/ResourcesForYou/PrimarySchool/EngContent/EngContent8.jpg')} alt="" /> */}
                                    <div className={"button-wrapper " + (this.state.backgroundColor ? this.state.backgroundColor : '')}>
                                        <div className="wrap">
                                            {this.state.showPrintButton &&
                                                <ReactToPrint
                                                    trigger={() => <div className={"print " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}></div>}
                                                    content={() => this.componentRef}
                                                />
                                            }

                                            {this.state.showFacebookShareButton &&
                                                <FacebookShareButton url={url} className={"facebookShare " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')} />
                                            }

                                            {this.state.showDownloadButton &&
                                                // <div className={"download " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}
                                                //     onClick={() => this._downloadFile(file, t("Upload:primaryTips"))}>
                                                <div className={"download " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}
                                                    onClick={() => this._downloadFile(file, t("Upload:primaryTips2"))}>
                                                </div>
                                            }

                                            {this.state.showCopyToClipboardButton &&
                                                <div className="wrapper-copyLink">
                                                    <CopyToClipboard text={url} onCopy={() => this._handleCopyLink()}>
                                                        <div className={"copyLink " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}></div>
                                                    </CopyToClipboard>
                                                    {(this.state.copied ? <div className="message">{t("Common:General.CopyLink")}</div> : '')}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {/* <Buttons
                                        backgroundColor='white'
                                        file={t("Upload:primaryTips")}
                                        componentRef={this.componentRef}
                                        showPrintButton={true}
                                        showFacebookShareButton={true}
                                        showDownloadButton={true}
                                        showCopyToClipboardButton={true}
                                    /> */}
                                </div>
                                <ComponentToPrint ref={el => (this.componentRef = el)} i18n={i18n} content={this.state.contentContent} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    router: state.router
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PrimaryGeneralTips));

import btoa from 'btoa';
import { API_URL_BASE } from '../../config';

const WEB_CLIENT_ID = 'xvyeBym51rN9Njd0';
const WEB_CLIENT_SECRET = 'AOdyo5FfB7Mvia56n6RtCspPhgqkIIjB4dR4ytgqjIkUGDUqqrqSpwL4n8x6wUzU';

export const BASE_URL = API_URL_BASE;

export const WEB_CLIENT_CREDENTIAL_TOKEN = btoa(`${WEB_CLIENT_ID}:${WEB_CLIENT_SECRET}`);

export const AUDIENCE = 'save-the-children.joyaether.com';

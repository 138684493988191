// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Styling

// Api
import { apiHistory } from '../../../Api/ApiHistory';
import { apiQuizResult } from '../../../Api/ApiQuizResult';
import { apiListPrimaryQuizQuestions } from '../../../Api/ApiListPrimaryQuizQuestions';

// Redux
import { connect } from 'react-redux';
import { login } from '../../../Redux/Action/authAction';

// Utils
import { clone, forEach, get, map, reduce, shuffle, zip } from 'lodash-es';

// Children components
import BreadCrumb from '../../../components/100Include/BreadCrumb';
import Quiz from '../../../components/100Include/Quiz';

class PrimaryQuiz extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quizData: null,
            contents: [],
            language: null,
            loading: false
        };

        this.handleRetryQuiz = this.handleRetryQuiz.bind(this);
        this.handleQuizResultCalculated = this.handleQuizResultCalculated.bind(this);
    }

    componentDidMount() {
        this.initQuizData();
        apiHistory.createHistory(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        const newLanguage = get(this, 'props.i18n.language');
        if (!loading && (!language || language !== newLanguage)) {
            this.changeQuizDataLanguage(newLanguage);
        }
    }

    initQuizData() {
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiListPrimaryQuizQuestions.getQuizQuestions((result) => {
            const contents = get(result, 'body');
            const language = get(this, 'props.i18n.language');
            const langAttr = language && language.toLowerCase() === 'en-us' ? 'en' : 'zh_cht';
            const questions = map(contents, (content) => {
                return {
                    correctAnswer: !!content.correct_answer,
                    text: content['question_' + langAttr],
                    answerExplanation: content['answer_explanation_' + langAttr],
                    number: content.question_number,
                    question_en: content.question_en,
                    answer_explanation_en: content.answer_explanation_en,
                    question_zh_cht: content.question_zh_cht,
                    answer_explanation_zh_cht: content.answer_explanation_zh_cht
                };
            });
            this.setState({
                contents: contents || [],
                loading: false,
                language,
                quizData: {
                    questions: shuffle(questions)
                },
            });
        });
    }

    changeQuizDataLanguage(language) {
        const { quizData } = this.state;
        const questions = get(quizData, 'questions');
        const langAttr = language && language.toLowerCase() === 'en-us' ? 'en' : 'zh_cht';
        forEach(questions, (question) => {
            question.text = question['question_' + langAttr];
            question.answerExplanation = question['answer_explanation_' + langAttr];
        });
        this.setState({
            quizData: clone(quizData),
            language
        });
    }

    handleRetryQuiz() {
        const { quizData } = this.state;
        this.setState({
            quizData: {
                questions: shuffle(quizData.questions)
            }
        });
    }

    handleQuizResultCalculated(userAnswers) {
        const { quizData } = this.state;
        const quizResultData = reduce(userAnswers, (breakdown, userAnswer) => {
            const userChoice = userAnswer.userChoice;
            if (userChoice === userAnswer.correctAnswer) {
                ++breakdown.correctAnswerCount;
            } else if (userChoice === 'do_not_know') {
                ++breakdown.doNotKnowCount;
            } else {
                ++breakdown.incorrectAnswerCount;
            }
            return breakdown;
        }, {
            correctAnswerCount: 0,
            incorrectAnswerCount: 0,
            doNotKnowCount: 0
        });
        quizResultData.questionData = map(zip(quizData.questions, userAnswers), ([question, answer]) => {
            return {
                questionNumber: question.number,
                questionEn: question.question_en,
                correctAnswer: question.correctAnswer,
                userChoice: answer.userChoice
            };
        });
        const auth = get(this, 'props.auth');
        const token = get(auth, 'token');
        const username = get(auth, 'userInfo.username');
        apiQuizResult.updateQuizData(username, 'quiz1', quizResultData, token)
    }

    render() {
        const { quizData } = this.state;

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>Save the Children | 救助兒童會</title>
                    <meta name="description" content= "Save the Children | 救助兒童會" />
                </Helmet>
                <div className="container-main quiz-container">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content no-padding">
                            {/* Quiz */}
                            <Quiz
                                quizData={quizData}
                                onRetryQuiz={this.handleRetryQuiz}
                                onQuizResultCalculated={this.handleQuizResultCalculated}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PrimaryQuiz));

export const API_URL_BASE = 'https://dev2.joyaethercloud.com/save-the-children-server';
// export const API_URL_BASE = 'https://netizen.savethechildren.org.hk/save-the-children-server';
export const API_URL = API_URL_BASE + '/';
export const WEBSITE_URL = 'https://save-the-children.dev.joyaethercloud.com';
// export const WEBSITE_URL = 'https://netizen.savethechildren.org.hk';
export const GA_TRACKING_ID = 'UA-153671248-2'; // dev
// export const GA_TRACKING_ID = 'UA-153671248-1'; // prod

export const SCREEN_SIZE_SM = 768;
export const SCREEN_SIZE_MD = 992;
export const SCREEN_SIZE_LG = 1200;

import { apiGeneral } from './_General';

export const apiListExternalLinks = {
  getBlogs(cb, eCb) {
    apiGeneral.apiFetch('public/list_external_links', {
      $orderby: 'sequence'
    }, null, cb, eCb);
  },

  getBlog(partnersgId, cb, eCb) {
    apiGeneral.apiFetch('public/list_external_links/' + encodeURIComponent(partnersgId), null, null, cb, eCb);
  }
};

// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
//import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Styling

// Api
import { apiHistory } from '../../../Api/ApiHistory';
import { apiWebPage } from '../../../Api/ApiWebPage';

// Redux
import { connect } from 'react-redux';
import { login } from '../../../Redux/Action/authAction';

// Utils
import ReactToPrint from 'react-to-print';
import CommonUtils, { createMarkup } from '../../../utils/CommonUtils';
import { FacebookShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { WEBSITE_URL } from '../../../config';
import { filter, get } from 'lodash-es';

// Children components
import BreadCrumb from '../../../components/100Include/BreadCrumb';
// import Buttons from '../../../components/100Include/Buttons';

class ComponentToPrint extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false
        };
    }

    toggleDiv = () => {
        this.setState({
            show: !this.state.show
        })
    }

    handleContent() {
        if (this.props.i18n.language === 'en-US') {
            return (
                <div dangerouslySetInnerHTML={createMarkup(this.props.contentFixedContent)} />
            )
        } else {
            return (
                <div className="wordDoc">
                    {/* <h3>{t("ParentsTips:page.title")}</h3> */}
                    <div dangerouslySetInnerHTML={createMarkup(this.props.contentFixedContent)} />
                    <br />
                    <h4 onClick={this.toggleDiv}>{(this.state.show ? "閱讀部份 >>>" : "閲讀更多 >>>")}</h4>
                    {this.state.show && <div dangerouslySetInnerHTML={createMarkup(this.props.contentExtendedContent)} />}
                </div>
            )
        }
    }


    render() {
        // const { t
        //      , i18n
        // } = this.props;

        // console.log('hello : ', this.props);


        return (
            <div className="wrapper-content">

                {/* <div dangerouslySetInnerHTML={createMarkup(this.props.contentFixedContent)} /> */}
                {this.handleContent()}
            </div>
        )
    }
}

class ParentsTips extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundColor: 'white',
            showPrintButton: true,
            showFacebookShareButton: true,
            showDownloadButton: true,
            showCopyToClipboardButton: true,
            copied: false,
            contentUpper: null,
            contentFixedContent: null,
            contentExtendedContent: null,
            language: null,
            loading: false
        }
    }

    _downloadFile = (file_url, file_name) => {
        // Bluebird.delay(0, file_url).then((url) => {
        CommonUtils.forceDownload(file_url, file_name);
        // });
    }

    _handleCopyLink = () => {
        this.setState({
            copied: true
        });
        setTimeout(() => {
            this.setState({
                copied: false
            });
        }, 3000);
    }

    componentDidMount() {
        this.updateHtml();
        apiHistory.createHistory(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
            this.updateHtml();
        }
    }

    updateHtml() {
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiWebPage.getPageData('/parents-tips', (result) => {
            const language = get(this, 'props.i18n.language');
            const newState = {
                loading: false,
                language
            };
            const body = get(result, 'body');
            if (body && body.length > 0) {
                const attr = language && language.toLowerCase() === 'en-us' ? 'html_en' : 'html_zh_cht';

                const uppers = filter(body, ['section', 'upper']);
                if (uppers && uppers.length > 0) {
                    newState.contentUpper = uppers[0][attr];
                } else {
                    console.log('no upper section');
                }

                const fixedContents = filter(body, ['section', 'contentFixedContent']);
                if (fixedContents && fixedContents.length > 0) {
                    newState.contentFixedContent = fixedContents[0][attr];
                } else {
                    console.log('no fixed content section');
                }

                const extendedContents = filter(body, ['section', 'contentExtendedContent']);
                if (extendedContents && extendedContents.length > 0) {
                    newState.contentExtendedContent = extendedContents[0][attr];
                } else {
                    console.log('no extended content section');
                }
            }
            this.setState(newState);
        });
    }

    render() {
        const { t, i18n } = this.props;

        const currentURL = this.props.router.location.pathname;

        const url = WEBSITE_URL + currentURL;

        let file = require('../../../uploads/' + t("Upload:parentsTips"));

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:parentsTips.title")}</title>
                    <meta name="description" content={t("Meta:parentsTips.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content no-background">
                            <div className="ImageText">
                                <div className="upper">
                                    <div dangerouslySetInnerHTML={createMarkup(this.state.contentUpper)} />

                                    <div className={"button-wrapper " + (this.state.backgroundColor ? this.state.backgroundColor : '')}>
                                        <div className="wrap">
                                            {this.state.showPrintButton &&
                                                <ReactToPrint
                                                    trigger={() => <div className={"print " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}></div>}
                                                    content={() => this.componentRef}
                                                />
                                            }

                                            {this.state.showFacebookShareButton &&
                                                <FacebookShareButton url={url} className={"facebookShare " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')} />
                                            }

                                            {this.state.showDownloadButton &&
                                                <div className={"download " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}
                                                    onClick={() => this._downloadFile(file, "Online life is real life.pdf")}>
                                                </div>
                                            }

                                            {this.state.showCopyToClipboardButton &&
                                                <div className="wrapper-copyLink">
                                                    <CopyToClipboard text={url} onCopy={() => this._handleCopyLink()}>
                                                        <div className={"copyLink " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}></div>
                                                    </CopyToClipboard>
                                                    {(this.state.copied ? <div className="message">{t("Common:General.CopyLink")}</div> : '')}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {/* <Buttons
                                        backgroundColor='white'
                                        file={t("Upload:parentsTips")}
                                        componentRef={this.componentRef}
                                        showPrintButton={true}
                                        showFacebookShareButton={true}
                                        showDownloadButton={true}
                                        showCopyToClipboardButton={true}
                                    /> */}
                                </div>
                                <ComponentToPrint ref={el => (this.componentRef = el)} t={t} i18n={i18n} contentFixedContent={this.state.contentFixedContent} contentExtendedContent={this.state.contentExtendedContent} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    router: state.router
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ParentsTips));

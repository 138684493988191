// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Styling
// import Grid from '@material-ui/core/Grid';

// Api
// import { apiAuth } from '../../Api/ApiAuth';

// Redux
import { connect } from 'react-redux';
import { login } from '../../Redux/Action/authAction';

// Utils

// Children components
// import BreadCrumb from '../../../components/100Include/BreadCrumb';

class SeekHelp extends Component {
    // constructor(props) {
    //     super(props);
    // }

    //     // this.state = {
    //     //     formSubmitted: false
    //     // }
    // }

    render() {

        const { t, i18n } = this.props;

        let pathname = this.props.route.location.pathname,
            urlArray = pathname.split("/"),
            currentURL = urlArray[2];

        // console.log(currentURL);

        // if (currentURL === '' || typeof currentURL === 'undefined') 
        return (
            <div className={"wrapper-helpBoard " + (currentURL !== '' && typeof currentURL !== 'undefined' ? 'smaller ' : '') + (this.props.seekHelp ? 'show' : 'hide')}>
                <div className="helpBoard">
                    <div
                        className="icon"
                        onClick={() => this.props.handleSeekHelp()}
                    >
                    </div>
                    <h3>{t("SeekHelp:title")}</h3>

                    <div className="wrapperText">
                        <div className="text">
                            <a target="_blank" rel="noopener noreferrer" href={t("SeekHelp:hrefLinks.report")}>{t("SeekHelp:report")}</a>
                        </div>
                        <div className="text">
                            <a href={"/" + i18n.language + "/contact-us"}>{t("SeekHelp:talk")}</a>
                        </div>
                        <div className="text">
                            <a target="_blank" rel="noopener noreferrer" href={t("SeekHelp:hrefLinks.connect")}>{t("SeekHelp:connect")}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        route: state.router,
        members: state.auth
    }
);

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SeekHelp));

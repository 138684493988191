import { apiGeneral } from './_General';

export const apiListPartners = {
  getBlogs(cb, eCb) {
    apiGeneral.apiFetch('public/list_partners', {
      $orderby: 'sequence'
    }, null, cb, eCb);
  },

  getBlog(partnersgId, cb, eCb) {
    apiGeneral.apiFetch('public/list_partners/' + encodeURIComponent(partnersgId), null, null, cb, eCb);
  }
};

// Essential for all components
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Api
import { apiListHomepageImages } from '../../Api/ApiListHomepageImages';

// Redux
import { connect } from 'react-redux';

// Utils
import Slider from "react-slick";
import { get } from 'lodash-es';

class HeroMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contents: null,
            language: null,
            loading: false
        };

        this.settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
        };
    }

    componentDidMount() {
        this.updateHtml();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
            this.updateHtml();
        }
    }

    updateHtml() {
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiListHomepageImages.getHomepageImages((result) => {
            this.setState({
                contents: get(result, 'body') || [],
                loading: false,
                language: get(this, 'props.i18n.language')
            });
        });
    }

    render() {
        const {
            t,
            i18n,
            effect
        } = this.props;
        const language = get(i18n, 'language');
        const sliderImageUrlAttr = language && language.toLowerCase() === 'en-us' ? 'url_en' : 'url_zh_cht';
        const { contents } = this.state;
        const heroMenu = get(effect, 'heroMenu');

        return (
            <div className="wrapper-heroMenu">
                <div className="heroMenu clearfix">
                    <div className="heroSlider">
                        <Slider {...this.settings}>
                            {contents && contents.map(content => {
                                return <div key={content.list_homepage_image_id}>
                                    <img src={content[sliderImageUrlAttr]} alt=""/>
                                </div>;
                            })}
                        </Slider>
                    </div>

                    <img src={require('../../images/HeroMenu/bg_homepage_menu.png')} alt="" />

                    <h1 className="logo-NA-hero">
                        <Link to={"/" + i18n.language + '/'}><img src={require('../../images/img_homepage_logo-NA.png')} alt="" /></Link>
                    </h1>

                    <span className="viewMore">{t("Common:General.ReadMore")}</span>

                    <div className={"figure about " + (get(heroMenu, 'about') ? 'active' : '')}></div>
                    <div className={"figure resource " + (get(heroMenu, 'resource') ? 'active' : '')}></div>
                    <div className={"figure featured " + (get(heroMenu, 'featured') ? 'active' : '')}></div>
                    <div className={"figure research " + (get(heroMenu, 'research') ? 'active' : '')}></div>
                    <div className={"figure event " + (get(heroMenu, 'event') ? 'active' : '')}></div>
                    <div className={"figure external " + (get(heroMenu, 'external') ? 'active' : '')}></div>
                </div>
                <img className="cached-image" alt="" src={require('../../images/HeroMenu/img_homepage_menu_01-About_default.png')} />
                <img className="cached-image" alt="" src={require('../../images/HeroMenu/img_homepage_menu_01-About_active.png')} />
                <img className="cached-image" alt="" src={require('../../images/HeroMenu/img_homepage_menu_02-Resource_default.png')} />
                <img className="cached-image" alt="" src={require('../../images/HeroMenu/img_homepage_menu_02-Resource_active.png')} />
                <img className="cached-image" alt="" src={require('../../images/HeroMenu/img_homepage_menu_03-Featured_default.png')} />
                <img className="cached-image" alt="" src={require('../../images/HeroMenu/img_homepage_menu_03-Featured_active.png')} />
                <img className="cached-image" alt="" src={require('../../images/HeroMenu/img_homepage_menu_04-Research_default.png')} />
                <img className="cached-image" alt="" src={require('../../images/HeroMenu/img_homepage_menu_04-Research_active.png')} />
                <img className="cached-image" alt="" src={require('../../images/HeroMenu/img_homepage_menu_05-Ourevent_default.png')} />
                <img className="cached-image" alt="" src={require('../../images/HeroMenu/img_homepage_menu_05-Ourevent_active.png')} />
                <img className="cached-image" alt="" src={require('../../images/HeroMenu/img_homepage_menu_06-External_default.png')} />
                <img className="cached-image" alt="" src={require('../../images/HeroMenu/img_homepage_menu_06-External_active.png')} />
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    effect: state.effect
});

export default withTranslation()(connect(mapStateToProps)(HeroMenu));

// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Styling

// Api
import { apiHistory } from '../../../Api/ApiHistory';
import { apiListSecondarySocialMediaGuides } from '../../../Api/ApiListSecondarySocialMediaGuides';

// Redux
import { connect } from 'react-redux';
import { login } from '../../../Redux/Action/authAction';

// Utils
import ReactToPrint from 'react-to-print';
import { get } from 'lodash-es';
import CommonUtils, { createMarkup } from '../../../utils/CommonUtils';
import { FacebookShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { WEBSITE_URL } from '../../../config';

// Children components
import BreadCrumb from '../../../components/100Include/BreadCrumb';

class ComponentToPrint extends React.Component {

  // const { t, i18n } = this.props;

  constructor(props) {
    super(props)
    this.state = {
    };
  }


  render() {
    return (
      <div className="wrapper-content">
        <div className="wordDoc userGuide">
          <h3>{this.props.title}</h3>
          <div dangerouslySetInnerHTML={createMarkup(this.props.content)} />
        </div>
      </div>
    )
  }
}

class SecondarySocialMediaUserGuideDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formSubmitted: false,
      showPrintButton: true,
      showFacebookShareButton: true,
      showDownloadButton: true,
      showCopyToClipboardButton: true,
      copied: false,
      contentId: null,
      platform: null,
      content: null,
      language: null,
      loading: false
    }
  }

  componentDidMount() {
    const { id } = this.props;
    if (id) {
      const splittedId = id.split('_');
      if (splittedId.length === 2) {
        const [contentId, platform] = splittedId;
        this.setState({
          contentId,
          platform
        }, () => {
          setTimeout(() => {
            this.updateHtml();
          }, 500);
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { language, loading } = this.state;
    if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
        this.updateHtml();
    }
    apiHistory.createHistory(window.location.pathname);
  }

  updateHtml() {
    const { contentId } = this.state;
    if (!contentId || this.state.loading) {
      return;
    }
    this.setState({
      loading: true,
      language: get(this, 'props.i18n.language')
    });
    apiListSecondarySocialMediaGuides.getSocialMediaGuide(contentId, (result) => {
      this.setState({
        content: get(result, 'body') || null,
        loading: false,
        language: get(this, 'props.i18n.language')
      });
    }, (e) => {
      console.log(e);
      this.setState({
        loading: false,
        language: get(this, 'props.i18n.language')
      });
    });
  }

  _downloadFile = (file_url, file_name) => {
    // Bluebird.delay(0, file_url).then((url) => {
    CommonUtils.forceDownload(file_url, file_name);
    // });
  }

  _handleCopyLink = () => {
    this.setState({
      copied: true
    });
    setTimeout(() => {
      this.setState({
        copied: false
      });
    }, 3000);
  }

  render() {
    const { t, i18n } = this.props;
    const { content, platform, language } = this.state;

    // let data = [
    //   { id: 1, banner: "img_socialmedia-01FB.png", title: t("PrimarySocialMedia:content1"), downloadFile: 'Facebook_Guide_Chin_iOS.pdf', content: t("PrimarySocialMedia:content1HTML"), img1: "<img src={require('../../../images/ResourcesForYou/UserGuide/' + data[id].banner)} alt=\"\" />" },
    //   { id: 2, banner: "img_socialmedia-01FB.png", title: t("PrimarySocialMedia:content2"), downloadFile: 'Facebook_Guide_Chin_Android.pdf', content: t("PrimarySocialMedia:content2HTML") },
    //   { id: 3, banner: "img_socialmedia-02Snapchat.png", title: t("PrimarySocialMedia:content3"), downloadFile: 'Snapchat_Guide_Chin.pdf', content: t("PrimarySocialMedia:content3HTML") },
    //   { id: 4, banner: "img_socialmedia-02Snapchat.png", title: t("PrimarySocialMedia:content4"), downloadFile: 'Snapchat_Guide_Chin.pdf', content: t("PrimarySocialMedia:content4HTML") },
    //   { id: 5, banner: "img_socialmedia-03Msger.png", title: t("PrimarySocialMedia:content5"), downloadFile: 'Messenger_Guide_Chin.pdf', content: t("PrimarySocialMedia:content5HTML") },
    //   { id: 6, banner: "img_socialmedia-03Msger.png", title: t("PrimarySocialMedia:content6"), downloadFile: 'Messenger_Guide_Chin.pdf', content: t("PrimarySocialMedia:content6HTML") },
    //   { id: 7, banner: "img_socialmedia-04Whatsapp.png", title: t("PrimarySocialMedia:content7"), downloadFile: 'Whatsapp_Guide_iOS_Chin.pdf', content: t("PrimarySocialMedia:content7HTML") },
    //   { id: 8, banner: "img_socialmedia-04Whatsapp.png", title: t("PrimarySocialMedia:content8"), downloadFile: 'Whatsapp_Guide_Android_Chin.pdf', content: t("PrimarySocialMedia:content8HTML") },
    //   { id: 9, banner: "img_socialmedia-05TT.png", title: t("PrimarySocialMedia:content9"), downloadFile: 'TikTok_Chin_iOS.pdf', content: t("PrimarySocialMedia:content9HTML") },
    //   { id: 10, banner: "img_socialmedia-05TT.png", title: t("PrimarySocialMedia:content10"), downloadFile: 'TikTok_Chin_Android.pdf', content: t("PrimarySocialMedia:content10HTML") },
    //   { id: 11, banner: "img_socialmedia-06wechat.png", title: t("PrimarySocialMedia:content11"), downloadFile: 'WeChat_Guide_Chin_Apple.pdf', content: t("PrimarySocialMedia:content11HTML") },
    //   { id: 12, banner: "img_socialmedia-06wechat.png", title: t("PrimarySocialMedia:content12"), downloadFile: 'WeChat_Guide_Chin_Android.pdf', content: t("PrimarySocialMedia:content12HTML") }
    // ]

    // const currentURL = this.props.router.location.pathname;
    // const url = window.location.protocol + '//' + window.location.hostname + currentURL;
    // const url = WEBSITE_URL + currentURL;
    const url = WEBSITE_URL + '/secondary-social-media-user-guide/' + this.props.id

    const langAttr = language && language.toLowerCase() === 'en-us' ? 'en' : 'zh_cht';
    const suffix = platform + '_' + langAttr;
    const title = get(content, 'page_title_' + suffix);
    const file = get(content, 'page_manual_download_url_' + suffix);
    const filename = file && file.split('/').pop();
    const banner = get(content, 'page_banner_url_' + suffix);
    const contentHtml = get(content, 'page_content_' + suffix);

    return (
      <div className="wrapper-container-main">
        <Helmet>
          <title>{t("Meta:parentsNetTube.title")}</title>
          <meta name="description" content={t("Meta:parentsNetTube.description")} />
        </Helmet>
        <div className="container-main">
          <BreadCrumb />
          <div className="wrapper-content">
            <div className="content no-background">
              <div className={"button-wrapper " + (this.state.backgroundColor ? this.state.backgroundColor : '')}>
                <div className="wrap">
                  <div className="ImageText">
                    <div className="upper">
                      <img src={banner} alt="" />
                    </div>
                    <div className="bottom">
                      {this.state.showPrintButton &&
                        <ReactToPrint
                          trigger={() => <div className={"print " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}></div>}
                          content={() => this.componentRef}
                        />
                      }

                      {this.state.showFacebookShareButton &&
                        <FacebookShareButton url={url} className={"facebookShare " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')} />
                      }

                      {this.state.showDownloadButton &&
                        <div className={"download " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}
                          onClick={() => this._downloadFile(file, filename)}>
                        </div>
                      }

                      {this.state.showCopyToClipboardButton &&
                        <div className="wrapper-copyLink">
                          <CopyToClipboard text={url} onCopy={() => this._handleCopyLink()}>
                            <div className={"copyLink " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}></div>
                          </CopyToClipboard>
                          {(this.state.copied ? <div className="message">{t("Common:General.CopyLink")}</div> : '')}
                        </div>
                      }
                    </div>

                    <ComponentToPrint ref={el => (this.componentRef = el)} i18n={i18n} content={contentHtml} title={title} />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SecondarySocialMediaUserGuideDetail));

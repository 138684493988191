// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Styling
import Grid from '@material-ui/core/Grid';

// Api
import { apiHistory } from '../../Api/ApiHistory';

// Redux
import { connect } from 'react-redux';
import { login } from '../../Redux/Action/authAction';

// Utils
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { isMobile, isTablet } from "react-device-detect";

// Children components
import BreadCrumb from '../../components/100Include/BreadCrumb';

class SmartNetizenCompetition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tabIndex: 1,
            // photoIndex: 0,
            isOpen: false,
            mainImage: '',
        }
    }

    componentDidMount() {
        apiHistory.createHistory(window.location.pathname);
    }

    _handleTabChange = (e) => {
        this.setState({
            tabIndex: e
        })
    }


    render() {
        const { t } = this.props;
        const { mainImage, isOpen } = this.state;

        let juniorData = [
            { id: 1, title: t("NetizenCompetition:id1.title"), image: 'img_Characterdesign-Senior-01Champion_S01.png', competitor: t("NetizenCompetition:id1.competitor"), character: t("NetizenCompetition:id1.character"), description: t("NetizenCompetition:id1.description") },
            { id: 2, title: t("NetizenCompetition:id2.title"), image: 'img_Characterdesign-Senior-02Secornd_S02.png', competitor: t("NetizenCompetition:id2.competitor"), character: t("NetizenCompetition:id2.character"), description: t("NetizenCompetition:id2.description") },
            { id: 3, title: t("NetizenCompetition:id3.title"), image: 'img_Characterdesign-Senior-03Third_S11.png', competitor: t("NetizenCompetition:id3.competitor"), character: t("NetizenCompetition:id3.character"), description: t("NetizenCompetition:id3.description") },
            { id: 4, title: t("NetizenCompetition:id4.title"), image: 'img_Characterdesign-Senior-04Merit_Prize_S03.png', competitor: t("NetizenCompetition:id4.competitor"), character: t("NetizenCompetition:id4.character"), description: t("NetizenCompetition:id4.description") },
            { id: 5, title: t("NetizenCompetition:id5.title"), image: 'img_Characterdesign-Senior-05Merit_Prize_S06.png', competitor: t("NetizenCompetition:id5.competitor"), character: t("NetizenCompetition:id5.character"), description: t("NetizenCompetition:id5.description") },
            { id: 6, title: t("NetizenCompetition:id6.title"), image: 'img_Characterdesign-Senior-06Merit_Prize_S05.png', competitor: t("NetizenCompetition:id6.competitor"), character: t("NetizenCompetition:id6.character"), description: t("NetizenCompetition:id6.description") },
        ]

        let seniorData = [
            { id: 1, title: t("NetizenCompetition:id7.title"), image: 'img_Characterdesign-Junior-01Champion_J07.png', competitor: t("NetizenCompetition:id7.competitor"), character: t("NetizenCompetition:id7.character"), description: t("NetizenCompetition:id7.description") },
            { id: 2, title: t("NetizenCompetition:id8.title"), image: 'img_Characterdesign-Junior-02Second_J12.png', competitor: t("NetizenCompetition:id8.competitor"), character: t("NetizenCompetition:id8.character"), description: t("NetizenCompetition:id8.description") },
            { id: 3, title: t("NetizenCompetition:id9.title"), image: 'img_Characterdesign-Junior-03Third_J04.png', competitor: t("NetizenCompetition:id9.competitor"), character: t("NetizenCompetition:id9.character"), description: t("NetizenCompetition:id9.description") },
            { id: 4, title: t("NetizenCompetition:id10.title"), image: 'img_Characterdesign-Junior-04Merit_Prize_J08.png', competitor: t("NetizenCompetition:id10.competitor"), character: t("NetizenCompetition:id10.character"), description: t("NetizenCompetition:id10.description") },
            { id: 5, title: t("NetizenCompetition:id11.title"), image: 'img_Characterdesign-Junior-05Merit_Prize_J13.png', competitor: t("NetizenCompetition:id11.competitor"), character: t("NetizenCompetition:id11.character"), description: t("NetizenCompetition:id11.description") },
            { id: 6, title: t("NetizenCompetition:id12.title"), image: 'img_Characterdesign-Junior-06Merit_Prize_J01.png', competitor: t("NetizenCompetition:id12.competitor"), character: t("NetizenCompetition:id12.character"), description: t("NetizenCompetition:id12.description") },
        ]

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:eventsSmartNetizenCompetition.title")}</title>
                    <meta name="description" content={t("Meta:eventsSmartNetizenCompetition.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    {isOpen && !isMobile && !isTablet &&
                        <Lightbox
                            mainSrc={require('../../images/OurEvents/CharacterDesignCompetition/' + mainImage)}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                        />}
                    <div className="wrapper-content">
                        <div className="content events">
                            <div className="ImageText">
                                <div className="upper">
                                    <img src={require('../../images/OurEvents/banner-ourevents_Ch_design.png')} alt="" />
                                </div>
                                <div className="bottom short">
                                    <p>{t("NetizenCompetition:header.description")}</p>
                                    <p>{t("NetizenCompetition:header.description2")}</p>
                                </div>
                            </div>

                            <div className="CategoryGrid">

                                <div className="inner">
                                    <Grid container spacing={16} className="spacing20">
                                        <Grid item xs={12}>
                                            <div className="Title">
                                                <h2 className="left-align">{t("NetizenCompetition:button.junior")}</h2>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={16} className="spacing40">
                                        {(seniorData.map(
                                            (data, i) => {
                                                return (
                                                    <Grid item sm={4} key={data.id}>
                                                        <div className="Contestor">
                                                            <h3>{data.title}</h3>
                                                            <div className="img">
                                                                <img src={require('../../images/OurEvents/CharacterDesignCompetition/' + data.image)} alt="" onClick={() => this.setState({ isOpen: true, mainImage: data.image })} />
                                                            </div>
                                                            <h5>{data.competitor}</h5>
                                                            <h4>{data.character}</h4>
                                                            <p>{data.description}</p>
                                                        </div>
                                                    </Grid>
                                                )
                                            }
                                        ))}
                                    </Grid>
                                </div>

                                <div className="inner">
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <div className="Title">
                                                <h2 className="left-align">{t("NetizenCompetition:button.senior")}</h2>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={16} className="spacing40">
                                        {(juniorData.map(
                                            (data, i) => {
                                                return (
                                                    <Grid item sm={4} key={data.id}>
                                                        <div className="Contestor">
                                                            <h3>{data.title}</h3>
                                                            <div className="img">
                                                                <img src={require('../../images/OurEvents/CharacterDesignCompetition/' + data.image)} alt="" onClick={() => this.setState({ isOpen: true, mainImage: data.image })} />
                                                            </div>
                                                            <h5>{data.competitor}</h5>
                                                            <h4>{data.character}</h4>
                                                            <p>{data.description}</p>
                                                        </div>
                                                    </Grid>
                                                )
                                            }
                                        ))}
                                    </Grid>
                                </div>

                                <Grid container spacing={16} className="spacing20">
                                    <Grid item xs={12}>
                                        <div className="Title">
                                            <h2 className="left-align">{t("NetizenCompetition:button.group")}</h2>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className="content contact">
                                    <div className="bottom short" style={{ color: 'black' }}>
                                        <h4>{t("NetizenCompetition:idGroup.title")}</h4>
                                        <h4 style={{ color: 'black' }}>{t("NetizenCompetition:idGroup.competitor")}</h4>
                                    </div>

                                </div>

                                {/* <Grid container spacing={16} className="spacing20">
                                    <Grid item xs={6}>
                                        <div className="Title">
                                            <h2>{t("NetizenCompetition:button.junior")}</h2>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="Title">
                                            <h2>{t("NetizenCompetition:button.senior")}</h2>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="Title">
                                            <h2 className="left-align">{t("NetizenCompetition:button.junior")}</h2>
                                        </div>
                                    </Grid>
                                </Grid> */}
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SmartNetizenCompetition)); 

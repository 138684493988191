// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

// Styling
// import Grid from '@material-ui/core/Grid';

// Api
import { apiHistory } from '../../../Api/ApiHistory';

// Redux
import { connect } from 'react-redux';
import { login } from '../../../Redux/Action/authAction';

// Utils
// import { Tabs, Tab } from 'react-bootstrap';

// Children components
import BreadCrumb from '../../../components/100Include/BreadCrumb';
import PrimarySecondary from '../../../components/02ResourcesForYou/PrimarySecondary';

class SecondarSchoolHome extends Component {

    componentDidMount() {
        apiHistory.createHistory(window.location.pathname);
    }

    render() {
        const {
            // i18n, 
            t } = this.props;

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:primarySchoolHome.title")}</title>
                    <meta name="description" content={t("Meta:primarySchoolHome.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content">
                            <div className={"ImageGrid Secondary"}>
                                <div className="upper">
                                    <img src={require('../../../images/ResourcesForYou/PrimarySchool/Banner/banner_resourcesforyou-Primary_School.png')} alt="" />
                                </div>
                                <PrimarySecondary
                                    tabIndex={this.props.tabIndex}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(SecondarSchoolHome)));

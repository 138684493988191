// Essential for all components
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Redux
import { connect } from 'react-redux';

class Breadcrumb extends Component {

    renderSwitch = (currentPath) => {
        const { //t, 
            i18n } = this.props;

        if (i18n.language === "en-US") switch (currentPath) {

            /*** HOME ***/
            case 'forget-password': {
                return (<div><span>Forget Password</span></div>);
            }
            case 'reset-password': {
                return (<div><span>Reset Password</span></div>);
            }

            /*** ABOUT US ***/
            case 'what-is-netizen-academy': {
                return (<div><span><Link to="/">About Us</Link></span> > <span>What is Netizen Academy</span></div>);
            }
            case 'our-mission-and-strategy': {
                return (<div><span><Link to="/">About Us</Link></span> > <span>Our mission and strategy</span></div>);
            }
            case 'our-partners': {
                return (<div><span><Link to="/">About Us</Link></span> > <span>Our Partners</span></div>);
            }
            case 'contact-us': {
                return (<div><span><Link to="/">About Us</Link></span> > <span>Contact us</span></div>);
            }
            case 'sitemap': {
                return (<div><span><Link to="/">About Us</Link></span> > <span>Sitemap</span></div>);
            }

            /*** Who you are ***/

            // Primary School
            case 'primary-school-home': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span>Primary school</span></div>);
            }
            case 'primary-general-tips': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/primary-school-home"}>Primary school</Link></span> > <span>General tips</span></div>);
            }
            case 'primary-social-media-user-guide': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/primary-school-home"}>Primary school</Link></span> > <span> "You Think you know?" Social Media Safety Users Guide</span></div>);
            }
            case 'primary-quiz': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/primary-school-home"}>Primary school</Link></span> > <span>The Netizen Challenge</span></div>);
            }
            case 'primary-video': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/primary-school-home"}>Primary school</Link></span> > <span>Video</span></div>);
            }
            case 'primary-risk-and-danger': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/primary-school-home"}>Primary school</Link></span> > <span>Risk and danger</span></div>);
            }
            case 'primary-game': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/primary-school-home"}>Primary school</Link></span> > <span>Netizen Adventure</span></div>);
            }
            case 'primary-netizen-pledge': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/primary-school-home"}>Primary school</Link></span> > <span>The Netizen Pledge</span></div>);
            }

            // Secondary School
            case 'secondary-school-home': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span>Secondary school</span></div>);
            }
            case 'secondary-general-tips': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/secondary-school-home"}>Secondary school</Link></span> > <span>General tips</span></div>);
            }
            case 'secondary-social-media-user-guide': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/secondary-school-home"}>Secondary school</Link></span> > <span> "You Think you know?" Social Media Safety Users Guide</span></div>);
            }
            case 'secondary-quiz': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/secondary-school-home"}>Secondary school</Link></span> > <span>The Netizen Challenge</span></div>);
            }
            case 'secondary-video': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/secondary-school-home"}>Secondary school</Link></span> > <span>Video</span></div>);
            }
            case 'secondary-risk-and-danger': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/secondary-school-home"}>Secondary school</Link></span> > <span>Risk and danger</span></div>);
            }
            case 'secondary-game': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/secondary-school-home"}>Secondary school</Link></span> > <span>Netizen Adventure</span></div>);
            }
            case 'secondary-netizen-pledge': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/secondary-school-home"}>Secondary school</Link></span> > <span>The Netizen Pledge</span></div>);
            }

            // Parents
            case 'parents-home': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span>Parents and caregivers</span></div>);
            }
            case 'parents-tips': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>Parents and caregivers</Link></span> > <span>Parents tips</span></div>);
            }
            case 'parents-blog': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>Parents and caregivers</Link></span> > <span><Link to={"/" + i18n.language + "/parents-blog"}>Blog</Link></span></div>);
            }
            case 'parents-blog-detail': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>Parents and caregivers</Link></span> > <span><Link to={"/" + i18n.language + "/parents-blog"}>Blog</Link></span> > <span>儿童安全上网工具</span></div>);
            }
            case 'parents-nettube': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>Parents and caregivers</Link></span> > <span><Link to={"/" + i18n.language + "/parents-nettube"}>NetTube</Link></span></div>);
            }
            case 'parents-nettube-detail': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>Parents and caregivers</Link></span> > <span><Link to={"/" + i18n.language + "/parents-nettube"}>NetTube</Link></span> > <span>生活影片</span></div>);
            }
            case 'parents-nettube-detail2': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>Parents and caregivers</Link></span> > <span><Link to={"/" + i18n.language + "/parents-nettube"}>NetTube</Link></span> > <span>生活影片2</span></div>);
            }
            case 'parents-online-safety-checklist': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>Parents and caregivers</Link></span> > <span>More resources on parenting</span></div>);
            }
            case 'parents-training-workshop': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>Parents and caregivers</Link></span> > <span>Training Workshop</span></div>);
            }
            case 'parents-more-resources': {
                return (<div><span><Link to="/">Who you are</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>Parents and caregivers</Link></span> > <span>More resources on parenting</span></div>);
            }

            /*** FEATURED ISSUES ***/
            case 'online-grooming': {
                return (<div><span><Link to="/">Featured Issues</Link></span> > <span>Online grooming</span></div>);
            }
            case 'sexting': {
                return (<div><span><Link to="/">Featured Issues</Link></span> > <span>Sexting</span></div>);
            }
            case 'personal-privacy': {
                return (<div><span><Link to="/">Featured Issues</Link></span> > <span>Personal privacy</span></div>);
            }
            case 'legislations': {
                return (<div><span><Link to="/">Featured Issues</Link></span> > <span>Legislations</span></div>);
            }

            /*** RESEARCH FINDINGS ***/
            case 'our-research': {
                return (<div><span><Link to="/">Research Findings</Link></span> > <span>Our research</span></div>);
            }
            case 'other-findings': {
                return (<div><span><Link to="/">Research Findings</Link></span> > <span>Other findings</span></div>);
            }

            /*** OUR EVENTS ***/
            case 'events-safer-internet-day': {
                return (<div><span><Link to="/">Our Events</Link></span> > <span>Safer Internet Day</span></div>);
            }
            case 'events-smart-netizen-competition': {
                return (<div><span><Link to="/">Our Events</Link></span> > <span>"The Smart Netizen" Character Design Competition</span></div>);
            }
            case 'events-training-workshops': {
                return (<div><span><Link to="/">Our Events</Link></span> > <span>Training workshops</span></div>);
            }

            case 'external-resources': {
                return (<div><span>External Resources</span></div>)
            }

            case 'disclaimer': {
                return (<div><span>Disclaimer</span></div>)
            }

            case 'research-findings': {
                return (<div><span>Research Findings</span></div>)
            }

            default: {
                return (<div><span> <Link to="/">Save the Children</Link></span></div>);
            }
        }

        else switch (currentPath) {

            /*** HOME ***/
            case 'forget-password': {
                return (<div><span>忘記密碼</span></div>);
            }
            case 'reset-password': {
                return (<div><span>重設密碼</span></div>);
            }

            /*** ABOUT US ***/
            case 'what-is-netizen-academy': {
                return (<div><span><Link to="/">關於Net神研究所</Link></span> > <span>甚麼是Net神研究所？</span></div>);
            }
            case 'our-mission-and-strategy': {
                return (<div><span><Link to="/">關於Net神研究所</Link></span> > <span>我們的使命</span></div>);
            }
            case 'our-partners': {
                return (<div><span><Link to="/">關於Net神研究所</Link></span> > <span>合作伙伴</span></div>);
            }
            case 'contact-us': {
                return (<div><span><Link to="/">關於Net神研究所</Link></span> > <span>聯絡我們</span></div>);
            }
            case 'sitemap': {
                return (<div><span><Link to="/">關於Net神研究所</Link></span> > <span>網站地圖</span></div>);
            }

            /*** Who you are ***/

            // Primary School
            case 'primary-school-home': {
                return (<div><span><Link to="/">你是...</Link></span> > <span>小學生（6-12歲）</span></div>);
            }
            case 'primary-general-tips': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/primary-school-home"}>小學生（6-12歲）</Link></span> > <span>Net神小貼士</span></div>);
            }
            case 'primary-social-media-user-guide': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/primary-school-home"}>小學生（6-12歲）</Link></span> > <span>「你以為你知道？  」社交媒體安全使用指南</span></div>);
            }
            case 'primary-quiz': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/primary-school-home"}>小學生（6-12歲）</Link></span> > <span>網絡安全挑戰站</span></div>);
            }
            case 'primary-video': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/primary-school-home"}>小學生（6-12歲）</Link></span> > <span>NetTube影院</span></div>);
            }
            case 'primary-risk-and-danger': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/primary-school-home"}>小學生（6-12歲）</Link></span> > <span>網絡的風險</span></div>);
            }
            case 'primary-game': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/primary-school-home"}>小學生（6-12歲）</Link></span> > <span>遊戲：Net神大冒險</span></div>);
            }
            case 'primary-netizen-pledge': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/primary-school-home"}>小學生（6-12歲）</Link></span> > <span>NET神約章</span></div>);
            }

            // Secondary School
            case 'secondary-school-home': {
                return (<div><span><Link to="/">你是...</Link></span> > <span>中學生（12-18歲）</span></div>);
            }
            case 'secondary-general-tips': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/secondary-school-home"}>中學生（12-18歲）</Link></span> > <span>Net神小貼士</span></div>);
            }
            case 'secondary-social-media-user-guide': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/secondary-school-home"}>中學生（12-18歲）</Link></span> > <span>「你以為你知道？  」社交媒體安全使用指南</span></div>);
            }
            case 'secondary-quiz': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/secondary-school-home"}>中學生（12-18歲）</Link></span> > <span>網絡安全挑戰站</span></div>);
            }
            case 'secondary-video': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/secondary-school-home"}>中學生（12-18歲）</Link></span> > <span>NetTube影院</span></div>);
            }
            case 'secondary-risk-and-danger': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/secondary-school-home"}>中學生（12-18歲）</Link></span> > <span>網絡的風險</span></div>);
            }
            case 'secondary-game': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/secondary-school-home"}>中學生（12-18歲）</Link></span> > <span>遊戲：Net神的跳躍之旅</span></div>);
            }
            case 'secondary-netizen-pledge': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/secondary-school-home"}>中學生（12-18歲）</Link></span> > <span>>NET神約章</span></div>);
            }

            // Parents
            case 'parents-home': {
                return (<div><span><Link to="/">你是...</Link></span> > <span>家長及照顧者</span></div>);
            }
            case 'parents-tips': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>家長及照顧者</Link></span> > <span>家長小貼士</span></div>);
            }
            case 'parents-blog': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>家長及照顧者</Link></span> > <span><Link to={"/" + i18n.language + "/parents-blog"}>Net神網誌</Link></span></div>);
            }
            case 'parents-blog-detail': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>家長及照顧者</Link></span> > <span><Link to={"/" + i18n.language + "/parents-blog"}>Net神網誌</Link></span> > <span>儿童安全上网工具</span></div>);
            }
            case 'parents-nettube': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>家長及照顧者</Link></span> > <span><Link to={"/" + i18n.language + "/parents-nettube"}>NetTube影院</Link></span></div>);
            }
            case 'parents-nettube-detail': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>家長及照顧者</Link></span> > <span><Link to={"/" + i18n.language + "/parents-nettube"}>NetTube影院</Link></span> > <span>生活影片</span></div>);
            }
            case 'parents-nettube-detail2': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>家長及照顧者</Link></span> > <span><Link to={"/" + i18n.language + "/parents-nettube"}>NetTube影院</Link></span> > <span>生活影片2</span></div>);
            }
            case 'parents-online-safety-checklist': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>家長及照顧者</Link></span> > <span>親子承諾書</span></div>);
            }
            case 'parents-training-workshop': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>家長及照顧者</Link></span> > <span>保護兒童網絡安全工作坊</span></div>);
            }
            case 'parents-more-resources': {
                return (<div><span><Link to="/">你是...</Link></span> > <span><Link to={"/" + i18n.language + "/parents-home"}>家長及照顧者</Link></span> > <span>其他親子資源</span></div>);
            }

            /*** FEATURED ISSUES ***/
            case 'online-grooming': {
                return (<div><span><Link to="/">專題</Link></span> > <span>網路性誘拐</span></div>);
            }
            case 'sexting': {
                return (<div><span><Link to="/">專題</Link></span> > <span>色情短訊</span></div>);
            }
            case 'personal-privacy': {
                return (<div><span><Link to="/">專題</Link></span> > <span>個人私隱</span></div>);
            }
            case 'legislations': {
                return (<div><span><Link to="/">專題</Link></span> > <span>相關法例</span></div>);
            }

            /*** RESEARCH FINDINGS ***/
            case 'our-research': {
                return (<div><span><Link to="/">研究報告</Link></span> > <span>我們的研究</span></div>);
            }
            case 'other-findings': {
                return (<div><span><Link to="/">研究報告</Link></span> > <span>其他研究</span></div>);
            }

            /*** OUR EVENTS ***/
            case 'events-safer-internet-day': {
                return (<div><span><Link to="/">我們的活動</Link></span> > <span>國際互聯網安全日</span></div>);
            }
            case 'events-smart-netizen-competition': {
                return (<div><span><Link to="/">我們的活動</Link></span> > <span>「全民做Net神」角色設計比賽</span></div>);
            }
            case 'events-training-workshops': {
                return (<div><span><Link to="/">我們的活動</Link></span> > <span>保護兒童網絡安全工作坊</span></div>);
            }

            case 'external-resources': {
                return (<div><span>有用連結</span></div>)
            }

            case 'disclaimer': {
                return (<div><span>聲明</span></div>)
            }

            case 'research-findings': {
                return (<div><span>研究報告</span></div>)
            }

            default: {
                return (<div><span> <Link to="/">救助兒童會</Link></span></div>);
            }
        }
    }

    render() {
        // const { t, i18n } = props;

        let pathname = this.props.route.location.pathname,
            urlArray = pathname.split("/"),
            currentPath = urlArray[2];

        return (
            <div className="Breadcrumb">
                {this.renderSwitch(currentPath)}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    route: state.router
});

export default withTranslation()(connect(mapStateToProps)(Breadcrumb));

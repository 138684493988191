// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
// import Slider from "react-slick";

// Styling

// Api
import { apiHistory } from '../../Api/ApiHistory';
import { apiWebPage } from '../../Api/ApiWebPage';

// Redux
import { connect } from 'react-redux';
import { login } from '../../Redux/Action/authAction';

// Utils
import { filter, get } from 'lodash-es';
import { createMarkup } from '../../utils/CommonUtils';

// Children components
import BreadCrumb from '../../components/100Include/BreadCrumb';
// import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';

class SaferInternetDay2019 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formSubmitted: false,
            contentContent: null,
            language: null,
            loading: false
        }
    }

    componentDidMount() {
        this.updateHtml();
        apiHistory.createHistory(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
            this.updateHtml();
        }
    }

    updateHtml() {
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiWebPage.getPageData('/events-safer-internet-day', (result) => {
            const language = get(this, 'props.i18n.language');
            const newState = {
                loading: false,
                language
            };
            const body = get(result, 'body');
            if (body && body.length > 0) {
                const attr = language && language.toLowerCase() === 'en-us' ? 'html_en' : 'html_zh_cht';

                const contents = filter(body, ['section', 'content']);
                if (contents && contents.length > 0) {
                    newState.contentContent = contents[0][attr];
                } else {
                    console.log('no content section');
                }
            }
            this.setState(newState);
        });
    }

    render() {
        const { t } = this.props;

        /*
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            dotsClass: 'slickDots1',
        }
        */

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:eventsSaferInternetDay2019.title")}</title>
                    <meta name="description" content={t("Meta:eventsSaferInternetDay2019.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content no-background">
                            <div className="ImageText">
                                <div className="bottom">
                                    <div dangerouslySetInnerHTML={createMarkup(this.state.contentContent)} />
                                    {/* <h3>{t("OurEvents:SaferInternetDay2019.title")}</h3>
                                    <p>{t("OurEvents:SaferInternetDay2019.content")}</p> */}
                                    {/* <div dangerouslySetInnerHTML={createMarkup(t("OurEvents:SaferInternetDay2019.html1"))} />
                                    <div>
                                        <img src={require('../../images/OurEvents/SaferInternetDay/Safer Internet Day_v3-01.png')} alt="" />
                                    </div>
                                    <div dangerouslySetInnerHTML={createMarkup(t("OurEvents:SaferInternetDay2019.html2"))} />
                                    <div>
                                        <img src={require('../../images/OurEvents/SaferInternetDay/eHelp 001.jpg')} alt="" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SaferInternetDay2019));

// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom'; 
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

// Styling
import Grid from '@material-ui/core/Grid';

// Api
import { apiHistory } from '../../../Api/ApiHistory';
import { apiListSecondarySocialMediaGuides } from '../../../Api/ApiListSecondarySocialMediaGuides';

// Redux
import { connect } from 'react-redux';
import { login } from '../../../Redux/Action/authAction';

// Utils
import { get } from 'lodash-es';
import { createMarkup } from '../../../utils/CommonUtils';

// Children components
import BreadCrumb from '../../../components/100Include/BreadCrumb';

class SecondarySocialMediaUserGuide extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formSubmitted: false,
            contents: [],
            language: null,
            loading: false
        }
    }

    componentDidMount() {
        this.updateHtml();
        apiHistory.createHistory(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
            this.updateHtml();
        }
    }

    updateHtml() {
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiListSecondarySocialMediaGuides.getSocialMediaGuides((result) => {
            this.setState({
                contents: get(result, 'body') || [],
                loading: false,
                language: get(this, 'props.i18n.language')
            });
        });
    }

    _goToDetail = (id) => {
        this.props.history.push("/" + this.props.i18n.language + '/secondary-social-media-user-guide/' + id);
    }

    render() {
        const { t } = this.props;
        const {
            contents
        } = this.state;

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:primarySocialMediaUserGuide.title")}</title>
                    <meta name="description" content={t("Meta:primarySocialMediaUserGuide.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content">

                            {/* Start of grid */}
                            <div className="GridGrid userGuide">
                                <div className="upper">
                                    <img src={require('../../../images/ResourcesForYou/PrimarySchool/Banner/banner_resourcesforyou-Primary_School-Social_media_user_guide.png')} alt="" />
                                </div>
                                <div className="lower">
                                    <div className="bottom">
                                        <h3>{t("PrimarySocialMedia:title")}</h3>
                                    </div>
                                    <Grid container spacing={16}>
                                        {contents && contents.length > 0 && contents.map(content => {
                                            const language = get(this, 'props.i18n.language');
                                            const langAttr = language && language.toLowerCase() === 'en-us' ? 'en' : 'zh_cht';
                                            const attr = 'html_' + langAttr;
                                            const bannerUrlAttr = 'banner_url_' + langAttr;
                                            const id = content.list_secondary_social_media_guide_id;
                                            return <Grid item sm={6} key={id}>
                                                <div className="grid">
                                                    <div className="top">
                                                        <img src={content[bannerUrlAttr]} alt="" />
                                                    </div>
                                                    <div className="bottom">
                                                        <div
                                                            dangerouslySetInnerHTML={createMarkup(content[attr])}
                                                        ></div>
                                                        <div className="twoImageButton">
                                                            <img src={require('../../../images/ResourcesForYou/UserGuide/' + t("PrimarySocialMedia:buttonNameiOS"))} alt="" onClick={() => this._goToDetail(id + '_ios')} />
                                                            <img src={require('../../../images/ResourcesForYou/UserGuide/' + t("PrimarySocialMedia:buttonNameAndroid"))} alt="" onClick={() => this._goToDetail(id + '_android')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>;
                                        })}
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(SecondarySocialMediaUserGuide)));

import { apiGeneral } from './_General';
import { isFunction } from 'lodash-es';

export const apiQuizResult = {

    updateQuizData: (username, primaryReferenceId, data, token, cb, eCb) => {
        if (primaryReferenceId == null) {
            const error = new Error('gameId is not provided');
            if (isFunction(eCb)) {
                eCb(error);
            } else {
                console.error(error);
            }
        } else if (username) {
            apiGeneral.apiFetch(
                'user_data',
                {
                    primary_reference_id: primaryReferenceId,
                    user: username
                },
                token,
                (retrievedData) => {
                    const status = retrievedData.status;
                    const dataBody = retrievedData.body;
                    const userDataObject = {
                        primary_reference_id: primaryReferenceId,
                        user: username,
                        data: JSON.stringify(data)
                    };
                    if (status >= 200 && status < 300 && dataBody && dataBody.length > 0) {
                        const existedData = retrievedData.body[0];
                        apiGeneral.apiPut(
                            `user_data/${existedData.user_data_id}`,
                            userDataObject,
                            token,
                            (response) => {
                                response.json().then((data) => {
                                    cb({
                                        status: response.status,
                                        body: data
                                    });
                                });
                            },
                            eCb
                        );
                    } else {
                        apiGeneral.apiPost('user_data', userDataObject, token, cb, eCb);
                    }
                },
                eCb
            );
        } else {
            apiGeneral.apiPost('public/user_data', {
                primary_reference_id: primaryReferenceId,
                data: JSON.stringify(data)
            }, null, cb, eCb);
        }
    }
};

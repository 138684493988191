// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Styling

// Api
import { apiHistory } from '../../Api/ApiHistory';

// Redux
import { connect } from 'react-redux';
import { login } from '../../Redux/Action/authAction';

// Utils

// Children components
import BreadCrumb from '../../components/100Include/BreadCrumb';

class ContactUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formSubmitted: false
        }
    }

    componentDidMount() {
        apiHistory.createHistory(window.location.pathname);
    }

    render() {
        const { t } = this.props;

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:contactus.title")}</title>
                    <meta name="description" content={t("Meta:contactus.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content contact">
                            <div>
                                <div className="upper">
                                    <img src={require('../../images/AboutUs/banner_aboutus-03Contact_us.png')} alt="" />
                                </div>
                                <div className="bottom narrow">
                                    <a href="http://www.savethechildren.org.hk" target="_blank" rel="noopener noreferrer">
                                        <img src={require('../../images/img_homepage_logo-schk.png')} alt="" style={{ width: '280px' }} />
                                    </a>
                                    <p className="facebook"><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/savethechildrenhk">@savethechildrenhk</a></p>
                                    <p className="instagram"><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/savethechildrenhk">@savethechildrenhk</a></p>
                                    <br />
                                    <br />
                                    <h2>{t("Sitemap:AboutNetizenAcademy.children.Contactus")}</h2>
                                    <p className="tel"><a target="_blank" rel="noopener noreferrer" href="tel:+85221125640">{t("AboutUs:contactUs.phone")}</a></p>
                                    <p className="fax"><a target="_blank" rel="noopener noreferrer" href="tel:+85231608685">{t("AboutUs:contactUs.fax")}</a></p>
                                    <p className="email"><a target="_blank" rel="noopener noreferrer" href="mailto:hk.program@savethchildren.org">{t("AboutUs:contactUs.email")}</a></p>
                                    <p className="address">{t("AboutUs:contactUs.address")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContactUs));

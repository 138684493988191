// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

// Styling
import Grid from '@material-ui/core/Grid';

// Api
import { apiHistory } from '../../../Api/ApiHistory';
import { apiListSecondaryNetTubes } from '../../../Api/ApiListSecondaryNetTubes';

// Redux
import { connect } from 'react-redux';
import { login } from '../../../Redux/Action/authAction';

// Utils
import { get } from 'lodash-es';
import { createMarkup } from '../../../utils/CommonUtils';

// Children components
import BreadCrumb from '../../../components/100Include/BreadCrumb';

class SecondaryVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contents: [],
      language: null,
      loading: false
    };
  }

  componentDidMount() {
    this.updateHtml();
    apiHistory.createHistory(window.location.pathname);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { language, loading } = this.state;
    if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
      this.updateHtml();
    }
  }

  updateHtml() {
    this.setState({
      loading: true,
      language: get(this, 'props.i18n.language')
    });
    apiListSecondaryNetTubes.getBlogs((result) => {
      this.setState({
        contents: get(result, 'body') || [],
        loading: false,
        language: get(this, 'props.i18n.language')
      });
    });
  }

  _goToDetail = (id) => {
    this.props.history.push("/" + this.props.i18n.language + '/secondary-video/' + id);
  }

  render() {
    const { t
      // , i18n 
    } = this.props;
    const {
      contents
    } = this.state;

    return (
      <div className="wrapper-container-main">
        <Helmet>
          <title>{t("Meta:parentsBlog.title")}</title>
          <meta name="description" content={t("Meta:parentsBlog.description")} />
        </Helmet>
        <div className="container-main">
          <BreadCrumb />
          <div className="wrapper-content">
            <div className="content">
              <Grid container spacing={16}>
                {contents && contents.length > 0 && contents.map(content => {
                  const language = get(this, 'props.i18n.language');
                  const attr = language && language.toLowerCase() === 'en-us' ? 'html_en' : 'html_zh_cht';
                  return <Grid item sm={6} key={content.list_secondary_net_tube_id}>
                    <div
                      className="ImageGrid2"
                      onClick={() => this._goToDetail(content.list_secondary_net_tube_id)}
                      dangerouslySetInnerHTML={createMarkup(content[attr])}
                    ></div>
                  </Grid>;
                })}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(SecondaryVideo)));

// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Styling

// Api
import { apiHistory } from '../../Api/ApiHistory';
import { apiWebPage } from '../../Api/ApiWebPage';

// Redux
import { connect } from 'react-redux';
import { login } from '../../Redux/Action/authAction';

// Utils
import ReactToPrint from 'react-to-print';
import CommonUtils, { createMarkup } from '../../utils/CommonUtils';
import { FacebookShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { WEBSITE_URL } from '../../config';
import { filter, get } from 'lodash-es';

// Children components
import BreadCrumb from '../../components/100Include/BreadCrumb';
// import Buttons from '../../components/100Include/Buttons';

class ComponentToPrint extends React.Component {

    render() {
        return (
            <div>
                <div className="upper">
                    <div dangerouslySetInnerHTML={createMarkup(this.props.content)} />
                </div>
            </div>
        )
        // if (this.props.i18n.language === 'en-US') {
        //     return (
        //         <div className="upper">
        //             <img src={require('../../images/FeaturedIssues/Sexting/img_featuredissues-sexting_V2.png')} alt="" style={{ maxWidth: 'none' }} />
        //         </div>
        //     );
        // } else {
        //     return (
        //         <div className="upper">
        //             <img src={require('../../images/FeaturedIssues/Sexting/img_featuredissues-sexting_CH_V2.png')} alt="" style={{ maxWidth: 'none' }} />
        //         </div>
        //     );
        // }
    }
}


class Sexting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundColor: 'white',
            showPrintButton: true,
            showFacebookShareButton: true,
            showDownloadButton: true,
            showCopyToClipboardButton: true,
            copied: false,
            contentUpper: null,
            contentContent: null,
            language: null,
            loading: false
        }
    }

    componentDidMount() {
        this.updateHtml();
        apiHistory.createHistory(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
            this.updateHtml();
        }
    }

    updateHtml() {
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiWebPage.getPageData('/sexting', (result) => {
            const language = get(this, 'props.i18n.language');
            const newState = {
                loading: false,
                language
            };
            const body = get(result, 'body');
            if (body && body.length > 0) {
                const attr = language && language.toLowerCase() === 'en-us' ? 'html_en' : 'html_zh_cht';

                const uppers = filter(body, ['section', 'upper']);
                if (uppers && uppers.length > 0) {
                    newState.contentUpper = uppers[0][attr];
                } else {
                    console.log('no upper section');
                }

                const contents = filter(body, ['section', 'content']);
                if (contents && contents.length > 0) {
                    newState.contentContent = contents[0][attr];
                } else {
                    console.log('no content section');
                }
            }
            this.setState(newState);
        });
    }

    _downloadFile = (file_url, file_name) => {
        // Bluebird.delay(0, file_url).then((url) => {
        CommonUtils.forceDownload(file_url, file_name);
        // });
    }

    _handleCopyLink = () => {
        this.setState({
            copied: true
        });
        setTimeout(() => {
            this.setState({
                copied: false
            });
        }, 3000);
    }

    render() {
        const { t, i18n } = this.props;

        const currentURL = this.props.router.location.pathname;
        // const url = window.location.protocol + '//' + window.location.hostname + currentURL;
        const url = WEBSITE_URL + currentURL;

        let file = require('../../uploads/' + t("Upload:sexting"));

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:sexting.title")}</title>
                    <meta name="description" content={t("Meta:sexting.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content">
                            <div className="ImageText">
                                <div className="upper">
                                    {/* <img src={require('../../images/FeaturedIssues/Sexting/banner_featured Issues-Sexting.png')} alt="" /> */}
                                    <div dangerouslySetInnerHTML={createMarkup(this.state.contentUpper)} />
                                </div>
                                <ComponentToPrint ref={el => (this.componentRef = el)} i18n={i18n} content={this.state.contentContent} />
                                {/* </div> */}

                                <div className={"button-wrapper "
                                    + (this.state.backgroundColor ? this.state.backgroundColor : '')}>
                                    <div className="wrap">
                                        {this.state.showPrintButton &&
                                            <ReactToPrint
                                                trigger={() => <div className={"print " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}></div>}
                                                content={() => this.componentRef}
                                            />
                                        }

                                        {this.state.showFacebookShareButton &&
                                            <FacebookShareButton url={url} className={"facebookShare " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')} />
                                        }

                                        {this.state.showDownloadButton &&
                                            <div className={"download " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}
                                                onClick={() => this._downloadFile(file, t("Upload:sexting"))}>
                                            </div>
                                        }

                                        {this.state.showCopyToClipboardButton &&
                                            <div className="wrapper-copyLink">
                                                <CopyToClipboard text={url} onCopy={() => this._handleCopyLink()}>
                                                    <div className={"copyLink " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}></div>
                                                </CopyToClipboard>
                                                {(this.state.copied ? <div className="message">{t("Common:General.CopyLink")}</div> : '')}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <Buttons
                                backgroundColor='white'
                                file={t("Upload:sexting")}
                                componentRef={this.componentRef}
                                showPrintButton={true}
                                showFacebookShareButton={true}
                                showDownloadButton={true}
                                showCopyToClipboardButton={true}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    router: state.router
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Sexting));

// Essential for all components
import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Styling

// Api
import { apiHistory } from '../../../Api/ApiHistory';
import { apiListBlog } from '../../../Api/ApiListBlog';

// Redux
import { connect } from 'react-redux';
import { login } from '../../../Redux/Action/authAction';

// Utils
import { get } from 'lodash-es';
import { createMarkup } from '../../../utils/CommonUtils';

// Children components
import BreadCrumb from '../../../components/100Include/BreadCrumb';

class ParentsBlogDetail extends Component {
    constructor(props) {
        super(props);

        const id = props.id;
        this.state = {
            formSubmitted: false,
            isCmsContent: id && id.length === 36,
            contentUpper: null,
            contentContent: null,
            language: null,
            loading: false
        };
    }

    componentDidMount() {
        this.updateHtml();
        apiHistory.createHistory(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
            this.updateHtml();
        }
    }

    updateHtml() {
        if (!this.state.isCmsContent) {
            return;
        }
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiListBlog.getBlog(this.props.id, (result) => {
            const language = get(this, 'props.i18n.language');
            const newState = {
                loading: false,
                language
            };
            const body = get(result, 'body');
            if (result.status === 200 && body) {
                const langAttr = language && language.toLowerCase() === 'en-us' ? 'en' : 'zh_cht';
                newState.contentUpper = body['page_upper_content_' + langAttr];
                newState.contentContent = body['page_content_' + langAttr];
            }
            this.setState(newState);
        });
    }

    getTop() {
        switch (this.props.id) {

            case '1': {
                return (
                    <iframe title="POPA"
                        src="https://www.youtube.com/embed/wp8__qHYKDE?rel=0">
                    </iframe>
                );
            }
            case '2': {
                return (
                    <iframe title="POPA"
                        src="https://www.youtube.com/embed/qWTw2jW6j40?rel=0">
                    </iframe>
                    //<img src={require('../../../images/ResourcesForYou/Parents/Button/btn_parents_01_blog.png')} alt="" />
                );
            }
            case '3': {
                return (
                    <p></p>
                    // <img src={require('../../../images/ResourcesForYou/Parents/Button/btn_parents_01_blog.png')} alt="" />
                );
            }
            case '4': {
                return (
                    <img src={require('../../../images/ResourcesForYou/Parents/Button/btn_parents_01_blog.png')} alt="" />
                );
            }
            case '5': {
                return (
                    <img src={require('../../../images/ResourcesForYou/Parents/Button/btn_parents_01_blog.png')} alt="" />
                );
            }
            case '6': {
                return (
                    <img src={require('../../../images/ResourcesForYou/Parents/Button/btn_parents_01_blog.png')} alt="" />
                );
            }
            default: return ''
        }
    }

    getBottom() {

        const { t } = this.props;

        switch (this.props.id) {

            case '1': {
                return (
                    <div className="bottom">
                        <h3>{t("ParentsBlogDetail:blog1.title")}</h3>
                        <h5 align='right'>{t("ParentsBlogDetail:blog1.author")}</h5>
                        <p>{t("ParentsBlogDetail:blog1.content")}</p>
                        {/* <div dangerouslySetInnerHTML={createMarkup(t("ParentsBlogDetail:blog1.content"))} /> */}
                        {/* <p>{t("ParentsBlogDetail:blog1.video")}</p> */}
                    </div>
                );
            }
            case '2': {
                return (
                    <div className="bottom">
                        <h3>{t("ParentsBlogDetail:blog2.title")}</h3>
                        <h5 align='right'>{t("ParentsBlogDetail:blog2.author")}</h5>
                        <p>{t("ParentsBlogDetail:blog2.content")}</p>
                        {/* <p>{t("ParentsBlogDetail:blog2.video")}</p> */}
                    </div>
                );
            }
            case '3': {
                return (
                    <div className="bottom">
                        <h3>{t("ParentsBlogDetail:blog3.title")}</h3>
                        <h5 align='right'>{t("ParentsBlogDetail:blog3.author")}</h5>
                        <div dangerouslySetInnerHTML={createMarkup(t("ParentsBlogDetail:blog3.content"))} />
                        {/* <p>{t("ParentsBlogDetail:blog3.video")}</p> */}
                    </div>
                );
            }
            case '4': {
                return (
                    <div className="bottom">
                        <h3>{t("ParentsBlogDetail:blog4.title")}</h3>
                        <h5 align="right">{t("ParentsBlogDetail:blog4.author")}</h5>
                        <div dangerouslySetInnerHTML={createMarkup(t("ParentsBlogDetail:blog4.content"))} />
                        {/* <p>{t("ParentsBlogDetail:blog4.video")}</p> */}
                    </div>
                );
            }
            case '5': {
                return (
                    <div className="bottom">
                        <h3>{t("ParentsBlogDetail:blog5.title")}</h3>
                        <h5>{t("ParentsBlogDetail:blog5.author")}</h5>
                        <div dangerouslySetInnerHTML={createMarkup(t("ParentsBlogDetail:blog5.content"))} />
                        <p>{t("ParentsBlogDetail:blog5.video")}</p>
                    </div>
                );
            }
            case '6': {
                return (
                    <div className="bottom">
                        <h3>{t("ParentsBlogDetail:blog6.title")}</h3>
                        <h5>{t("ParentsBlogDetail:blog6.author")}</h5>
                        <div dangerouslySetInnerHTML={createMarkup(t("ParentsBlogDetail:blog6.content"))} />
                        <p>{t("ParentsBlogDetail:blog6.video")}</p>
                    </div>
                );
            }
            default: return ''
        }
    }

    renderOldContent() {
        return <Fragment>
            <div className="upper">
                {this.getTop()}
            </div>
            {this.getBottom()}
        </Fragment>;
    }

    renderCmsContent() {
        const { contentUpper, contentContent } = this.state;
        return <Fragment>
            <div
                className="upper"
                dangerouslySetInnerHTML={createMarkup(contentUpper)}
            ></div>
            <div
                className="bottom"
                dangerouslySetInnerHTML={createMarkup(contentContent)}
            ></div>
        </Fragment>;
    }

    render() {
        const { t } = this.props;
        const { isCmsContent } = this.state;

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:parentsBlogDetail.title")}</title>
                    <meta name="description" content={t("Meta:parentsBlogDetail.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content">
                            <div className="BlogDetail">
                                {isCmsContent ? this.renderCmsContent() : this.renderOldContent()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ParentsBlogDetail));

// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Styling
// import Grid from '@material-ui/core/Grid';

// Api
import { apiHistory } from '../../Api/ApiHistory';
import { apiWebPage } from '../../Api/ApiWebPage';

// Redux
import { connect } from 'react-redux';
import { login } from '../../Redux/Action/authAction';

// Utils
import { filter, get } from 'lodash-es';
import { createMarkup } from '../../utils/CommonUtils';

// Children components
import BreadCrumb from '../../components/100Include/BreadCrumb';
// import i18next from 'i18next';

class SiteMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: null,
            language: null,
            loading: false
        };
    }

    componentDidMount() {
        this.updateHtml();
        apiHistory.createHistory(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
            this.updateHtml();
        }
    }

    updateHtml() {
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiWebPage.getPageData('/sitemap', (result) => {
            const language = get(this, 'props.i18n.language');
            const newState = {
                loading: false,
                language
            };
            const body = get(result, 'body');
            if (body && body.length > 0) {
                const attr = language && language.toLowerCase() === 'en-us' ? 'html_en' : 'html_zh_cht';

                const contents = filter(body, ['section', 'content']);
                if (contents && contents.length > 0) {
                    newState.content = contents[0][attr];
                } else {
                    console.log('no content section');
                }
            }
            this.setState(newState);
        });
    }

    render() {
        const { t } = this.props;

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:sitemap.title")}</title>
                    <meta name="description" content={t("Meta:sitemap.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content sitemap">
                            <div
                                className="ImageText"
                                dangerouslySetInnerHTML={createMarkup(this.state.content)}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SiteMap));

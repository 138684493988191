// Essential for all components
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Redux
import { connect } from 'react-redux';
import { logout } from '../../Redux/Action/authAction';

// Utils
import Popup from "reactjs-popup";
import { debounce } from 'lodash-es';

// Children components
import LoginRegister from '../../containers/00Home/LoginRegister';
import { apiAuth } from '../../Api/ApiAuth';

import { SCREEN_SIZE_SM } from '../../config';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isDesktop: this.getIsDesktop()
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleWindowResize = debounce(this.handleWindowResize.bind(this), 250);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        window.addEventListener('orientationchange', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('orientationchange', this.handleWindowResize);
        window.removeEventListener('resize', this.handleWindowResize);
    }

    openModal() {
        this.setState({ open: true });
    }

    closeModal() {
        this.setState({ open: false });
    }

    handleLogout() {
        return apiAuth.revoke(this.props.members.token).finally(() => {
            return this.props.logoutP();
        });
    }

    handleWindowResize(evt) {
        this.setState({
            isDesktop: this.getIsDesktop()
        });
    }

    getIsDesktop() {
        return window.matchMedia('(min-width: ' + SCREEN_SIZE_SM + 'px)').matches;
    }

    changeLanguage(param) {

        switch (param) {
            case 'zh-HK':
                param = 'zh-HK';
                break;
            case 'en-US':
                param = 'en-US';
                break;
            default:
                param = 'zh-HK';
        }
        this.props.i18n.changeLanguage(param);
    }

    render() {
        const { t, i18n, members, route } = this.props;
        const { isDesktop } = this.state;

        let location = route.location,
            pathname = location.pathname,
            routeUrl = pathname.substring(6) + location.search,
            urlArray = pathname.split("/"),
            currentURL = urlArray[2],
            language = i18n.language,
            auth = members.auth;

        return (
            <div className="wrapper-header">
                <div className="header clearfix">
                    {currentURL &&
                        <h1 className="logo-NA-top">
                            <Link className="header-child" to={"/" + language + '/'}>
                                {
                                    isDesktop ?
                                        <img
                                            src={require('../../images/img_homepage_logo-NA.png')}
                                            alt=""
                                        /> :
                                        <img
                                            src={require('../../images/Header/img_homepage_logo-NA-mobile.png')}
                                            alt=""
                                            className="mobile-top-logo"
                                        />
                                }
                            </Link>
                        </h1>
                    }

                    <h1 className="header-child logo-schk">
                        <a href="http://www.savethechildren.org.hk" target="_blank" rel="noopener noreferrer">
                            {
                                isDesktop ?
                                    <img
                                        src={require('../../images/img_homepage_logo-schk.png')}
                                        alt=""
                                    /> :
                                    <img
                                        src={require('../../images/Header/STC-logo-exceptional-colour.png')}
                                        alt=""
                                        className="mobile-schk-logo"
                                    />
                            }
                        </a>
                        {/* <Link to={"/" + language + '/'}><img src={require('../../images/img_homepage_logo-schk.png')} alt="" /></Link> */}
                    </h1>

                    <ul className="header-child desktop-language">
                        <li><Link to={"/zh-HK" + routeUrl} onClick={() => this.changeLanguage('zh-HK')} className={language === 'zh-HK' ? 'active' : ''}>{t("Common:General.Language1")}</Link></li>
                        <li><Link to={"/en-US" + routeUrl} onClick={() => this.changeLanguage('en-US')} className={language === 'en-US' ? 'active' : ''}>{t("Common:General.Language2")}</Link></li>
                    </ul>

                    {auth &&
                        <div className="header-child helloUser">
                            <span>{t("Common:General.Hello")}</span>
                            <span>{members.userInfo.display_name}</span>
                        </div>
                    }

                    {auth ?
                        <span className="header-child desktop-logout" onClick={this.handleLogout}>{t("Common:General.Logout")}</span> :
                        <span className="header-child desktop-login" onClick={this.openModal}>{t("Common:General.Login")}</span>
                    }

                    <Popup
                        open={this.state.open}
                        closeOnDocumentClick
                        onClose={this.closeModal}
                        contentStyle={{ padding: "0px", border: "none" }}
                    >
                        <LoginRegister open={this.state.open} close={this.closeModal} handleClose={this.closeModal} />

                        {/* <div className="modalhello">
                            <a className="close" onClick={this.closeModal}>&times;</a>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae magni
                            omnis delectus nemo, maxime molestiae dolorem numquam mollitia, voluptate
                            ea, accusamus excepturi deleniti ratione sapiente! Laudantium, aperiam
                            doloribus. Odit, aut.
                        </div> */}
                    </Popup>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        route: state.router,
        members: state.auth
    }
);

const mapDispatchToProps = dispatch => ({
    logoutP: data => dispatch(logout(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Header));

// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Styling

// Api
import { apiAuth } from '../../Api/ApiAuth';
import { apiHistory } from '../../Api/ApiHistory';
import { apiPages } from '../../Api/ApiPages';
import { apiWebPage } from '../../Api/ApiWebPage';

// Redux
import { connect } from 'react-redux';

// Utils
import { filter, get } from 'lodash-es';
import { createMarkup } from '../../utils/CommonUtils';

// Children components
import BreadCrumb from '../../components/100Include/BreadCrumb';

class ResearchFindings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title_en_us: '',
            content_en_us: '',
            title_zh_hk: '',
            content_zh_hk: '',
            contentUpper: null,
            contentContent: null,
            language: null,
            loading: false
        }
    }

    componentDidMount = () => {
        apiAuth.getClientCredentials().then((res) => {
            // console.log(res);
            if (res) {
                this._getPageByRelativePath(res.access_token);
            }
        })
        this.updateHtml();
        apiHistory.createHistory(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
            this.updateHtml();
        }
    }

    updateHtml() {
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiWebPage.getPageData('/research-findings', (result) => {
            const language = get(this, 'props.i18n.language');
            const newState = {
                loading: false,
                language
            };
            const body = get(result, 'body');
            if (body && body.length > 0) {
                const attr = language && language.toLowerCase() === 'en-us' ? 'html_en' : 'html_zh_cht';

                const uppers = filter(body, ['section', 'upper']);
                if (uppers && uppers.length > 0) {
                    newState.contentUpper = uppers[0][attr];
                } else {
                    console.log('no upper section');
                }

                const contents = filter(body, ['section', 'content']);
                if (contents && contents.length > 0) {
                    newState.contentContent = contents[0][attr];
                } else {
                    console.log('no content section');
                }
            }
            this.setState(newState);
        });
    }

    _getPageByRelativePath = (token) => {
        const cb = (obj) => {
            // console.log("cb : ", obj);
            this.setState({
                ...this.state,
                title_en_us: obj.body[0].title_en,
                content_en_us: obj.body[0].html_en,

                title_zh_hk: obj.body[0].title_zh_cht,
                content_zh_hk: obj.body[0].html_zh_cht,
            })
        }

        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }

        const params = {
            path: 'our-research',
            $expand: `web_content_item/cover_image,web_page_media/file`
        };

        apiPages.getPageByRelativePath(params, token, cb, eCb);
    }

    render() {
        const { t
            //, i18n
        } = this.props;

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:researchFindings.title")}</title>
                    <meta name="description" content={t("Meta:researchFindings.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content no-background">
                            <div className="ImageText">
                                <div className="upper">
                                    <div dangerouslySetInnerHTML={createMarkup(this.state.contentUpper)} />
                                </div>
                                <div className="researchFinding ">
                                    {/* <h3>{i18n.language === 'en-US' ? this.state.title_en_us : this.state.title_zh_hk}</h3> */}

                                    {/* {i18n.language === 'en-US' ?
                                        <div dangerouslySetInnerHTML={createMarkup(this.state.content_en_us)} /> :
                                        <div dangerouslySetInnerHTML={createMarkup(this.state.content_zh_hk)} />
                                    } */}
                                    <div dangerouslySetInnerHTML={createMarkup(this.state.contentContent)} />
                                    {/* <h3>{t("ResearchFindings:OurResearch.bottom.0.title")}</h3>
                                    <h4>{t("ResearchFindings:OurResearch.bottom.0.from")}</h4>
                                    <img src={require('../../images/ResearchFindings/20190815 NA Research finding graphics-01.jpg')} alt="" />
                                    <img src={require('../../images/ResearchFindings/20190815 NA Research finding graphics-03.jpg')} alt="" />
                                    <img src={require('../../images/ResearchFindings/20190815 NA Research finding graphics-02.jpg')} alt="" />
                                    <p>{t("ResearchFindings:OurResearch.bottom.0._link") + " "}<a target="_blank" rel="noopener noreferrer" href={t("ResearchFindings:OurResearch.bottom.0.link")}>{t("ResearchFindings:OurResearch.bottom.0.link")}</a></p>

                                    <h3>{t("ResearchFindings:OurResearch.bottom.1.title")}</h3>
                                    <h4>{t("ResearchFindings:OurResearch.bottom.1.from")}</h4>
                                    <img src={require('../../images/ResearchFindings/20190815 NA Research finding graphics-04.jpg')} alt="" />
                                    <p>{t("ResearchFindings:OurResearch.bottom.1._link") + " "}<a target="_blank" rel="noopener noreferrer" href={t("ResearchFindings:OurResearch.bottom.1.link")}>{t("ResearchFindings:OurResearch.bottom.1.link")}</a></p>

                                    <div className="sep-20"></div>

                                    <h3>{t("ResearchFindings:OurResearch.bottom.2.title")}</h3>
                                    <h4>{t("ResearchFindings:OurResearch.bottom.2.from")}</h4>
                                    <img src={require('../../images/ResearchFindings/Research-finding-graphics-05.png')} alt="" />
                                    <img src={require('../../images/ResearchFindings/Research-finding-graphics-06.png')} alt="" />
                                    <img src={require('../../images/ResearchFindings/Research-finding-graphics-07.png')} alt="" />
                                    <p>{t("ResearchFindings:OurResearch.bottom.2._link") + " "}<a target="_blank" rel="noopener noreferrer" href={t("ResearchFindings:OurResearch.bottom.2.link")}>{t("ResearchFindings:OurResearch.bottom.2.link")}</a></p>

                                    <div className="sep-20"></div>

                                    <h3>{t("ResearchFindings:OurResearch.bottom.3.title")}</h3>
                                    <h4>{t("ResearchFindings:OurResearch.bottom.3.from")}</h4>
                                    <p>{t("ResearchFindings:OurResearch.bottom.3._link") + " "}<a target="_blank" rel="noopener noreferrer" href={t("ResearchFindings:OurResearch.bottom.3.link")}>{t("ResearchFindings:OurResearch.bottom.3.link")}</a></p>

                                    <div className="sep-20"></div>

                                    <h3>{t("ResearchFindings:OurResearch.bottom.4.title")}</h3>
                                    <h4>{t("ResearchFindings:OurResearch.bottom.4.from")}</h4>
                                    <p>{t("ResearchFindings:OurResearch.bottom.4._link") + " "}<a target="_blank" rel="noopener noreferrer" href={t("ResearchFindings:OurResearch.bottom.4.link")}>{t("ResearchFindings:OurResearch.bottom.4.link")}</a></p>

                                    <div className="sep-20"></div>

                                    <h3>{t("ResearchFindings:OurResearch.bottom.5.title")}</h3>
                                    <h4>{t("ResearchFindings:OurResearch.bottom.5.from")}</h4>
                                    <p>{t("ResearchFindings:OurResearch.bottom.5._link") + " "}<a target="_blank" rel="noopener noreferrer" href={t("ResearchFindings:OurResearch.bottom.5.link")}>{t("ResearchFindings:OurResearch.bottom.5.link")}</a></p>

                                    <div className="sep-20"></div>

                                    <h3>{t("ResearchFindings:OurResearch.bottom.6.title")}</h3>
                                    <h4>{t("ResearchFindings:OurResearch.bottom.6.from")}</h4>
                                    <p>{t("ResearchFindings:OurResearch.bottom.6._link") + " "}<a target="_blank" rel="noopener noreferrer" href={t("ResearchFindings:OurResearch.bottom.6.link")}>{t("ResearchFindings:OurResearch.bottom.6.link")}</a></p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ResearchFindings));

// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

// Styling
// import Grid from '@material-ui/core/Grid';

// Api
import { apiHistory } from '../../../Api/ApiHistory';
import { apiWebPage } from '../../../Api/ApiWebPage';

// Redux
import { connect } from 'react-redux';
import { login } from '../../../Redux/Action/authAction';

// Utils
//import ReactToPrint from 'react-to-print';
import CommonUtils, { createMarkup } from '../../../utils/CommonUtils';
import { FacebookShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { WEBSITE_URL } from '../../../config';
import { filter, get } from 'lodash-es';

// Children components
import BreadCrumb from '../../../components/100Include/BreadCrumb';
// import TextAboveBanner from '../../../components/02ResourcesForYou/TextAboveBanner';
// import Buttons from '../../../components/100Include/Buttons';

// class ComponentToPrint extends React.Component {
//     render() {

//         const { i18n } = this.props;

//         if (i18n.language === 'en-US') {
//             return (
//                 <div className="bottom">
//                     <h3>Why is it so important?</h3>

//                     <h4>1. Don’t let it be too late</h4>
//                     <p>Begin the conversation with your children from an early age! Protect them from risks they may not yet understand and to prepare them to face and manage the threat. </p>

//                     <h4>2. Get involved</h4>
//                     <p>Imagine, how dangerous it can be if you don’t even know what problem is your children facing online?  <a href="/" target="_blank">(Link)</a></p>
//                     <p>Work to create an accepting environment where your children feel free to express their ideas without fear of being wrong or of not being taken seriously. Encourage children to express themselves so that you can get involved before and when your children face a problem.</p>

//                     <p>&nbsp;</p>

//                     <h3>What to do?</h3>
//                     <h4>1. Customize the checklist together</h4>
//                     <ul>
//                         <li>Every child is unique in their own way! Set the checklist together according to your children’s age and your family practice. It is not necessarily asking your children to  all rules!</li>
//                         <li>Please do not forget to listen to your children and consider their needs while making the checklist.</li>
//                     </ul>
//                     <h4>2. Explain to your children the purpose of setting these rules</h4>
//                     <ul>
//                         <li><strong>Boundaries bring freedom</strong> <br />
//                             Don’t just see boundaries as limitations, boundaries also bring freedom - boundaries tell children where they are free to explore and roam. Meanwhile, don’t forget to leave them space to explore the device! Strict parenting may deprive kids of the opportunity to internalize self-discipline and responsibility.</li>

//                         <li><strong>Think twice before posting</strong> <br />
//                             Discuss with your children on the lasting effect of the content we post online. Contents they share online are hard to erase, any personal information, photos of themselves/ family/
// friends, offensive or inappropriate content they post online will be stored permanently. The content can affect one’s career or ruin a relationship.</li>
//                     </ul>

//                     <p>&nbsp;</p>

//                     <h3>What to do when my children encounter problems online?</h3>

//                     <p>Depending on different situations, you may try to direct your children to think of a solution themselves by providing them a variety of problem-solving experiences.</p>
//                     <p>If you don’t know how to address the problem your child encounters, please do not hesitate to seek help. You
// may refer to our ‘seek help’ page for more information.</p>
//                 </div>
//             )
//         } else {

//             return (
//                 <div className="wrapper-content">
//                     <div className="wordDoc">

//                         <h3>為何要設立承諾書？</h3>

//                         <h4>1. 別讓一切變得「太遲」</h4>
//                         <p>在孩子年紀尚小的時候就開始和他們溝通，讓他們及早認識網絡上存在的危機，並學懂如何面對和處理危險的狀況。</p>

//                         <h4>2. 讓孩子願意和您傾訴需時</h4>
//                         <p>想象一下，如果連您的孩子在網上遇到甚麼危機都不知道的話，孩子會有多危險呢？ <a href="/" target="_blank">(Link)</a></p>
//                         <p>花心思去建立一個開放的家庭環境，讓您的孩子能舒適地表達自己的想法，不必擔心做錯或不被重視。在孩子需要您的時候或更早之前能有足夠的溝通去一起面對問題。</p>

//                         <p>&nbsp;</p>

//                         <h3>應該怎樣做？</h3>
//                         <h4>1. 一起訂立您們的承諾書</h4>
//                         <ul>
//                             <li>每位兒童都是獨特的！一起訂立適合孩子年齡和您們家庭習慣的承諾書。</li>
//                             <li>這並非命令您孩子的指引，而是一份通過良好溝通去和孩子一起訂立的承諾，別忘記在設立規則的同時尊重孩子的意見和考慮他的需要。</li>
//                         </ul>
//                         <h4>2. 讓您的孩子明白設立規則的用處</h4>
//                         <ul>
//                             <li>規限如何帶來自由
//                     規限只是限制這麼簡單嗎？有沒有想過規限亦能帶來自由？規限能助兒童了解在甚麼地方他們能自由地探索和遨遊。設立規限的同時，別忘記要給予孩子使用電子產品的空間！過於嚴格的管教可能會剝奪孩子建立內在自律和責任的機會。</li>

//                             <li>別做令未來的自己後悔的事
//                     和孩子討論在網絡發佈內容的後果，讓他們明白上載到網絡的個人資料、他們/家人/朋友的照片、具攻擊性或不當的內容等都有機會被永久保存，很難徹底移除。有些更可能在將來影響他們的前途或人際關係，在上載前必須想清楚。</li>
//                         </ul>

//                         <p>&nbsp;</p>

//                         <h3>若我的孩子在網上遇到危險怎麼辦？</h3>

//                         <p>在不同的情況下，您可以引導您的孩子去思考解決問題的方法，分享一些不同處理問題的經驗和可能性。同時，在孩子在網上遇上問題的時候，請不要猶豫，尋求幫助。 您可以參考Net神研究所「尋求幫助」的頁面了解更多。</p>
//                     </div>
//                 </div>
//             );
//         }
//     }
// }

class ParentsOnlineSafetyChecklist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundColor: 'white',
            showPrintButton: true,
            showFacebookShareButton: true,
            showDownloadButton: true,
            showCopyToClipboardButton: true,
            copied: false,
            contentAboveUpper: null,
            contentUpper: null,
            contentContent: null,
            language: null,
            loading: false
        }
    }

    componentDidMount() {
        this.updateHtml();
        apiHistory.createHistory(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
            this.updateHtml();
        }
    }

    updateHtml() {
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiWebPage.getPageData('/parents-online-safety-checklist', (result) => {
            const language = get(this, 'props.i18n.language');
            const newState = {
                loading: false,
                language
            };
            const body = get(result, 'body');
            if (body && body.length > 0) {
                const attr = language && language.toLowerCase() === 'en-us' ? 'html_en' : 'html_zh_cht';

                const aboveUpper = filter(body, ['section', 'aboveUpper']);
                if (aboveUpper && aboveUpper.length > 0) {
                    newState.contentAboveUpper = aboveUpper[0][attr];
                } else {
                    console.log('no above upper content section');
                }

                const uppers = filter(body, ['section', 'upper']);
                if (uppers && uppers.length > 0) {
                    newState.contentUpper = uppers[0][attr];
                } else {
                    console.log('no upper section');
                }

                const contents = filter(body, ['section', 'content']);
                if (contents && contents.length > 0) {
                    newState.contentContent = contents[0][attr];
                } else {
                    console.log('no content section');
                }
            }


            this.setState(newState);
        });
    }

    _downloadFile = (file_url, file_name) => {
        // Bluebird.delay(0, file_url).then((url) => {
        CommonUtils.forceDownload(file_url, file_name);
        // });
    }

    _handleCopyLink = () => {
        this.setState({
            copied: true
        });
        setTimeout(() => {
            this.setState({
                copied: false
            });
        }, 3000);
    }

    render() {
        const { t } = this.props;

        const currentURL = this.props.router.location.pathname;
        // const url = window.location.protocol + '//' + window.location.hostname + currentURL;
        const url = WEBSITE_URL + currentURL;

        let file = require('../../../uploads/' + t("Upload:onlineSafetyChecklistjpg"));

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:parentsOnlineSafetyChecklist.title")}</title>
                    <meta name="description" content={t("Meta:parentsOnlineSafetyChecklist.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content no-background">
                            <div className="ImageText">
                                <div className="wrapper-content">
                                    <div className="wordDoc">
                                        <div dangerouslySetInnerHTML={createMarkup(this.state.contentAboveUpper)} />
                                    </div>
                                </div>
                                <div className="upper">
                                    {/* <TextAboveBanner /> */}
                                    {/* <img src={require('../../../images/ResourcesForYou/PrimarySchool/Banner/' + t("Upload:onlineSafetyChecklistBanner"))} alt="" /> */}
                                    <div dangerouslySetInnerHTML={createMarkup(this.state.contentUpper)} />

                                    <div className={"button-wrapper " + (this.state.backgroundColor ? this.state.backgroundColor : '')}>
                                        <div className="wrap">
                                            {/* {this.state.showPrintButton &&
                                                <ReactToPrint
                                                    trigger={() => <div className={"print " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}></div>}
                                                    content={() => this.componentRef}
                                                />
                                            } */}

                                            {this.state.showFacebookShareButton &&
                                                <FacebookShareButton url={url} className={"facebookShare " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')} />
                                            }

                                            {this.state.showDownloadButton &&
                                                // <div className={"download " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}
                                                //     onClick={() => this._downloadFile(file, t("Upload:onlineSafetyChecklistpdf"))}>
                                                // </div>
                                                <div className={"download " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}
                                                    onClick={() => this._downloadFile(file, t("Upload:onlineSafetyChecklistjpg"))}>
                                                </div>
                                            }

                                            {this.state.showCopyToClipboardButton &&
                                                <div className="wrapper-copyLink">
                                                    <CopyToClipboard text={url} onCopy={() => this._handleCopyLink()}>
                                                        <div className={"copyLink " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}></div>
                                                    </CopyToClipboard>
                                                    {(this.state.copied ? <div className="message">{t("Common:General.CopyLink")}</div> : '')}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {/* <Buttons
                                        backgroundColor='white'
                                        file={t("Upload:onlineSafetyChecklist")}
                                        componentRef={this.componentRef}
                                        showPrintButton={true}
                                        showFacebookShareButton={true}
                                        showDownloadButton={true}
                                        showCopyToClipboardButton={true}
                                    /> */}
                                </div>
                                <div className="wrapper-content">
                                    <div className="wordDoc">
                                        <div dangerouslySetInnerHTML={createMarkup(this.state.contentContent)} />
                                        {/* <ComponentToPrint ref={el => (this.componentRef = el)} t={t} i18n={i18n} /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    router: state.router
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ParentsOnlineSafetyChecklist)));

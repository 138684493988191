// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Styling
import Grid from '@material-ui/core/Grid';

// Api
import { apiHistory } from '../../Api/ApiHistory';
import { apiListPartners } from '../../Api/ApiListPartners';

// Redux
import { connect } from 'react-redux';
import { login } from '../../Redux/Action/authAction';

// Utils
import { get } from 'lodash-es';

// Children components
import BreadCrumb from '../../components/100Include/BreadCrumb';
//import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';

class OurPartners extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formSubmitted: false,
            language: null,
            loading: false,
            content: null
        }
    }

    componentDidMount() {
        this.updateHtml();
        apiHistory.createHistory(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
            this.updateHtml();
        }
    }

    updateHtml() {
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiListPartners.getBlogs((result) => {
            this.setState({
                contents: get(result, 'body') || [],
                loading: false,
                language: get(this, 'props.i18n.language')
            });
        });
    }

    render() {
        const { t } = this.props;
        const {
            contents
        } = this.state;
        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:ourPartners.title")}</title>
                    <meta name="description" content={t("Meta:ourPartners.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content contact" margin="20">
                            <div>
                                <Grid container spacing={16} style={{ justifyContent: 'space-around' }}>
                                    {contents && contents.length > 0 && contents.map(content => {
                                        const language = get(this, 'props.i18n.language');
                                        const url = language && language.toLowerCase() === 'en-us' ? content.url_en : content.url_zh_cht;
                                        const imageUrl = language && language.toLowerCase() === 'en-us' ? content.image_url_en : content.image_url_zh_cht;
                                        return <Grid item sm={5} key={content.list_partner_id}
                                            style={{ paddingTop: 50 }}
                                        >
                                            <a href={url} target="_blank" rel="noopener noreferrer">
                                                <img src={imageUrl} alt="" width="100%" />
                                            </a>
                                        </Grid>;
                                    })}
                                </Grid>

                                {/* hide Google */}
                                {/* <img src={require('../../images/AboutUs/Google logo.png')} alt="" width="45%" /> */}
                                {/* <a href={t("AboutUs:ourPartners")} target="_blank" rel="noopener noreferrer">
                                    <img src={require('../../images/AboutUs/Trend Mirco logo.eps.png')} alt="" width="33%" />
                                </a> */}
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(OurPartners));

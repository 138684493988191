import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import { apiHistory } from '../Api/ApiHistory';

class PageNotFound extends Component {

	componentDidMount() {
        apiHistory.createHistory(window.location.pathname);
	}

	render() {
		return (
			<div>
				<div className="wrapper-container-main">
					<Helmet>
						<title> Page Not Found</title>
						<meta name="description" content="Page not found" />
					</Helmet>
					<div className="container-main">

						<div className="wrapper-content">
							<div className="content">
								Sorry!
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default PageNotFound;
// connect(mapStateToProps)(Contact);

// Essential for all components
import React, { Component } from 'react';
// import {Redirect} from 'react-router';
import './css/App.scss';
import { withTranslation } from 'react-i18next';

// Api
import { apiAuth } from './Api/ApiAuth';
import { apiMenus } from './Api/ApiMenus';

// Redux
import { connect } from 'react-redux';
import { refreshTokenByRefreshToken, login, logout } from './Redux/Action/authAction';
import { getAllMenus } from './Redux/Action/menusAction';

import querySearch from "stringquery";

import Header from './components/100Include/Header';
// import MainMenuBar from './components/100Include/MainMenuBar';
import Footer from './components/100Include/Footer';
import HeroMenu from './components/100Include/HeroMenu';
import MainMenu from './components/100Include/MainMenu';
import FooterSitemap from './components/100Include/FooterSitemap';
import SeekHelp from './components/100Include/SeekHelp';

// Home
import Home from './containers/00Home/Home';
import LoginRegister from './containers/00Home/LoginRegister';
import ForgetPassword from './containers/00Home/ForgetPassword';
import ResetPassword from './containers/00Home/ResetPassword';

/*** About Us ***/
import WhatIsNetizenAcademy from './containers/01AboutUs/WhatIsNetizenAcademy';
import OurMissionAndStrategy from './containers/01AboutUs/OurMissionAndStrategy';
import OurPartners from './containers/01AboutUs/OurPartners';
import Sitemap from './containers/01AboutUs/Sitemap';
import ContactUs from './containers/01AboutUs/ContactUs';

/** Resources For You ***/

// Primary School
import PrimarySchoolHome from './containers/02ResourcesForYou/PrimarySchool/PrimarySchoolHome';
import PrimaryGeneralTips from './containers/02ResourcesForYou/PrimarySchool/PrimaryGeneralTips';
import PrimaryVideo from './containers/02ResourcesForYou/PrimarySchool/PrimaryVideo';
import PrimaryVideoDetail from './containers/02ResourcesForYou/PrimarySchool/PrimaryVideoDetail';
import PrimarySocialMediaUserGuide from './containers/02ResourcesForYou/PrimarySchool/PrimarySocialMediaUserGuide';
import PrimarySocialMediaUserGuideDetail from './containers/02ResourcesForYou/PrimarySchool/PrimarySocialMediaUserGuideDetail';
import PrimaryQuiz from './containers/02ResourcesForYou/PrimarySchool/PrimaryQuiz';
import PrimaryRiskAndDanger from './containers/02ResourcesForYou/PrimarySchool/PrimaryRiskAndDanger';
import PrimaryGame from './containers/02ResourcesForYou/PrimarySchool/PrimaryGame';
import PrimaryNetizenPledge from './containers/02ResourcesForYou/PrimarySchool/PrimaryNetizenPledge';

// Secondary School
import SecondarySchoolHome from './containers/02ResourcesForYou/SecondarySchool/SecondarySchoolHome';
// import SecondaryGeneralTips from './containers/02ResourcesForYou/SecondarySchool/SecondaryGeneralTips';
import SecondaryVideo from './containers/02ResourcesForYou/SecondarySchool/SecondaryVideo';
import SecondaryVideoDetail from './containers/02ResourcesForYou/SecondarySchool/SecondaryVideoDetail';
import SecondaryGame from './containers/02ResourcesForYou/SecondarySchool/SecondaryGame';
import SecondaryQuiz from './containers/02ResourcesForYou/SecondarySchool/SecondaryQuiz';
// import SecondaryNetizenPledge from './containers/02ResourcesForYou/SecondarySchool/SecondaryNetizenPledge';
// import SecondaryRiskAndDanger from './containers/02ResourcesForYou/SecondarySchool/SecondaryRiskAndDanger';
import SecondarySocialMediaUserGuide from './containers/02ResourcesForYou/SecondarySchool/SecondarySocialMediaUserGuide';
import SecondarySocialMediaUserGuideDetail from './containers/02ResourcesForYou/SecondarySchool/SecondarySocialMediaUserGuideDetail';

// Parents
import ParentsHome from './containers/02ResourcesForYou/Parents/ParentsHome';
import ParentsTips from './containers/02ResourcesForYou/Parents/ParentsTips';
import ParentsBlog from './containers/02ResourcesForYou/Parents/ParentsBlog';
import ParentsBlogDetail from './containers/02ResourcesForYou/Parents/ParentsBlogDetail';
import ParentsOnlineSafetyChecklist from './containers/02ResourcesForYou/Parents/ParentsOnlineSafetyChecklist';
import ParentsNetTube from './containers/02ResourcesForYou/Parents/ParentsNetTube';
import ParentsNetTubeDetail from './containers/02ResourcesForYou/Parents/ParentsNetTubeDetail';
import ParentsTrainingWorkshop from './containers/02ResourcesForYou/Parents/ParentsTrainingWorkshop';
import ParentsMoreResources from './containers/02ResourcesForYou/Parents/ParentsMoreResources';

/*** Featured Issues ***/
import OnlineGrooming from './containers/03FeaturedIssues/OnlineGrooming';
import Sexting from './containers/03FeaturedIssues/Sexting';
import PersonalPrivacy from './containers/03FeaturedIssues/PersonalPrivacy';
import Legislations from './containers/03FeaturedIssues/Legislations';

/*** Research Findings ***/
import ResearchFindings from './containers/04ResearchFindings/ResearchFindings';
// import OurResearch from './containers/04ResearchFindings/OurResearch';
// import OtherFindings from './containers/04ResearchFindings/OtherFindings';

/*** Our Events ***/
import EventsSaferInternetDay2019 from './containers/05OurEvents/EventsSaferInternetDay2019';
import EventsSmartNetizenCompetition from './containers/05OurEvents/EventsSmartNetizenCompetition';
import EventsTrainingWorkshops from './containers/05OurEvents/EventsTrainingWorkshops';

/*** External Resources ***/
import ExternalResources from './containers/06ExternalResources/ExternalResources';

/*** Others ***/
import Disclaimer from './containers/07Others/Disclaimer';

/*** Templates ***/
// import ImageText from './containers/10Templates/ImageText';
// import ImageGrid from './containers/10Templates/ImageGrid';
// import ImageTabGrid from './containers/10Templates/ImageTabGrid';

// 404
import PageNotFound from './containers/PageNotFound';

class App extends Component {

    // change URL
    constructor(props) {
        super(props);
        this.state = {
            seekHelp: false,
            debug: false
        }
    }

    componentDidMount = () => {
        // HelperDesktopHandle.DesktopHandle.init();
        // HelperMobileHandle.MobileHandle.init();
        // HelperMobileHandle.MobileHandle.containersSize();
        // window.addEventListener("resize", this.windowResize);

        // get all menus from Credentials
        if (this.props.menus.allMenus.length === 0) {
            apiAuth.getClientCredentials().then((res) => {
                this._getAllMenus(res.access_token);
            })
        }
    }

    componentDidUpdate = () => {
        window.scrollTo(0, 0);

        // check if token has expired
        if (this.props.auth.auth) {
            this._getUserInformation(this.props.auth.token);
        }
    }

    // windowResize = () => {
    //     HelperDesktopHandle.DesktopHandle.resetDesktopMenu();
    //     HelperDesktopHandle.DesktopHandle.maxHeightDesktopMenu();
    //     HelperMobileHandle.MobileHandle.containersSize();
    // }

    // check if token has expired
    _getUserInformation = (access_token) => {

        console.log('check if token has expired');

        const cb = (obj) => {
            // console.log("check cb : ", obj);

            switch (obj.status) {
                case 401:
                    console.log('token has expired, now use refresh_token to refresh it');
                    this._refreshTokenByRefreshToken(this.props.auth.refreshToken);
                    break;

                case 200:
                    console.log('token is still valid');
                    break;

                default:
                    console.log('There is error 1 : ', obj)
            }
        }

        const eCb = (obj) => {
            // console.log("eCb : ", obj);
        }

        const params = null;

        apiAuth.getUserInformation(params, access_token, cb, eCb);
    }

    // refresh token by refresh_token
    _refreshTokenByRefreshToken = (refresh_token) => {

        console.log('refresh token by refresh_token');

        const cb = (obj) => {
            // console.log("cb _refreshTokenByRefreshToken : ", obj);

            switch (obj.status) {

                case 400:
                    console.log('refresh token has expired, please login again (400)');
                    this.props.logoutP();
                    break;

                case 401:
                    console.log('refresh token has expired, please login again (401)');
                    this.props.logoutP();
                    break;

                case 200:
                    console.log('token is refreshed by refresh_token');
                    let data = {
                        token: obj.body.access_token,
                        refreshToken: obj.body.refresh_token,
                    }
                    this.props.loginP(data);

                    break;

                default:
                    console.log('There is error 2 : ', obj)
            }
        }
        const eCb = (obj) => {
            // console.log("eCb : ", obj);
        }

        apiAuth.refreshTokenByRefreshToken(refresh_token, cb, eCb);
    }

    _handleSeekHelp = () => {
        this.setState({
            seekHelp: !this.state.seekHelp
        })
    }

    _getAllMenus = (token) => {

        if (typeof token !== 'undefined') {

            const cb = (obj) => {
                console.log("cb : ", obj);
                this.props.getAllMenusP(obj.body);
            }
            const eCb = (obj) => {
                console.log("eCb : ", obj);
            }
            const params = null;

            apiMenus.getAllMenus(params, token, cb, eCb);
        }
    }

    getComponent = (currentURL, currentID, params) => {

        if (currentURL) {
            // console.log(params);

            switch (currentURL) {

                case 'home': {
                    return <Home />;
                }
                case 'login-register': {
                    return <LoginRegister />;
                }
                case 'forget-password': {
                    return <ForgetPassword />;
                }
                case 'reset-password': {
                    return <ResetPassword />;
                }

                /*** ABOUT US ***/
                case 'what-is-netizen-academy': {
                    return <WhatIsNetizenAcademy />;
                    // return <ImageText
                    //     relativePath={currentURL}
                    // />
                }
                case 'our-mission-and-strategy': {
                    return <OurMissionAndStrategy />;
                    // return <ImageText
                    //     relativePath={currentURL}
                    // />
                }
                case 'our-partners': {
                    return <OurPartners />;
                    // return <ImageText
                    //     relativePath={currentURL}
                    // />
                }
                case 'sitemap': {
                    return <Sitemap />;
                    // return <ImageText
                    //     relativePath={currentURL}
                    // />
                }
                case 'contact-us': {
                    return <ContactUs />;
                }

                /*** RESOURCES FOR YOU ***/

                // Primary School
                case 'primary-school-home': {
                    return <PrimarySchoolHome
                        tabIndex={1}
                    />
                    // return <ImageTabGrid
                    //     tabIndex={1}
                    // />;
                }
                case 'primary-general-tips': {
                    return <PrimaryGeneralTips />;
                }
                case 'primary-social-media-user-guide': {
                    if (currentID) {
                        return <PrimarySocialMediaUserGuideDetail
                            id={currentID}
                        />;
                    } else {
                        return <PrimarySocialMediaUserGuide />;
                    }
                }


                case 'primary-quiz': {
                    return <PrimaryQuiz />;
                }
                // case 'primary-video': {
                //     return <PrimaryVideo />;
                // }
                case 'primary-video': {
                    if (currentID) {
                        return <PrimaryVideoDetail
                            id={currentID}
                        />;
                    } else {
                        return <PrimaryVideo />;
                    }
                }
                case 'primary-risk-and-danger': {
                    return <PrimaryRiskAndDanger />;
                    // return <ImageText
                    //     relativePath={currentURL}
                    // />
                }
                case 'primary-game': {
                    return <PrimaryGame />;
                }
                case 'primary-netizen-pledge': {
                    return <PrimaryNetizenPledge />;
                }

                // Secondary School
                case 'secondary-school-home': {
                    return <SecondarySchoolHome
                        tabIndex={2}
                    />;
                    // return <ImageTabGrid
                    //     tabIndex={2}
                    // />;
                }
                case 'secondary-general-tips': {
                    return <PrimaryGeneralTips />;
                }
                case 'secondary-social-media-user-guide': {
                    if (currentID) {
                        return <SecondarySocialMediaUserGuideDetail
                            id={currentID}
                        />;
                    } else {
                        return <SecondarySocialMediaUserGuide />;
                    }
                }

                case 'secondary-quiz': {
                    return <SecondaryQuiz />;
                }
                case 'secondary-risk-and-danger': {
                    return <PrimaryRiskAndDanger />
                    // return <ImageText
                    //     relativePath={currentURL}
                    // />
                }
                case 'secondary-game': {
                    return <SecondaryGame />;
                }
                case 'secondary-video': {
                    if (currentID) {
                        return <SecondaryVideoDetail
                            id={currentID}
                        />;
                    } else {
                        return <SecondaryVideo />;
                    }
                }
                case 'secondary-netizen-pledge': {
                    return <PrimaryNetizenPledge />;
                }

                // Parents
                case 'parents-home': {
                    return <ParentsHome />;
                    // return <ImageGrid />;
                }
                case 'parents-tips': {
                    return <ParentsTips />;
                    // return <ImageText
                    //     relativePath={currentURL}
                    // />
                }
                case 'parents-blog': {

                    if (currentID) {
                        return <ParentsBlogDetail
                            id={currentID}
                        />;
                    } else {
                        return <ParentsBlog />;
                    }
                }
                case 'parents-blog-detail': {
                    return <ParentsBlogDetail />;
                }
                case 'parents-online-safety-checklist': {
                    return <ParentsOnlineSafetyChecklist />
                }
                case 'parents-nettube': {
                    if (currentID) {
                        return <ParentsNetTubeDetail
                            id={currentID}
                        />;
                    } else {
                        return <ParentsNetTube />;
                    }
                }
                case 'parents-training-workshop': {
                    return <ParentsTrainingWorkshop />;
                    // return <ImageText
                    //     relativePath={currentURL}
                    // />
                }
                case 'parents-more-resources': {
                    return <ParentsMoreResources />;
                    // return <ImageText
                    //     relativePath={currentURL}
                    // />
                }

                /*** FEATURED ISSUES ***/
                case 'online-grooming': {
                    return <OnlineGrooming />;
                    // return <ImageText
                    //     relativePath={currentURL}
                    // />
                }
                case 'sexting': {
                    return <Sexting />;
                    // return <ImageText
                    //     relativePath={currentURL}
                    // />
                }
                case 'personal-privacy': {
                    return <PersonalPrivacy />;
                    // return <ImageText
                    //     relativePath={currentURL}
                    // />
                }
                case 'legislations': {
                    return <Legislations />;
                    // return <ImageText
                    //     relativePath={currentURL}
                    // />
                }

                /*** RESEARCH FINDINGS ***/
                case 'research-findings': {
                    return <ResearchFindings />;
                    // return <ImageText
                    //     relativePath={currentURL}
                    // />
                }
                // case 'our-research': {
                //     return <OurResearch />;
                //     // return <ImageText
                //     //     relativePath={currentURL}
                //     // />
                // }
                // case 'other-findings': {
                //     return <OtherFindings />;
                //     // return <ImageText
                //     //     relativePath={currentURL}
                //     // />
                // }

                /*** OUR EVENTS ***/
                case 'events-safer-internet-day': {
                    return <EventsSaferInternetDay2019 />;
                }
                case 'events-smart-netizen-competition': {
                    return <EventsSmartNetizenCompetition />;
                }
                case 'events-training-workshops': {
                    return <EventsTrainingWorkshops />;
                }

                /*** EXTERNAL RESOURCES ***/
                case 'external-resources': {
                    return <ExternalResources />;
                }

                /*** OTHERS ***/
                case 'disclaimer': {
                    return <Disclaimer />;
                }

                default: {
                    return <PageNotFound />;
                }
            }
        } else {
            return <Home />
        }
    }

    render() {
        const { t } = this.props;

        let pathname = this.props.route.location.pathname,
            search = this.props.route.location.search,
            urlArray = pathname.split("/"),
            currentURL = urlArray[2],
            currentID = urlArray[3],
            params = null;

        if (search !== "")
            params = querySearch(search);

        return (
            <div>
                <div id="wrap" className={(this.props.i18n.language === 'zh-HK' ? 'ch' : '')}>
                    <Header />
                    {(currentURL === '' || typeof currentURL === 'undefined') &&
                        <HeroMenu />
                    }

                    <div className={"seekHelp-Wrapper " + (currentURL !== '' && typeof currentURL !== 'undefined' ? 'smaller ' : '')}>
                        <div
                            className={"seekHelp " + (this.state.seekHelp ? 'hide' : 'show')}
                            onClick={() => this._handleSeekHelp()}
                        ><span>{t("Common:General.SeekHelp")}</span></div>

                        <SeekHelp
                            seekHelp={this.state.seekHelp}
                            handleSeekHelp={this._handleSeekHelp}
                        />
                    </div>

                    <MainMenu
                        currentURL={currentURL}
                    />

                    {this.getComponent(currentURL, currentID, params)}

                    <Footer />

                    {this.state.debug &&
                        <FooterSitemap />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    route: state.router,
    auth: state.auth,
    menus: state.menus
});

const mapDispatchToProps = dispatch => ({
    refreshTokenByRefreshTokenP: data => dispatch(refreshTokenByRefreshToken(data)),
    getAllMenusP: data => dispatch(getAllMenus(data)),
    loginP: data => dispatch(login(data)),
    logoutP: data => dispatch(logout(data)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App));

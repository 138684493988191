// Main Menu
export const MAIN_MENU_MOUSE_OVER = 'MAIN_MENU_MOUSE_OVER';
export const MAIN_MENU_MOUSE_LEAVE = 'MAIN_MENU_MOUSE_LEAVE';

// auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const GET_USER_INFO = 'GET_USER_INFO';
export const REFRESH_TOKEN_BY_REFRESH_TOKEN = 'REFRESH_TOKEN_BY_REFRESH_TOKEN';
export const LOGOUT = 'LOGOUT';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_CLEAR = 'FORGET_PASSWORD_CLEAR';

// menu
export const GET_ALL_MENUS = 'GET_ALL_MENUS';

// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

// Styling
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';

// Api
import { apiAuth } from '../../Api/ApiAuth';
import { apiHistory } from '../../Api/ApiHistory';

// Redux
import { connect } from 'react-redux';
import { login, forgetPassword } from '../../Redux/Action/authAction';

// Utils
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// Children components
import BreadCrumb from '../../components/100Include/BreadCrumb';
import ErrorMessage from '../../components/100Include/ErrorMessage';
import GeneralMessage from '../../components/100Include/GeneralMessage';

class ForgetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            MessageContent: ''
        }
    }

    componentDidMount() {
        apiHistory.createHistory(window.location.pathname);
    }

    _forgetPasswordAsync = (values) => {
        if (typeof (values) !== 'undefined') {

            var promise = Promise.resolve();
            apiAuth.getClientCredentials().then((res) => {
                promise.then(() => {
                    this._forgetPassword(values, res.access_token);
                });
            })
        }
    }

    _forgetPassword = (values, token) => {
        const { t, 
            //i18n
         } = this.props;

        const cb = (obj) => {
            console.log("cb : ", obj);

            this.props.forgetPasswordP(values.email);

            this.setState({
                MessageContent: t('LoginRegister:forgetPassword.msgSent')
            });

            setTimeout(() => {
                this.props.history.push('reset-password');
            }, 5000);
        }

        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }

        const params = null;

        apiAuth.forgetPassword(values.email, params, token, cb, eCb);
    }

    formForgetPassword = ({ values, errors, touched, handleChange }) => {
        const { t } = this.props;

        return (
            <Form className="form-wrapper">

                <Grid container spacing={16}>
                    <Grid item xs={12} className="grid">
                        <h1>{t('Common:General.ForgetPassword')}</h1>
                    </Grid>
                    <Grid item xs={12} className="grid">
                        <Field name="email" type="email" placeholder={t("LoginRegister:placeholder.Email")} maxLength="100" style={{ 'width': '100%' }} />
                        {errors.email && touched.email ? <ErrorMessage message={errors.email} /> : null}
                    </Grid>
                    <Grid item xs={12} className="grid">
                        <Button type="submit">{t("Common:General.Submit")}</Button>
                    </Grid>
                    {(this.state.MessageContent !== '') &&
                        <Grid item xs={12} className="SuccessMessage">
                            <GeneralMessage
                                message={this.state.MessageContent}
                            />
                        </Grid>
                    }
                </Grid>
            </Form>
        )
    }

    render() {
        const { t } = this.props;

        // Redirect to home
        if (this.props.auth.auth) {
            return <Redirect push to={"/"} />
        }

        const Schema = Yup.object().shape({
            email: Yup.string()
                .email(t("LoginRegister:register.Checking1"))
                .required(t("LoginRegister:register.Checking2"))
        })

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:forgetpassword.title")}</title>
                    <meta name="description" content={t("Meta:forgetpassword.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content">
                            <div className="forgetPassword">
                                <Formik
                                    initialValues={{
                                        email: '',
                                    }}
                                    validationSchema={Schema}
                                    onSubmit={this._forgetPasswordAsync}
                                    component={this.formForgetPassword}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data)),
    forgetPasswordP: data => dispatch(forgetPassword(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgetPassword)));

// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Styling

// Api
import { apiHistory } from '../../Api/ApiHistory';
import { apiWebPage } from '../../Api/ApiWebPage';

// Redux
import { connect } from 'react-redux';
import { login } from '../../Redux/Action/authAction';

// Utils
import { filter, get } from 'lodash-es';
import { createMarkup } from '../../utils/CommonUtils';

// Children components
import BreadCrumb from '../../components/100Include/BreadCrumb';

class WhatIsNetizenAcademy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formSubmitted: false,
            contentUpper: null,
            contentContent: null,
            language: null,
            loading: false
        }
    }

    componentDidMount() {
        this.updateHtml();
        apiHistory.createHistory(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
            this.updateHtml();
        }
    }

    updateHtml() {
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiWebPage.getPageData('/what-is-netizen-academy', (result) => {
            const language = get(this, 'props.i18n.language');
            const newState = {
                loading: false,
                language
            };
            const body = get(result, 'body');
            if (body && body.length > 0) {
                const attr = language && language.toLowerCase() === 'en-us' ? 'html_en' : 'html_zh_cht';

                const uppers = filter(body, ['section', 'upper']);
                if (uppers && uppers.length > 0) {
                    newState.contentUpper = uppers[0][attr];
                } else {
                    console.log('no upper section');
                }

                const contents = filter(body, ['section', 'content']);
                if (contents && contents.length > 0) {
                    newState.contentContent = contents[0][attr];
                } else {
                    console.log('no content section');
                }
            }
            this.setState(newState);
        });
    }


    // handleBreak() {
    //     if (this.props.i18n.language === 'zh-HK') {
    //         return (<p>&nbsp;</p>)
    //     }
    // }

    // handleBold() {
    //     if (this.props.i18n.language === 'en-US') {
    //         return (<b>The online world is the real world. </b>)
    //     }
    // }

    render() {
        const { t } = this.props;

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:whatIsNetizenAcademy.title")}</title>
                    <meta name="description" content={t("Meta:whatIsNetizenAcademy.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content">
                            <div className="ImageText">
                                <div className="upper">
                                    {/* <img src={require('../../images/AboutUs/banner_aboutus-01Whymemustact.png')} alt="" /> */}
                                    <div dangerouslySetInnerHTML={createMarkup(this.state.contentUpper)} />
                                </div>
                                <center>
                                    <div className="bottom">
                                        <div dangerouslySetInnerHTML={createMarkup(this.state.contentContent)} />
                                        {/* <h3>{t("AboutUs:whatIsNetizenAcademy.title1")}</h3>
                                        <p>{t("AboutUs:whatIsNetizenAcademy.content1a")}</p>
                                        <p>{t("AboutUs:whatIsNetizenAcademy.content1b")}</p>
                                        <p>{t("AboutUs:whatIsNetizenAcademy.content1c")}</p>
                                        <span>{this.handleBreak()}</span>
                                        <p>{t("AboutUs:whatIsNetizenAcademy.content1d")}</p>
                                        <p>{t("AboutUs:whatIsNetizenAcademy.content1e")}</p>
                                        <span>{this.handleBreak()}</span>
                                        <p>{t("AboutUs:whatIsNetizenAcademy.content1f")}</p>
                                        <p>&nbsp;</p>
                                        <h3>{t("AboutUs:whatIsNetizenAcademy.title2")}</h3>
                                        <p><span>{this.handleBold()}</span>{t("AboutUs:whatIsNetizenAcademy.content2a")}</p>
                                        <p>{t("AboutUs:whatIsNetizenAcademy.content2b")}</p>
                                        <p>&nbsp;</p>
                                        <p>{t("AboutUs:whatIsNetizenAcademy.content2c")}</p>
                                        <p>{t("AboutUs:whatIsNetizenAcademy.content2d")}</p>
                                        <p>{t("AboutUs:whatIsNetizenAcademy.content2e")}</p>
                                        <p>{t("AboutUs:whatIsNetizenAcademy.content2f")}</p>
                                        <p>&nbsp;</p>
                                        <h3>{t("AboutUs:whatIsNetizenAcademy.title3")}</h3>
                                        <p>{t("AboutUs:whatIsNetizenAcademy.content3a")}</p>
                                        <p>{t("AboutUs:whatIsNetizenAcademy.content3b")}</p>
                                        <p>{t("AboutUs:whatIsNetizenAcademy.content3c")}</p>
                                        <p>{t("AboutUs:whatIsNetizenAcademy.content3d")}</p>
                                        <p>&nbsp;</p>
                                        <img style={{ maxWidth: 'none' }} src={require('../../images/AboutUs/20190814SuperNetsonontroductionbanner-01.jpg')} alt="" /> */}
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WhatIsNetizenAcademy));

// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

// Styling
import Grid from '@material-ui/core/Grid';

// Api
import { apiHistory } from '../../Api/ApiHistory';
import { apiWebPage } from '../../Api/ApiWebPage';

// Redux
import { connect } from 'react-redux';
import { login } from '../../Redux/Action/authAction';

// Utils
import { filter, get } from 'lodash-es';
import { createMarkup } from '../../utils/CommonUtils';

// Children components
// import BreadCrumb from '../../components/100Include/BreadCrumb';

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            description: null,
            language: null,
            loading: false
        };
    }

    componentDidMount() {
        this.updateHtml();
        apiHistory.createHistory(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
            this.updateHtml();
        }
    }

    updateHtml() {
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiWebPage.getPageData('/homepage', (result) => {
            const language = get(this, 'props.i18n.language');
            const newState = {
                loading: false,
                language
            };
            const body = get(result, 'body');
            if (body && body.length > 0) {
                const attr = language && language.toLowerCase() === 'en-us' ? 'html_en' : 'html_zh_cht';

                const descriptions = filter(body, ['section', 'description']);
                if (descriptions && descriptions.length > 0) {
                    newState.description = descriptions[0][attr];
                } else {
                    console.log('no description section');
                }
            }
            this.setState(newState);
        });
    }

    _goToDetail = (url) => {

        const { i18n } = this.props;

        this.props.history.push('/' + i18n.language + '/' + url);

    }

    render() {
        const { t } = this.props;

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:home.title")}</title>
                    <meta name="description" content={t("Meta:home.description")} />
                </Helmet>
                <div className="container-main">
                    <div className="wrapper-content">
                        <div className="content">
                            <div className="home">
                                <div dangerouslySetInnerHTML={createMarkup(this.state.description)}></div>
                                <div className="bottom">
                                    <Grid container spacing={16}>
                                        <Grid item sm={4}>
                                            <div className="section" onClick={() => this._goToDetail('primary-school-home')}>
                                                <img src={require('../../images/Homepage/img_homepage_content_Resourcefoyou-02_Primary-School.png')} alt="" />
                                                <h6>{t("Home:primary")}</h6>
                                            </div>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <div className="section" onClick={() => this._goToDetail('secondary-school-home')}>
                                                <img src={require('../../images/Homepage/img_homepage_content_Resourcefoyou-03_Secondry-School.png')} alt="" />
                                                <h6>{t("Home:secondary")}</h6>
                                            </div>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <div className="section" onClick={() => this._goToDetail('parents-home')}>
                                                <img src={require('../../images/Homepage/img_homepage_content_Resourcefoyou-04_Parents.png')} alt="" />
                                                <h6>{t("Home:parents")}</h6>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Home)));

import { api } from './_ApiFactoryWithHeader';
import { AUDIENCE, WEB_CLIENT_CREDENTIAL_TOKEN } from '../Redux/Constant/ServerConstant';

import { apiGeneral } from './_General';

export const apiAuth = {
	// get client credentials
	getClientCredentials: () => api.postUrlFormEncoded('auth', {
		audience: AUDIENCE, grant_type: 'client_credentials',
	}, {
			headers: {
				'Authorization': `Basic ${WEB_CLIENT_CREDENTIAL_TOKEN}`,
				'Access-Control-Allow-Origin': '*',
			}
		}
	),

	// register
	register: (body, token, cb, eCb) => {
		apiGeneral.apiPost('users', body, token, cb, eCb)
	},

	// login
	authenticate: (username, password) => api.postUrlFormEncoded('auth', {
		username, password, audience: AUDIENCE, grant_type: 'password',
	}, {
			headers: {
				'Authorization': `Basic ${WEB_CLIENT_CREDENTIAL_TOKEN}`,
				'Access-Control-Allow-Origin': '*',
			}
		}
	),

	// logout
	revoke: (token) => api.postUrlFormEncoded('token/revoke', {
		token
	}, {
			headers: {
				'Authorization': `Basic ${WEB_CLIENT_CREDENTIAL_TOKEN}`,
				'Access-Control-Allow-Origin': '*',
			}
		}
	),

	// get user information
	getUserInformation: (params, token, cb, eCb) => {
		apiGeneral.apiFetch('user/me', params, token, cb, eCb);
	},

	// refresh token by refresh token
	refreshTokenByRefreshToken: (token, cb, eCb) => {
		apiGeneral.apiPostRefreshToken('auth', {
			headers: {
				'Authorization': `Basic ${WEB_CLIENT_CREDENTIAL_TOKEN}`,
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded',
			}
		}, token, cb, eCb);
	},

	// forget password
	forgetPassword: (value, params, token, cb, eCb) => {
		apiGeneral.apiFetch(`users/${value}/forget_password`, params, token, cb, eCb)
	},

	// reset password
	resetPassword: (value, body, token, cb, eCb) => {
		apiGeneral.apiPut(`users/${value}/reset_password`, body, token, cb, eCb)
	}
};

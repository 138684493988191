// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Styling
// import Grid from '@material-ui/core/Grid';

// Api
import { apiHistory } from '../../../Api/ApiHistory';

// Redux
import { connect } from 'react-redux';
import { login } from '../../../Redux/Action/authAction';

// Utils
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import CommonUtils from '../../../utils/CommonUtils';
import { FacebookShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { WEBSITE_URL } from '../../../config';

// Children components
import BreadCrumb from '../../../components/100Include/BreadCrumb';
// import Buttons from '../../../components/100Include/Buttons';
import ErrorMessage from '../../../components/100Include/ErrorMessage';

class ComponentToPrint extends React.Component {

    render() {
        if (this.props.i18n.language === 'en-US') {
            return (
                <div className="bottom">
                    <div className="fill-wrapper-cert" style={{ position: 'relative' }}>
                        <img src={require('../../../images/ResourcesForYou/NetizenPledge/bg_netizenpledge-FINISH_Empty.png')} alt="" />
                        <div className="text" style={{ overflow: 'auto', position: 'absolute', top: '20%', left: '20%', right: '20%', bottom: '25%', textAlign: 'center', color: '#ffff88' }}>
                            {/* <h5>This pledge is proudly presented on {this.props.date} to:</h5> */}
                            <h5>{this.props.name} has proudly taken this pledge on {this.props.date} to become a smart and safe internet user!</h5>

                            {/* <h4>{this.props.name}</h4> */}

                            {/* <p style={{ color: '#ffff88', fontSize: '18px', marginBottom: 10 }}>for promising</p>
                            <p style={{ color: '#ffff88', fontSize: '18px', marginBottom: 10 }}>to be a smart and safe internet user.</p> */}

                            <p>Be a kind & respectful internet user</p>
                            <p>Be my privacy protector</p>
                            <p>Be my time controller</p>
                            <p>Be a brave reporter</p>
                        </div>
                    </div>
                </div >
            );
        } else {
            // return null;
            return (
                <div className="bottom">
                    <div className="fill-wrapper-cert" style={{ position: 'relative' }}>
                        <img src={require('../../../images/ResourcesForYou/NetizenPledge/bg_netizenpledge-CH_FINISH_Empty.png')} alt="" />
                        <div className="text" style={{ overflow: 'auto', position: 'absolute', top: '20%', left: '20%', right: '20%', bottom: '25%', textAlign: 'center' }}>
                            {/* <h5>謹此約章在 {this.props.date} 認證</h5> */}
                            <h5>此約章證明{this.props.name}在{this.props.date}承諾成為精明和安全的網絡使用者</h5>

                            {/* <h4>{this.props.name}</h4> */}
                            {/* <p style={{ color: '#ffff88', fontSize: '18px', marginBottom: 10 }}>for promising</p> */}
                            <h5>承諾成為精明和安全的網絡使用者</h5>
                            <p>成為一個善良和懂得尊重的網民</p>
                            <p>成為自己的私隱保護者</p>
                            <p>成為自己的時間控制者 </p>
                            <p>成為一個勇敢的舉報者 </p>
                            {/* <p style={{ color: '#ffff88', fontSize: '14px', marginBottom: 5 }}>Be a brave reporter</p> */}
                        </div>
                    </div>
                </div>
            )
        }
    }
}

class PrimaryNetizenPledge extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            date: moment(new Date()).format("YYYY-MM-DD"),
            okProgress: 0,
            error: null,

            backgroundColor: '',
            showPrintButton: true,
            showFacebookShareButton: true,
            showDownloadButton: true,
            showCopyToClipboardButton: true,
            copied: false
        }
    }

    componentDidMount() {
        apiHistory.createHistory(window.location.pathname);
    }

    _handleInput = (e) => {
        this.setState({
            ...this.state,
            name: e.target.value
        })
    }

    _nextStep = (e) => {
        e.preventDefault();
        const { t } = this.props;

        if (this.state.okProgress === 4) {
            if (this.state.name === '') {
                this.setState({
                    ...this.state,
                    error: {
                        name: t("PrimaryNetizenPledge:warming")
                    }
                });
            } else {
                this.setState({
                    ...this.state,
                    okProgress: this.state.okProgress + 1,
                    error: null
                });
            }
        } else {
            this.setState({
                ...this.state,
                okProgress: this.state.okProgress + 1
            });
        }
    }

    _downloadFile = (file_url, file_name) => {
        // Bluebird.delay(0, file_url).then((url) => {
        CommonUtils.forceDownload(file_url, file_name);
        // });
    }

    _handleCopyLink = () => {
        this.setState({
            copied: true
        });
        setTimeout(() => {
            this.setState({
                copied: false
            });
        }, 3000);
    }

    render() {
        const { t, i18n } = this.props;

        const currentURL = this.props.router.location.pathname;
        // const url = window.location.protocol + '//' + window.location.hostname + currentURL;
        const url = WEBSITE_URL + currentURL;

        // let file = require('../../../uploads/' + t("PrimaryTips:download"));

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:primaryNetizenPledge.title")}</title>
                    <meta name="description" content={t("Meta:primaryNetizenPledge.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content lavender-background pledge">
                            <h2>{t("PrimaryNetizenPledge:title")}</h2>

                            {(this.state.okProgress === 0) &&
                                <div className="fill-wrapper">
                                    <img src={require('../../../images/ResourcesForYou/NetizenPledge/bg_netizenplledge_01.jpg')} alt="" />

                                    <div className="text">
                                        <div className="pledgeOverflow">
                                            <h3>{t("PrimaryNetizenPledge:content1.heading")}</h3>
                                            <p>{t("PrimaryNetizenPledge:content1.paragraph")}</p>
                                        </div>
                                        <Link to={"/"} className="tick" onClick={(e) => this._nextStep(e)}></Link>
                                    </div>
                                </div>
                            }

                            {(this.state.okProgress === 1) &&
                                <div className="fill-wrapper">
                                    <img src={require('../../../images/ResourcesForYou/NetizenPledge/bg_netizenplledge_01.jpg')} alt="" />

                                    <div className="text">
                                        <div className="pledgeOverflow">
                                            <h3>{t("PrimaryNetizenPledge:content2.heading")}</h3>
                                            <p>{t("PrimaryNetizenPledge:content2.paragraph")}</p>
                                        </div>
                                        <Link to={"/"} className="tick" onClick={(e) => this._nextStep(e)}></Link>
                                    </div>
                                </div>
                            }

                            {(this.state.okProgress === 2) &&
                                <div className="fill-wrapper">
                                    <img src={require('../../../images/ResourcesForYou/NetizenPledge/bg_netizenplledge_01.jpg')} alt="" />

                                    <div className="text">
                                        <div className="pledgeOverflow">
                                            <h3>{t("PrimaryNetizenPledge:content3.heading")}</h3>
                                            <p>{t("PrimaryNetizenPledge:content3.paragraph")}</p>
                                        </div>
                                        <Link to={"/"} className="tick" onClick={(e) => this._nextStep(e)}></Link>
                                    </div>
                                </div>
                            }

                            {(this.state.okProgress === 3) &&
                                <div className="fill-wrapper">
                                    <img src={require('../../../images/ResourcesForYou/NetizenPledge/bg_netizenplledge_01.jpg')} alt="" />

                                    <div className="text">
                                        <div className="pledgeOverflow">
                                            <h3>{t("PrimaryNetizenPledge:content4.heading")}</h3>
                                            <p>{t("PrimaryNetizenPledge:content4.paragraph")}</p>
                                        </div>
                                        <Link to={"/"} className="tick" onClick={(e) => this._nextStep(e)}></Link>
                                    </div>
                                </div>
                            }

                            {(this.state.okProgress === 4) &&
                                <div className="fill-wrapper">
                                    <img src={require('../../../images/ResourcesForYou/NetizenPledge/bg_netizenplledge_01.jpg')} alt="" />

                                    <div className="text">
                                        <h3>{t("PrimaryNetizenPledge:enterName")}</h3>

                                        <div className="input-wrapper">
                                            <input type="text" placeholder={t("PrimaryNetizenPledge:placeholder")} value={this.state.name} onChange={(e) => this._handleInput(e)} />
                                            {this.state.error ? <ErrorMessage message={this.state.error.name} /> : ''}
                                        </div>

                                        <Link to={"/"} className="nextStep" onClick={(e) => this._nextStep(e)}></Link>
                                    </div>
                                </div>
                            }

                            <div className={'finish ' + (this.state.okProgress === 5 ? 'show' : '')}>
                                {/* <Grid container spacing={16} > */}
                                {/* <Grid item xs={10}> */}
                                <ComponentToPrint ref={el => (this.componentRef = el)} i18n={i18n} name={this.state.name} date={this.state.date} />
                                {/* </Grid> */}
                                {/* <Grid item xs={2}> */}
                                <div className={"button-wrapper " + (this.state.backgroundColor ? this.state.backgroundColor : '')}>
                                    <div className="wrap" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        {this.state.showPrintButton &&
                                            <ReactToPrint
                                                trigger={() => <div className={"print " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}></div>}
                                                content={() => this.componentRef}
                                            />
                                        }

                                        {this.state.showFacebookShareButton &&
                                            <FacebookShareButton url={url} className={"facebookShare " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')} />
                                        }

                                        {/* {this.state.showDownloadButton &&
                                                    <div className={"download " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}
                                                        onClick={() => this._downloadFile(file, t("PrimaryTips:download"))}>
                                                    </div>
                                                } */}

                                        {this.state.showCopyToClipboardButton &&
                                            <div className="wrapper-copyLink">
                                                <CopyToClipboard text={url} onCopy={() => this._handleCopyLink()}>
                                                    <div className={"copyLink " + (this.props.i18n.language === 'en-US' ? 'button' : 'button2')}></div>
                                                </CopyToClipboard>
                                                {(this.state.copied ? <div className="message">{t("Common:General.CopyLink")}</div> : '')}
                                            </div>
                                        }
                                    </div>
                                </div>
                                {/* <Buttons
                                            file={t("PrimaryTips:download")}
                                            componentRef={this.componentRef}
                                            showPrintButton={true}
                                            showFacebookShareButton={true}
                                            showDownloadButton={true}
                                            showCopyToClipboardButton={true}
                                        /> */}
                                {/* </Grid> */}
                                {/* </Grid> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    router: state.router
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PrimaryNetizenPledge));

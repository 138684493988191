// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

// Styling
import Grid from '@material-ui/core/Grid';

// Api
import { apiHistory } from '../../../Api/ApiHistory';
import { apiListBlog } from '../../../Api/ApiListBlog';

// Redux
import { connect } from 'react-redux';
import { login } from '../../../Redux/Action/authAction';

// Utils
import { get } from 'lodash-es';
import { createMarkup } from '../../../utils/CommonUtils';

// Children components
import BreadCrumb from '../../../components/100Include/BreadCrumb';

class ParentsBlog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contents: [],
            language: null,
            loading: false
        };
    }

    componentDidMount() {
        this.updateHtml();
        apiHistory.createHistory(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
            this.updateHtml();
        }
    }

    updateHtml() {
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiListBlog.getBlogs((result) => {
            this.setState({
                contents: get(result, 'body') || [],
                loading: false,
                language: get(this, 'props.i18n.language')
            });
        });
    }

    _goToDetail = (id) => {
        this.props.history.push("/" + this.props.i18n.language + '/parents-blog/' + id);
    }

    render() {
        const { t
            // , i18n 
        } = this.props;
        const {
            contents
        } = this.state;

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:parentsBlog.title")}</title>
                    <meta name="description" content={t("Meta:parentsBlog.description")} />
                </Helmet>
                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div className="content">
                            <Grid container spacing={16}>
                                {contents && contents.length > 0 && contents.map(content => {
                                    const language = get(this, 'props.i18n.language');
                                    const attr = language && language.toLowerCase() === 'en-us' ? 'html_en' : 'html_zh_cht';
                                    return <Grid item sm={6} key={content.list_blog_id}>
                                        <div
                                            className="ImageGrid2"
                                            onClick={() => this._goToDetail(content.list_blog_id)}
                                            dangerouslySetInnerHTML={createMarkup(content[attr])}
                                        ></div>
                                    </Grid>;
                                })}
                                {/* <Grid item sm={6}>
                                    <div className="ImageGrid2" onClick={() => this._goToDetail(1)}>
                                        <div className="upper">
                                            <img src={require('../../../images/ResourcesForYou/Parents/Blog/' + t("ParentsBlog:blog1.photoPath"))} alt="" />
                                        </div>
                                        <div className="bottom">
                                            <h3>{t("ParentsBlog:blog1.title")}</h3>
                                            <h5>{t("ParentsBlog:blog1.author")}</h5>
                                            <p>{t("ParentsBlog:blog1.content")}</p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item sm={6}>
                                    <div className="ImageGrid2" onClick={() => this._goToDetail(2)}>
                                        <div className="upper">
                                        <img src={require('../../../images/ResourcesForYou/Parents/Blog/' + t("ParentsBlog:blog2.photoPath"))} alt="" />
                                        </div>
                                        <div className="bottom">
                                            <h3>{t("ParentsBlog:blog2.title")}</h3>
                                            <h5>{t("ParentsBlog:blog2.author")}</h5>
                                            <p>{t("ParentsBlog:blog2.content")}</p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item sm={6}>
                                    <div className="ImageGrid2" onClick={() => this._goToDetail(3)}>
                                        <div className="upper">
                                        <img src={require('../../../images/ResourcesForYou/Parents/Blog/' + t("ParentsBlog:blog3.photoPath"))} alt="" />
                                        </div>
                                        <div className="bottom">
                                            <h3>{t("ParentsBlog:blog3.title")}</h3>
                                            <h5>{t("ParentsBlog:blog3.author")}</h5>
                                            <p>{t("ParentsBlog:blog3.content")}</p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item sm={6}>
                                    <div className="ImageGrid2" onClick={() => this._goToDetail(4)}>
                                        <div className="upper">
                                        <img src={require('../../../images/ResourcesForYou/Parents/Blog/' + t("ParentsBlog:blog4.photoPath"))} alt="" />
                                        </div>
                                        <div className="bottom">
                                            <h3>{t("ParentsBlog:blog4.title")}</h3>
                                            <h5>{t("ParentsBlog:blog4.author")}</h5>
                                            <p>{t("ParentsBlog:blog4.content")}</p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item sm={6}>
                                    <div className="ImageGrid2" onClick={() => this._goToDetail(5)}>
                                        <div className="upper">
                                            <img src={require('../../../images/ResourcesForYou/Parents/Button/btn_parents_01_blog.png')} alt="" />
                                        </div>
                                        <div className="bottom">
                                            <h3>{t("ParentsBlog:blog5.title")}</h3>
                                            <h5>{t("ParentsBlog:blog5.author")}</h5>
                                            <p>{t("ParentsBlog:blog5.content")}</p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item sm={6}>
                                    <div className="ImageGrid2" onClick={() => this._goToDetail(6)}>
                                        <div className="upper">
                                            <img src={require('../../../images/ResourcesForYou/Parents/Button/btn_parents_01_blog.png')} alt="" />
                                        </div>
                                        <div className="bottom">
                                            <h3>{t("ParentsBlog:blog6.title")}</h3>
                                            <h5>{t("ParentsBlog:blog6.author")}</h5>
                                            <p>{t("ParentsBlog:blog6.content")}</p>
                                        </div>
                                    </div>
                                </Grid> */}
                            </Grid>
                            {/* Grid */}
                            {/* <div><Link to={"/" + i18n.language + "/parents-blog-detail"}>Parents Blog Detail</Link></div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ParentsBlog)));

// Essential for all components
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Component dependencies
import Unity, { UnityContent } from 'react-unity-webgl';

// Styling
import fullScreenButtonImage from '../../images/btn_fullscreen.png';

// Api
import { apiGame } from '../../Api/ApiGame';

// Redux
import { connect } from 'react-redux';
import { login } from '../../Redux/Action/authAction';

// Utils
import { assign, find, forEach, get, isFunction, throttle } from 'lodash-es';
import getRelativePath from 'get-relative-path';
import CommonUtils from '../../utils/CommonUtils';


function getParentFolderPath(filePath) {
    const filePaths = filePath.split('/');
    filePaths.pop();
    return filePaths.join('/');
}

function createUnityCallbackJsonData(data, shouldExtractObj, errorMessage) {
    let result;
    let isResultAvailable = false;
    const status = data.status;
    const dataBody = data.body;

    if (status >= 200 && status < 300) {
        if (!shouldExtractObj) {
            result = dataBody;
            isResultAvailable = true;
        } else if (dataBody && dataBody.length > 0) {
            result = dataBody[0];
            isResultAvailable = true;
        }
    }
    if (!isResultAvailable) {
        result = {
            error: `${errorMessage}. Status code = ${status}`
        };
    }
    return result;
}

function createUnityErrorJsonObject(error) {
    let errorResult;
    if (error.error) {
        errorResult = error;
    } else {
        errorResult = {
            error: error instanceof Error ? error.message : error
        };
    }
    return errorResult;
}

class Game extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formSubmitted: false,
            unityInitialized: false,
            isSoftFullScreen: false,
            showUpgradeIosMessage: false
        };

        this.unityRefElement = null;
        this.handleFullScreenButton = this.handleFullScreenButton.bind(this);
        this.handleUnityFocusIn = this.handleUnityFocusIn.bind(this);
        this.handleUnityFocusOut = this.handleUnityFocusOut.bind(this);
        this.setUnityRefElement = this.setUnityRefElement.bind(this);
        this.handleHideUpgradeMessage = this.handleHideUpgradeMessage.bind(this);
        this.handleFullScreenOrientation = this.handleFullScreenOrientation.bind(this);
        this.handleOrientationChange = throttle(this.handleOrientationChange.bind(this), 250);
    }

    initUnityContent() {
        const {
            buildJsonPath,
            unityLoaderJsPath,
            unityConfig
        } = this.props;
        const currentPath = window.location.pathname;
        const localUnityLoaderJsPath = getRelativePath(currentPath, unityLoaderJsPath);
        let unityGameFolderPath = getParentFolderPath(localUnityLoaderJsPath) + '/';
        const localUnityConfig = assign({}, unityConfig, {
            modules: {
                locateFile: function (filename) {
                    return unityGameFolderPath.concat('build.wasm' === filename ? this.wasmCodeUrl : filename);
                }
            }
        });

        const unityContent = new UnityContent(
            buildJsonPath,  // getRelativePath(currentPath, buildJsonPath),
            unityLoaderJsPath,  // localUnityLoaderJsPath,
            localUnityConfig
        );
        this.unityContent = unityContent;

        unityContent.on('GetDisplayName', () => {
            return get(this, 'props.auth.userInfo.display_name') || '';
        });
        unityContent.on('GetLanguage', () => {
            return get(this, 'props.i18n.language') || 'zh-HK';
        });
        unityContent.on('RequestLeaderBoardData', (paramsJsonStr) => {
            let isResultSent = false;
            try {
                const token = get(this, 'props.auth.token');
                const params = JSON.parse(paramsJsonStr);
                const gameId = get(params, 'game_id');
                const level = get(params, 'level');
                apiGame.getLeaderBoardData(gameId, level, token, (data) => {
                    let result = createUnityCallbackJsonData(data, false, 'Cannot retrieve leader board data');
                    this.unityContent.send(get(this, 'props.gameObjectName'), 'OnLeaderBoardDataReady', JSON.stringify(result));
                    isResultSent = true;
                }, (error) => {
                    let errorResult = createUnityErrorJsonObject(error);
                    this.unityContent.send(get(this, 'props.gameObjectName'), 'OnLeaderBoardDataReady', JSON.stringify(errorResult));
                    isResultSent = true;
                });
            } catch (error) {
                console.error(error);
                if (!isResultSent) {
                    let errorResult = createUnityErrorJsonObject(error);
                    this.unityContent.send(get(this, 'props.gameObjectName'), 'OnLeaderBoardDataReady', JSON.stringify(errorResult));
                    isResultSent = true;
                }
            }
        });
        unityContent.on('RequestGameData', (gameId) => {
            const auth = get(this, 'props.auth');
            const token = get(auth, 'token');
            const username = get(auth, 'userInfo.username');
            // if (username) {
            apiGame.getGameData({
                primary_reference_id: gameId,
                user: username
            }, token, (data) => {
                let result = createUnityCallbackJsonData(data, true, 'Cannot retrieve game data');
                this.unityContent.send(get(this, 'props.gameObjectName'), 'OnRequestedGameData', JSON.stringify(result));
            }, (error) => {
                let errorResult = createUnityErrorJsonObject(error);
                this.unityContent.send(get(this, 'props.gameObjectName'), 'OnRequestedGameData', JSON.stringify(errorResult));
            });
            // } else {
            //     this.unityContent.send(get(this, 'props.gameObjectName'), 'OnRequestedGameData', JSON.stringify({
            //         error: 'User is not logged in.'
            //     }));
            // }
        });
        unityContent.on('UpdateGameData', (gameDataJsonStr) => {
            let isResultSent = false;
            try {
                const auth = get(this, 'props.auth');
                const token = get(auth, 'token');
                const username = get(auth, 'userInfo.username');
                const gameData = JSON.parse(gameDataJsonStr);
                apiGame.updateGameData(username, gameData, token, (data) => {
                    let result = createUnityCallbackJsonData(data, false, 'Cannot update game data');
                    this.unityContent.send(get(this, 'props.gameObjectName'), 'OnUpdatedGameData', JSON.stringify(result));
                    isResultSent = true;
                }, (error) => {
                    let errorResult = createUnityErrorJsonObject(error);
                    this.unityContent.send(get(this, 'props.gameObjectName'), 'OnUpdatedGameData', JSON.stringify(errorResult));
                    isResultSent = true;
                });
            } catch (error) {
                console.error(error);
                if (!isResultSent) {
                    let errorResult = createUnityErrorJsonObject(error);
                    this.unityContent.send(get(this, 'props.gameObjectName'), 'OnUpdatedGameData', JSON.stringify(errorResult));
                    isResultSent = true;
                }
            }
        });
        unityContent.on('GoToPage', () => {
            const { url } = this.props;
            if (url) {
                CommonUtils.openLinkInNewTab(url);
            } else {
                console.log('invalid url: ' + url);
            }
        });
        unityContent.on('FacebookShare', () => {
            const url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href);
            CommonUtils.openLinkInNewTab(url);
        });
        unityContent.on('loaded', () => {
            this.setUnityEventHandlers();
        });
        unityContent.on('error', console.error);

        const newState = {
            unityInitialized: true,
            viewAreaHeight: window.innerHeight
        };
        const userAgent = get(window, 'navigator.userAgent') || get(window, 'navigator.vendor') || get(window, 'opera');
        if (!window.MSStream &&
            (
                (
                    /iPhone|iPad/i.test(userAgent) &&
                    / OS 13_[0,1](?:_\d+) /i.test(userAgent)
                ) ||
                (
                    /AppleWebKit/.test(userAgent) &&
                    / Version\/13(?:\.0(?:\.[0,1,2])?)? /i.test(userAgent)
                )
            )
        ) {
            newState.showUpgradeIosMessage = true;
        }

        this.setState(newState);
    }

    componentDidMount() {
        this.initUnityContent();

        // firefox will throw an uncatchable error when try to lock or unlock the screen orientation
        // so we do not do it for firefox
        if (typeof InstallTrigger === 'undefined') {
            if (get(window, ['screen', 'orientation'])) {
                document.addEventListener('fullscreenchange', this.handleFullScreenOrientation);
                this.orientationHandlerIsSet = true;
            }
        }
    }

    componentWillUnmount() {
        const { isSoftFullScreen } = this.state;
        if (isSoftFullScreen) {
            this.changeParentCssPosition();
            this.modifyOrientationChangeEventListeners();
        }
        this.removeUnityEventHandlers();
        if (this.orientationHandlerIsSet) {
            document.removeEventListener('fullscreenchange', this.handleFullScreenOrientation);
            try {
                const unlockResult = window.screen.orientation.unlock();
                if (unlockResult && isFunction(unlockResult.catch)) {
                    unlockResult.catch(console.log);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    render() {
        const { width, height, t } = this.props;
        const { isSoftFullScreen, showUpgradeIosMessage, viewAreaHeight } = this.state;
        let unityGameDivClassName = 'unity-game-div';
        let actualWidth;
        let actualHeight;
        let fullscreenButtonBottomPosition;
        if (isSoftFullScreen) {
            unityGameDivClassName += ' unity-game-div-fullscreen';
            actualWidth = null;
            actualHeight = viewAreaHeight + 'px';
            fullscreenButtonBottomPosition = (viewAreaHeight / 2 - 25) + 'px';
        } else if (showUpgradeIosMessage) {
            actualWidth = width + 'px';
            actualHeight = Math.max((height - 20), 0) + 'px';
        } else {
            actualWidth = width + 'px';
            actualHeight = height + 'px';
        }
        return <div
            ref={this.setUnityRefElement}
            className={unityGameDivClassName}
            style={{
                width: actualWidth,
                height: actualHeight
            }}
        >
            {this.state.unityInitialized && <Fragment>
                {showUpgradeIosMessage && <p>
                    {t('Game:upgradeIos13')}
                    <br/>
                    <button onClick={this.handleHideUpgradeMessage}>
                        {t('Game:ok')}
                    </button>
                </p>}
                <img
                    src={fullScreenButtonImage}
                    onClick={this.handleFullScreenButton}
                    alt="Play game in full screen"
                    style={{
                        bottom: fullscreenButtonBottomPosition
                    }}
                />
                <Unity unityContent={this.unityContent} />
            </Fragment>}
        </div >;
    }


    handleFullScreenButton() {
        if (document.fullscreenEnabled) {
            const unityContent = get(this, 'unityContent');
            if (unityContent) {
                unityContent.setFullscreen(true);
            }
            this.setState({ isSoftFullScreen: false });
        } else {
            const { isSoftFullScreen } = this.state;
            const newIsSoftFullScreen = !isSoftFullScreen;
            this.changeParentCssPosition(newIsSoftFullScreen);
            this.modifyOrientationChangeEventListeners(newIsSoftFullScreen);
            window.scroll(0, 1);
            this.setState({
                isSoftFullScreen: newIsSoftFullScreen,
                viewAreaHeight: window.innerHeight
            });
        }
    }

    handleUnityFocusIn(evt) {
        if (this.unityContent) {
            console.log('handleUnityFocusIn: unityContent is set');
            this.unityContent.send(get(this, 'props.gameObjectName'), 'FocusCanvas', 1);
        } else {
            console.error('handleUnityFocusIn: unityContent is null');
        }
    }

    handleUnityFocusOut(evt) {
        const target = evt.target;
        if (target.tagName.toUpperCase() === 'CANVAS') {
            const canvases = this.unityRefElement.getElementsByTagName('canvas');
            const targetCanvas = find(canvases, (canvas) => canvas === target);
            if (targetCanvas != null) {
                return;
            };
        }
        if (this.unityContent) {
            console.log('handleUnityFocusOut: unityContent is set');
            this.unityContent.send(get(this, 'props.gameObjectName'), 'FocusCanvas', 0);
        } else {
            console.error('handleUnityFocusOut: unityContent is null');
        }
    }

    setUnityRefElement(element) {
        if (this.unityRefElement !== element) {
            this.removeUnityEventHandlers();
        }
        this.unityRefElement = element;
    }

    setUnityEventHandlers() {
        const unityRefElement = this.unityRefElement;
        if (unityRefElement) {
            const canvases = unityRefElement.getElementsByTagName('canvas');
            if (canvases.length > 0) {
                forEach(canvases, (canvas) => {
                    canvas.addEventListener('click', this.handleUnityFocusIn, false);
                    document.addEventListener('click', this.handleUnityFocusOut, false);
                });
            }
        }
    }

    removeUnityEventHandlers() {
        const unityRefElement = this.unityRefElement;
        if (unityRefElement) {
            const canvases = unityRefElement.getElementsByTagName('canvas');
            if (canvases.length > 0) {
                forEach(canvases, (canvas) => {
                    document.removeEventListener('click', this.handleUnityFocusOut, false);
                    canvas.removeEventListener('click', this.handleUnityFocusIn, false);
                });
            }
        }
    }

    handleHideUpgradeMessage() {
        this.setState({
            showUpgradeIosMessage: false
        });
    }

    handleFullScreenOrientation(event) {
        if (get(document, 'fullscreenElement')) {
            try {
                const lockResult = window.screen.orientation.lock('any');
                if (lockResult && isFunction(lockResult.catch)) {
                    lockResult.catch(console.log);
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                const unlockResult = window.screen.orientation.unlock();
                if (unlockResult && isFunction(unlockResult.catch)) {
                    unlockResult.catch(console.log);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    handleOrientationChange(event) {
        this.setState({
            viewAreaHeight: window.innerHeight
        });
        setTimeout(() => {
            window.scroll(0, 1);
        }, 250);
    }

    changeParentCssPosition(shouldPrepareSoftFullScreen) {
        const parentElems = document.querySelectorAll('#wrap, .wrapper-container-main, .container-main, .wrapper-content, .unity-game-container');
        forEach(parentElems, (elem) => {
            const elemStyle = get(elem, 'style');
            if (elemStyle) {
                elemStyle.position = shouldPrepareSoftFullScreen ? 'static' : '';
            }
        });
    }

    modifyOrientationChangeEventListeners(shouldPrepareSoftFullScreen) {
        if (shouldPrepareSoftFullScreen) {
            window.addEventListener('resize', this.handleOrientationChange);
            window.addEventListener('orientationchange', this.handleOrientationChange);
        } else {
            window.removeEventListener('orientationchange', this.handleOrientationChange);
            window.removeEventListener('resize', this.handleOrientationChange);
        }
    }
}

Game.propTypes = {
    buildJsonPath: PropTypes.string.isRequired,
    unityLoaderJsPath: PropTypes.string.isRequired,
    unityConfig: PropTypes.object,
    gameObjectName: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Game)));

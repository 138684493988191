import { apiGeneral } from './_General';

export const apiListBlog = {
  getBlogs(cb, eCb) {
    apiGeneral.apiFetch('public/list_blogs', {
      $orderby: 'sequence'
    }, null, cb, eCb);
  },

  getBlog(blogId, cb, eCb) {
    apiGeneral.apiFetch('public/list_blogs/' + encodeURIComponent(blogId), null, null, cb, eCb);
  }
};

// Essential for all components
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Api
import { apiAuth } from '../../Api/ApiAuth';
import { apiNewsLetter } from '../../Api/ApiNewsletter';

// Utils
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// Children components
import ErrorMessage from '../../components/100Include/ErrorMessage';
import footerLogo from '../../images/Footer/schk_web_footer_logo_schk_n_NA.png';
import facebookLinkIcon from '../../images/Footer/btn_footer_contact_FB.png';
import instagramLinkIcon from '../../images/Footer/btn_footer_contact_IG.png';

class Footer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			email: '',
			MessageContent: ''
		};
	}

	_newsLetterAsync = (values) => {
		if (typeof (values) !== 'undefined') {
			apiAuth.getClientCredentials().then((res) => {
				this._newsLetter(values, res.access_token);
			})
		}
	}

	_newsLetter = (values, access_token) => {

		const { t } = this.props;

		const cb = (obj) => {
			console.log("cb : ", obj);

			switch (obj.status) {

				case 500:
					this.setState({
						...this.state,
						MessageContent: obj.body.error
					});
					break;

				case 201:
					this.setState({
						...this.state,
						MessageContent: t("Common:Subscribe.SubscriptionCompleted")
					});
					break;

				default: console.log('what');
			}
		}

		const eCb = (obj) => {
			console.log("eCb : ", obj);
		}

		const body = {
			email: values.email
		}

		apiNewsLetter.addNewsletterSubscription(body, access_token, cb, eCb);
	}

	formNewsletter = ({ values, errors, touched, handleChange }) => {
		const { t
			, i18n
		} = this.props;

		return (
			<Form>
				{/* <Link to={"/" + i18n.language + '/'}><img className="footer-subscription-logo" src={footerLogo} alt="Logo of Save the Children" /></Link> */}
				<a href="http://www.savethechildren.org.hk" target="_blank" rel="noopener noreferrer"><img className="footer-subscription-logo" src={footerLogo} alt="Logo of Save the Children" /></a>
				<label htmlFor="signup_email">{t("Common:Subscribe.Signup")}</label>
				<div className="input-group">

					<Field name="email" type="email" placeholder={t("LoginRegister:placeholder.Email")} maxLength="100" className="form-control" />

					<span className="input-group-btn">
						<button className="btn btn-default" type="submit">{t("Common:Subscribe.Subscribe")}</button>
					</span>
				</div>
				{errors.email && touched.email ? <ErrorMessage message={errors.email} /> : null}
				{this.state.MessageContent ? <ErrorMessage message={this.state.MessageContent} /> : null}
				<Link to={"/" + i18n.language + '/disclaimer'} className="disclaimer" target="_blank" rel="noopener noreferrer"><h4>{t("Common:Disclaimer.Disclaimer")}</h4></Link>
			</Form>

		)
	}

	render() {
		const { t } = this.props;

		const Schema = Yup.object().shape({
			email: Yup.string()
				.email(t("LoginRegister:register.Checking1"))
				.required(t("LoginRegister:register.Checking2"))
		})

		return <div className="wrapper-footer container-fluid">
			<div className="row footer">
				<div className="col-sm-4 footer-column footer-subscription">

					<Formik
						initialValues={{
							email: '',
						}}
						validationSchema={Schema}
						onSubmit={this._newsLetterAsync}
						component={this.formNewsletter}
					/>
				</div>
				<div className="col-sm-4 footer-column footer-contact">
					<h2>{t("Common:Contact.Title")}</h2>
					<ul>
						<li className="email"><span className="sr-only">Email: </span><a target="_blank" rel="noopener noreferrer" href="mailto:hk.program@savethchildren.org">{t("Common:Contact.Email")}</a></li>
						<li className="tel"><span className="sr-only">Phone: </span><a target="_blank" rel="noopener noreferrer" href="tel:+852-2122-5640">{t("Common:Contact.Phone")}</a></li>
						<li className="fax"><span className="sr-only">Fax: </span><a target="_blank" rel="noopener noreferrer" href="tel:tel:+852-3160-8685">{t("Common:Contact.Fax")}</a></li>
					</ul>
					<div className="footer-social-icon-div">
						<a href="https://www.facebook.com/savethechildrenhk/" target="_blank" rel="noopener noreferrer"><img className="footer-social-icon" src={facebookLinkIcon} alt="Facebook link" /></a>
						<a href="https://www.instagram.com/savethechildrenhk/?hl=en" target="_blank" rel="noopener noreferrer"><img className="footer-social-icon" src={instagramLinkIcon} alt="Instagram link" /></a>
					</div>
				</div>
				<div className="col-sm-4 footer-column footer-address">
					<h2>{t("Common:Address.Title")}</h2>
					<p>
						{t("Common:Address.Address1")}<br />
						{t("Common:Address.Address2")}<br />
						{t("Common:Address.Address3")}<br />
						{t("Common:Address.Address4")}
					</p>
				</div>
			</div>
		</div>;
	}

}

Footer.propTypes = {
	html: PropTypes.string
};

export default withTranslation()(Footer);

import { apiGeneral } from './_General';

export const apiListSecondaryNetTubes = {
  getBlogs(cb, eCb) {
    apiGeneral.apiFetch('public/list_secondary_net_tubes', {
      $orderby: 'sequence'
    }, null, cb, eCb);
  },

  getBlog(blogId, cb, eCb) {
    apiGeneral.apiFetch('public/list_secondary_net_tubes/' + encodeURIComponent(blogId), null, null, cb, eCb);
  }
};

import { apiGeneral } from './_General';
import { get, isFunction, pick } from 'lodash-es';
import localForage from 'localforage';

export const apiGame = {

    getGameData: (params, token, cb, eCb) => {
        if (token) {
            apiGeneral.apiFetch('user_data', params, token, cb, eCb);
        } else {
            // get data from browser storage if user is not logged in
            const gameId = get(params, 'primary_reference_id');
            if (gameId == null) {
                const error = new Error('gameId is not provided');
                if (isFunction(eCb)) {
                    eCb(error);
                } else {
                    console.error(error);
                }
            } else {
                localForage.getItem(`user_data_${gameId}`).then((value) => {
                    if (value == null) {
                        eCb(new Error('No anonymous game data stored in browser'));
                    } else {
                        try {
                            cb({
                                status: 200,
                                body: [ value ]
                            });
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }).catch(eCb);
            }
        }
    },
    
    updateGameData: (username, data, token, cb, eCb) => {
        const gameId = get(data, 'primary_reference_id');
        if (gameId == null) {
            const error = new Error('gameId is not provided');
            if (isFunction(eCb)) {
                eCb(error);
            } else {
                console.error(error);
            }
        } else if (token == null) {
            // save data to browser storage if user is not logged in
            const clonedData = pick(data, ['primary_reference_id', 'data']);
            localForage.setItem(`user_data_${gameId}`, clonedData).then((value) => {
                try {
                    cb({
                        status: 200,
                        body: value
                    });
                } catch (e) {
                    console.error(e);
                }
            }).catch(eCb);
        } else {
            apiGeneral.apiFetch(
                'user_data',
                {
                    primary_reference_id: gameId,
                    user: username
                },
                token,
                (retrievedData) => {
                    const status = retrievedData.status;
                    const dataBody = retrievedData.body;
                    const clonedData = pick(data, ['primary_reference_id', 'data']);
                    clonedData.user = username;
                    if (status >= 200 && status < 300 && dataBody && dataBody.length > 0) {
                        const existedData = retrievedData.body[0];
                        apiGeneral.apiPut(
                            `user_data/${existedData.user_data_id}`,
                            clonedData,
                            token,
                            (response) => {
                                response.json().then((data) => {
                                    cb({
                                        status: response.status,
                                        body: data
                                    });
                                });
                            },
                            eCb
                        );
                    } else {
                        apiGeneral.apiPost('user_data', clonedData, token, cb, eCb);
                    }
                },
                eCb
            );
        }
    },

    getLeaderBoardData: (gameId, level, token, cb, eCb) => {
        const params = level == null ? null : { level };
        apiGeneral.apiFetch(`leader_board/${gameId}`, params, token, cb, eCb);
    }
};

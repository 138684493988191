// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
//import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

// Styling
import Grid from '@material-ui/core/Grid';

// Api
import { apiHistory } from '../../Api/ApiHistory';
import { apiListExternalLinks } from '../../Api/ApiListExternalLinks';

// Redux
import { connect } from 'react-redux';
import { login } from '../../Redux/Action/authAction';

// Utils
import { get } from 'lodash-es';
import { createMarkup } from '../../utils/CommonUtils';

// Children components
import BreadCrumb from '../../components/100Include/BreadCrumb';

class ExternalResources extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formSubmitted: false,
            language: null,
            loading: false,
            content: null
        }
    }

    componentDidMount() {
        this.updateHtml();
        apiHistory.createHistory(window.location.pathname);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { language, loading } = this.state;
        if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
            this.updateHtml();
        }
    }

    updateHtml() {
        this.setState({
            loading: true,
            language: get(this, 'props.i18n.language')
        });
        apiListExternalLinks.getBlogs((result) => {
            this.setState({
                contents: get(result, 'body') || [],
                loading: false,
                language: get(this, 'props.i18n.language')
            });
        });
    }

    render() {
        const { t,
            //i18n
        } = this.props;
        // const { classes } = this.props;

        const {
            contents
        } = this.state;

        return (
            <div>
                <div className="wrapper-container-main">
                    <Helmet>
                        <title>{t("Meta:externalResources.title")}</title>
                        <meta name="description" content={t("Meta:externalResources.description")} />
                    </Helmet>
                    <div className="container-main">
                        <BreadCrumb />
                        <div className="wrapper-content">
                            <div className="content">
                                <div className="GridGrid">
                                    <div className="upper">
                                        <img src={require('../../images/ExternalResources/banner-External_Resources.png')} alt="" />
                                    </div>
                                    <div className="lower ExternalResourcesList">
                                        <Grid container spacing={16} style={{ justifyContent: 'space-around' }}>
                                            {contents && contents.length > 0 && contents.map(content => {
                                                const language = get(this, 'props.i18n.language');
                                                const attr = language && language.toLowerCase() === 'en-us' ? 'html_en' : 'html_zh_cht';
                                                const url = language && language.toLowerCase() === 'en-us' ? content.url_en : content.url_zh_cht;
                                                return <Grid item sm={6} key={content.list_external_link_id}>
                                                    <a href={url} target="_blank" rel="noopener noreferrer">


                                                        <div dangerouslySetInnerHTML={createMarkup(content[attr])}
                                                        ></div>
                                                    </a>
                                                </Grid>;
                                            })}
                                        </Grid>
                                        {/* <Grid container spacing={16}>
                                            <Grid item sm={6}>
                                                <a href="https://www.hkedcity.net/home/" target="_blank" rel="noopener noreferrer">
                                                    <div className="grid">
                                                        <div className="top">
                                                            <img src={require('../../images/ExternalResources/btn_externalresources_01_HKedCity.png')} alt="" />
                                                        </div>
                                                        <div className="bottom">
                                                            <h2><b>{t("ExternalResources:block1.title")}</b></h2>
                                                            <h3>{t("ExternalResources:block1.subtitle")}</h3>
                                                            <div className="sep-0"></div>
                                                            <p>{t("ExternalResources:block1.content")}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </Grid> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ExternalResources));

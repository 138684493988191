import { apiGeneral } from './_General';

export const apiHistory = {
  createHistory(urlPath, token, cb, eCb) {
    apiGeneral.apiPost('public/histories', {
      action_type: '16a43d76-5cd4-4776-b723-42ff76978e11',
      resource: '3d19f4f4-06b1-42f5-8dea-0db8fa5be12e',
      json_attributes: JSON.stringify({ url_path: urlPath })
    }, token, cb, eCb);
  }
};

// Essential for all components
import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Styling

// Api
import { apiHistory } from '../../../Api/ApiHistory';
import { apiListSecondaryNetTubes } from '../../../Api/ApiListSecondaryNetTubes';

// Redux
import { connect } from 'react-redux';
import { login } from '../../../Redux/Action/authAction';

// Utils
import { get } from 'lodash-es';
import { createMarkup } from '../../../utils/CommonUtils';

// Children components
import BreadCrumb from '../../../components/100Include/BreadCrumb';

class SecondaryVideoDetail extends Component {
  constructor(props) {
    super(props);

    const id = props.id;
    this.state = {
      formSubmitted: false,
      isCmsContent: id && id.length === 36,
      contentVideoURL: null,
      contentContent: null,
      language: null,
      loading: false
    };
  }

  componentDidMount() {
    this.updateHtml();
    apiHistory.createHistory(window.location.pathname);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { language, loading } = this.state;
    if (!loading && (!language || language !== get(this, 'props.i18n.language'))) {
      this.updateHtml();
    }
  }

  updateHtml() {
    if (!this.state.isCmsContent) {
      return;
    }
    this.setState({
      loading: true,
      language: get(this, 'props.i18n.language')
    });
    apiListSecondaryNetTubes.getBlog(this.props.id, (result) => {
      const language = get(this, 'props.i18n.language');
      const newState = {
        loading: false,
        language
      };
      const body = get(result, 'body');
      if (result.status === 200 && body) {
        const langAttr = language && language.toLowerCase() === 'en-us' ? 'en' : 'zh_cht';
        newState.contentVideoURL = body['video_url'];
        newState.contentContent = body['page_content_' + langAttr];
      }
      this.setState(newState);
    });


  }

  renderCmsContent() {
    const { contentVideoURL, contentContent } = this.state;
    return <Fragment>
      <div className="upper">
        <iframe width="800" height="480" title="POPA"
          src={contentVideoURL}>
        </iframe>
      </div>
      <div
        className="bottom"
        dangerouslySetInnerHTML={createMarkup(contentContent)}

      ></div>
    </Fragment>;
  }

  render() {
    const { t } = this.props;

    return (
      <div className="wrapper-container-main">
        <Helmet>
          <title>{t("Meta:parentsNetTube.title")}</title>
          <meta name="description" content={t("Meta:parentsNetTube.description")} />
        </Helmet>
        <div className="container-main">
          <BreadCrumb />
          <div className="wrapper-content">
            <div className="content">
              <div className="BlogDetail">
                {/* {isCmsContent ? this.renderCmsContent() : this.renderOldContent()} */}
                {this.renderCmsContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SecondaryVideoDetail));

import { apiGeneral } from './_General';

export const apiListSecondarySocialMediaGuides = {
  getSocialMediaGuides(cb, eCb) {
    apiGeneral.apiFetch('public/list_secondary_social_media_guides', {
      $orderby: 'sequence'
    }, null, cb, eCb);
  },

  getSocialMediaGuide(socialMediaGuideId, cb, eCb) {
    apiGeneral.apiFetch('public/list_secondary_social_media_guides/' + encodeURIComponent(socialMediaGuideId), null, null, cb, eCb);
  }
};

// Essential for all components
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

// Component dependencies

// Styling

// Api
import { apiHistory } from '../../../Api/ApiHistory';

// Redux
import { connect } from 'react-redux';
import { login } from '../../../Redux/Action/authAction';

// Utils
import { filter, get } from 'lodash-es';

// Children components
import BreadCrumb from '../../../components/100Include/BreadCrumb';
import Game from '../../../components/100Include/Game';

import { SCREEN_SIZE_SM } from '../../../config';


// Assume the base url is the the root path of a (sub)domain.
// TODO: get the base url of the react app and use it to construct the correct path.
const gameBasePath = '/HelixJumper/Build/';

const gameObjectName = '3d_helix_jumper_manager';
const buildJsonPath = gameBasePath + 'HelixJumper.json';
const unityLoaderJsPath = gameBasePath + 'UnityLoader.js';

class SecondaryGame extends Component {

    constructor(props) {
        super(props);
        this.unityContainerRef = React.createRef();
    }

    componentDidMount() {
        apiHistory.createHistory(window.location.pathname);
    }

    render() {
        const { t } = this.props;
        const HEIGHT_RATIO = 0.75;
        // TODO: trigger react to re-render in resize or orientationchange events
        const leftRightMargin = window.matchMedia('(min-width: ' + SCREEN_SIZE_SM + 'px)').matches ?
                20 :
                40;
        const width = Math.min.apply(Math, filter([
            get(this, 'unityContainerRef.current.clientWidth'),
            1020,
            (document.body.clientWidth - leftRightMargin * 2),
            (document.body.clientHeight - 75) / HEIGHT_RATIO
        ]));
        const height = width * HEIGHT_RATIO;

        const urls = [window.location.host];
        const language = get(this, 'props.i18n.language');
        if (language) {
            urls.push(language);
        }
        const url = '//' + urls.join('/') + '/secondary-school-home';

        return (
            <div className="wrapper-container-main">
                <Helmet>
                    <title>{t("Meta:secondaryGame.title")}</title>
                    <meta name="description" content={t("Meta:secondaryGame.description")} />
                </Helmet>

                <div className="container-main">
                    <BreadCrumb />
                    <div className="wrapper-content">
                        <div
                            className="content no-padding unity-game-container"
                            ref={this.unityContainerRef}
                            style={{
                                minWidth: width + 'px',
                                maxWidth: width + 'px',
                                minHeight: height + 'px',
                                maxHeight: height + 'px',
                            }}
                        >
                            <Game
                                gameObjectName={gameObjectName}
                                buildJsonPath={buildJsonPath}
                                unityLoaderJsPath={unityLoaderJsPath}
                                width={width}
                                height={height}
                                url={url}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SecondaryGame));
